import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { VscEdit } from "react-icons/vsc";
import "./index.scss";
import { GetCallApi } from "../../../Action/Action";
// import qrcodeIcon from "../../../assets/icons/qr_code_icon.svg";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi";
import { ToastError } from "../../Components/Toast/index.jsx";
import TableHeader from "../../../TableHeader.json";

const DeletePopup = lazy(() =>
  import("../../Components/DeletePopup/index.jsx")
);
const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};

function EventListMain() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [conferenceId, setConferenceId] = useState();
  const [eventId, setEventId] = useState();
  const [open, setOpen] = useState(false);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);

  const qrRef = useRef();

  // let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    document.title = "FIG Events | Event";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      handleConfereceSelect(res[0]?.value);
    }
  };

  useEffect(() => {
    if (confId) {
      GetAllEventsList();
    }
  }, [confId]);

  const GetAllEventsList = async () => {
    setDataOfTable([]);
    if (confId) {
      setOpenCircle(true);
      let seData = {
        url: `/api/Events/GetAllEventsList?userId=${logindetail?.userId}&conferenceId=${confId}`,
      };
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res?.status === 200 && res?.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            eventStartdate: c?.eventStartdate
              ? convert(c?.eventStartdate?.split("T")[0])
              : "",
            eventEnddate: c?.eventEnddate
              ? convert(c?.eventEnddate?.split("T")[0])
              : "",
            status: c?.status,
            Action: (
              <>
                <Button
                  className="download_btn me-3"
                  onClick={() => handleDownload(c)}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background:
                      "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                    padding: "5px 15px",
                    borderRadius: "5px",
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  <div className="text">
                    <img src={"/assets/icons/qr_code_icon.svg"} alt="QR Code" />
                    <span className="ms-2">Download QR</span>
                  </div>
                </Button>
                <Link
                  to={`edit/${c.eventId}`}
                  className="btn_edit me-3 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Event"}
                  text={c?.eventName}
                  url={`/api/Events/DeleteEvents?eventId=${c?.eventId}`}
                  callBack={true}
                  callBackFunction={GetAllEventsList}
                />
              </>
            ),
          };
        });
        setDataOfTable(comList);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadQRCode(image, imageFileName);
  };

  const downloadQRCode = async (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setOpen(false);
  };

  const handleDownload = (c) => {
    setEventId(c);
    setConferenceId(c?.conferenceId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="d-flex flex-wrap gap-4 mb-3"
                      style={{ marginLeft: "20px" }}
                    >
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleConfereceSelect(e?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    addressLink={`create`}
                    headCells={TableHeader?.EventListHeader}
                    title="Event List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                    addBtn={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {open && (
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="popup_box_add_drag"
          >
            <Box sx={style} className={`main_box `}>
              <div ref={qrRef}>
                <div
                  className=" p-3 w-auto"
                  style={{ color: "#80c242", fontSize: "18px" }}
                >
                  {eventId?.eventName}
                </div>
                {/* <div className="title">QR Code</div> */}
                <hr />
                <div className="col-md-12 d-flex justify-content-center mb-3">
                  <div className="qr_box">
                    <div style={{ padding: "1rem" }} className="qr_image">
                      <QRCode
                        value={
                          "/conferenceId/" +
                          conferenceId +
                          "/Event/" +
                          eventId?.eventId
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-evenly">
                  <Button
                    className="back_button"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="common_btn"
                    onClick={(e) => exportAsImage(e, eventId?.eventName)}
                  >
                    Download
                  </Button>
                  {/* className="common_btn mx-auto" */}
                </div>
              </div>
              {/* </form> */}
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}

export default EventListMain;
