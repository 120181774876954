import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import WebFooter from "../../../Shared/WebFooter";
import logo from "../../../assets/images/fig_events.png";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Axios from "../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Config } from "../../../Utils/Config";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { HiOutlineEye } from "react-icons/hi";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { GetCallApi } from "../../../Action/Action";
import { FaCalendarWeek } from "react-icons/fa";
import NewPaginations from "../../../Modules/Components/NewPaginations/Pagination";
import { GrView } from "react-icons/gr";
import ImgPopup from "../../../Modules/Components/ImgPopUp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Swal from "sweetalert2";
import Select from "react-select";
import { customFilter } from "../../../Modules/Components/CustomFilterForReactSelect";

const noLogo = "/assets/images/noLogo.png";
const NotFound = "/assets/images/Image_not_available.jpg";
const calendar = "/assets/icons/calendar-icon.svg";
const call = "/assets/icons/call-icon.svg";
const cities = "/assets/icons/cityes.svg";
const clockimg = "/assets/icons/clock-icon.svg";
const email = "/assets/icons/email-icon.svg";
const location = "/assets/icons/location-icon.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ConferencesDetail() {
  // const { id } = useParams();
  const { state } = useLocation();
  const { conferenceId: id } = state;
  // const id = conferenceId;

  const [expanded, setExpanded] = React.useState("panel1");
  const [value, setValue] = React.useState("1");
  const [sessionList, setSessionList] = useState([]);
  const [seDetail, setSeDetail] = useState();
  const [glList, setGlList] = useState([]);
  const [speakerId, setSpeakerId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let Pagesize = 12;

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
    let se = sessionList?.filter((x, i) => i + 1 === parseInt(newValue))[0];
    setSeDetail(se);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [dataOfEvent, setDataOfEvent] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      getData();
    }, 200);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    document.title = "FIG Events | Conference Detail";
    GetGlList();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line
  }, []);
  const getData = () => {
    if (id) {
      let url = LogDetails?.userId
        ? `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&userId=${LogDetails?.userId}&isAlphanumericOrder=false`
        : `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&isAlphanumericOrder=false`;

      Axios.get(url, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data.success) {
              // setOpenCircle(false);
              setIsLoading(false);
              let comList1 = res?.data?.payload;
              setDataOfEvent(comList1);
            } else {
              // setOpenCircle(false);
              setIsLoading(false);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          setOpenCircle(false);
          setIsLoading(false);
        });
      getSession(id);
    }
  };
  const getSession = async (id) => {
    let seData = {
      url: `/api/Conference/GetDayWiseClassListByConferenceId?conferenceId=${id}`,
      headers: headers,
    };
    var special = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    var deca = [
      "twent",
      "thirt",
      "fort",
      "fift",
      "sixt",
      "sevent",
      "eight",
      "ninet",
    ];

    function stringifyNumber(n) {
      if (n < 20) {
        return special[n];
      } else if (n < 100) {
        return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
      } else if (n < 1000) {
        var hundreds = Math.floor(n / 100);

        if (hundreds === 1) {
          return (
            "hundredth" +
            (n % 100 > 0 ? " and " + stringifyNumber(n % 100) : "")
          );
        } else {
          return (
            special[hundreds] +
            " hundred" +
            (n % 100 > 0 ? " and " + stringifyNumber(n % 100) : "")
          );
        }
      } else {
        // Handle numbers greater than 999 (e.g., "thousandth", "ten thousandth", etc.)
        // You can implement this logic based on your specific requirements
        return "unspecified";
      }
    }

    // TEST LOOP SHOWING RESULTS
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        let sl = res?.data.payload
          ?.map((x, i) => {
            return {
              ...x,
              date: x.date
                ? new Date(x.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })
                : "",
              day: stringifyNumber(i + 1) + " Day",
            };
          })
          ?.filter((x) => x.classList?.length > 0);
        setSessionList(sl);
        setSeDetail(sl[0]);
      }
    }
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const GetGlList = async () => {
    let seData = {
      url: `/api/Gallery/GetAllGallery`,
      headers: {},
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            image: c?.image ? `${Config.API_HOST_URL_live}${c.image}` : "",
          };
        });
        setGlList(comList);
      }
    }
  };

  const box = Array.from({ length: 1 }, (_, index) => (
    <div key={index} className="skeleton-box"></div>
  ));
  const showReadMore = (t, des) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: t,
      text: des,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  function TimeTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    let period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    hours = hours < 10 ? "0" + hours : hours;
    hours = hours ? hours : "00";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    minutes = minutes ? minutes : "00";
    return `${hours}:${minutes} ${period}`;
  }
  const handleSpeaker = (d, spId) => {
    if (spId) {
      const spe = d?.speakerInfos?.filter((x) => x?.speakerId === spId)[0];
      setSpeakerId({ classId: d?.classId, ...spe });
    } else {
      setSpeakerId();
    }
  };

  const ShowSpeakerBrochures = ({
    expanded,
    handleChange,
    speakerId,
    de,
    i,
  }) => {
    return (
      <Accordion
        className="pannel"
        expanded={expanded === `Bro${i + 1}`}
        onChange={handleChange(`Bro${i + 1}`)}
      >
        <AccordionSummary
          className="pannel_title"
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          Brochures
        </AccordionSummary>
        <AccordionDetails className="pannel_contain">
          {speakerId ? (
            de?.classId === speakerId?.classId ? (
              <>
                {speakerId?.speakerBrochers?.map((docPth, no) => (
                  <div className="document-block " key={no}>
                    <div className="d-flex gap-2">
                      <div className="num me-3">{no + 1}.</div>
                      <div className="text_data">
                        {docPth.attachmentTitle
                          ? docPth.attachmentTitle
                          : "Document PDF"}
                      </div>
                    </div>
                    <Link
                      to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                      target="_blank"
                      className="btn_dlt"
                    >
                      <HiOutlineEye className="icon" />
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <>
                {de?.speakerInfos[0]?.speakerBrochers?.map((docPth, no) => (
                  <div className="document-block " key={no}>
                    <div className="d-flex gap-2">
                      <div className="num me-3">{no + 1}.</div>
                      <div className="text_data">
                        {docPth.attachmentTitle
                          ? docPth.attachmentTitle
                          : "Document PDF"}
                      </div>
                    </div>
                    <Link
                      to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                      target="_blank"
                      className="btn_dlt"
                    >
                      <HiOutlineEye className="icon" />
                    </Link>
                  </div>
                ))}
              </>
            )
          ) : (
            <>
              {de?.speakerInfos[0]?.speakerBrochers?.map((docPth, no) => (
                <div className="document-block " key={no}>
                  <div className="d-flex gap-2">
                    <div className="num me-3">{no + 1}.</div>
                    <div className="text_data">
                      {docPth.attachmentTitle
                        ? docPth.attachmentTitle
                        : "Document PDF"}
                    </div>
                  </div>
                  <Link
                    to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                    target="_blank"
                    className="btn_dlt"
                  >
                    <HiOutlineEye className="icon" />
                  </Link>
                </div>
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };
  const ShowSpeakerDocument = ({
    expanded,
    handleChange,
    speakerId,
    de,
    i,
  }) => {
    return (
      <Accordion
        className="pannel"
        expanded={expanded === `Doc${i + 1}`}
        onChange={handleChange(`Doc${i + 1}`)}
      >
        <AccordionSummary
          className="pannel_title"
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          Speaker Documents
        </AccordionSummary>
        <AccordionDetails className="pannel_contain">
          {speakerId ? (
            de?.classId === speakerId?.classId ? (
              <>
                {speakerId?.speakerDocuments?.map((docPth, no) => (
                  <div className="document-block " key={no}>
                    <div className="d-flex gap-2">
                      <div className="num me-3">{no + 1}.</div>
                      <div className="text_data">
                        {docPth.attachmentTitle
                          ? docPth.attachmentTitle
                          : "Document PDF"}
                      </div>
                    </div>
                    <Link
                      to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                      target="_blank"
                      className="btn_dlt"
                    >
                      <HiOutlineEye className="icon" />
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <>
                {de?.speakerInfos[0]?.speakerDocuments?.map((docPth, no) => (
                  <div className="document-block " key={no}>
                    <div className="d-flex gap-2">
                      <div className="num me-3">{no + 1}.</div>
                      <div className="text_data">
                        {docPth.attachmentTitle
                          ? docPth.attachmentTitle
                          : "Document PDF"}
                      </div>
                    </div>
                    <Link
                      to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                      target="_blank"
                      className="btn_dlt"
                    >
                      <HiOutlineEye className="icon" />
                    </Link>
                  </div>
                ))}
              </>
            )
          ) : (
            <>
              {de?.speakerInfos[0]?.speakerDocuments?.map((docPth, no) => (
                <div className="document-block " key={no}>
                  <div className="d-flex gap-2">
                    <div className="num me-3">{no + 1}.</div>
                    <div className="text_data">
                      {docPth.attachmentTitle
                        ? docPth.attachmentTitle
                        : "Document PDF"}
                    </div>
                  </div>
                  <Link
                    to={`${Config.API_HOST_URL_live}/${docPth.attachmentPath}`}
                    target="_blank"
                    className="btn_dlt"
                  >
                    <HiOutlineEye className="icon" />
                  </Link>
                </div>
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section
          className={`conferenceDetails-banner ${isLoading ? "load" : ""}`}
        >
          <div className="container">
            <div className="row details-banner">
              <div className="col-lg-6">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div
                      className="skeleton-line skeleton-title mb-4"
                      style={{ width: "50%" }}
                    ></div>
                  </div>
                ) : (
                  <h2>
                    {dataOfEvent?.conferenceName
                      ? dataOfEvent?.conferenceName
                      : ""}
                  </h2>
                )}
                {isLoading ? (
                  <div className="skeleton-loader" style={{ width: "70%" }}>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                  </div>
                ) : (
                  dataOfEvent?.description && (
                    <p style={{ whiteSpace: "pre-line" }}>
                      {dataOfEvent?.description
                        ? dataOfEvent?.description?.slice(0, 285)
                        : ""}
                      {dataOfEvent?.description
                        ? dataOfEvent?.description?.length > 285 && (
                            <span
                              className="description cr"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                showReadMore(
                                  dataOfEvent?.conferenceName,
                                  dataOfEvent?.description
                                )
                              }
                            >
                              ...read more
                            </span>
                          )
                        : ""}
                    </p>
                  )
                )}

                <div className="info">
                  {isLoading ? (
                    <div className="skeleton-loader" style={{ width: "70%" }}>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  ) : (
                    <>
                      <h5>{dataOfEvent?.contactName}</h5>
                      <ul>
                        <li>
                          <img src={email} alt="Email" />
                          <p>{dataOfEvent?.email}</p>
                        </li>
                        <li>
                          <img src={call} alt="call" />
                          <p>{dataOfEvent?.phoneNumber}</p>
                        </li>
                        <li>
                          <img src={location} alt="location" />
                          <p>{dataOfEvent?.conferenceAddress}</p>
                        </li>
                        <li>
                          <img src={calendar} alt="calendar" />
                          <p>
                            {dataOfEvent?.conferenceStartdate
                              ? convert(
                                  dataOfEvent?.conferenceStartdate?.split(
                                    "T"
                                  )[0]
                                )
                              : ""}{" "}
                            -{" "}
                            {dataOfEvent?.conferenceEnddate
                              ? convert(
                                  dataOfEvent?.conferenceEnddate?.split("T")[0]
                                )
                              : ""}
                          </p>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
                <div className="btns">
                  {dataOfEvent?.conferenceEnddate ? (
                    dataOfEvent?.conferenceEnddate?.split("T")[0] >=
                    new Date()?.toISOString()?.split("T")[0] ? (
                      <>
                        {/* {dataOfEvent?.isExhibitorRegister ? (
                          dataOfEvent?.isExhibitorRegistered === true ? ( */}
                        {/* "" */}
                        {/* ) :  */}
                        {dataOfEvent?.isExhibitorRegister ? (
                          <>
                            {dataOfEvent?.isAllBoothBooked ? (
                              isLoading ? (
                                <div className="skeleton-loader inner-loader-btn">
                                  <div className="skeleton-btn"></div>
                                </div>
                              ) : (
                                <Button className="orange-btn" disabled>
                                  Registrations Closed
                                </Button>
                              )
                            ) : dataOfEvent?.isTwoInProgressBooth ? (
                              isLoading ? (
                                <div className="skeleton-loader inner-loader-btn">
                                  <div className="skeleton-btn"></div>
                                </div>
                              ) : (
                                <Link className="exhibitors-btn">
                                  <span>
                                    Currenty Booth allocation in progress please
                                    try again after some time.
                                  </span>
                                </Link>
                              )
                            ) : isLoading ? (
                              <div className="skeleton-loader inner-loader-btn">
                                <div className="skeleton-btn"></div>
                              </div>
                            ) : LogDetails?.userId ? (
                              <Link
                                to={`/conference-exhibotor-register/${id}`}
                                className="exhibitors-btn"
                              >
                                <span>Continue as Exhibitor</span>
                              </Link>
                            ) : (
                              <Link to={"/register"} className="exhibitors-btn">
                                <span>Become an Exhibitor</span>
                              </Link>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/*} ) : (
                        //   ""
                        // )}*/}
                        {dataOfEvent?.isAttendeeRegister === true ? (
                          dataOfEvent?.isConferenceBooked === true ? (
                            ""
                          ) : isLoading ? (
                            <div className="skeleton-loader inner-loader-btn">
                              <div className="skeleton-btn"></div>
                            </div>
                          ) : LogDetails?.userId ? (
                            <Link
                              to={`/conference-user-register/${id}`}
                              className="attendee-btn"
                            >
                              Continue as Attendee
                            </Link>
                          ) : (
                            <Link to={"/register"} className="attendee-btn">
                              Become an Attendee
                            </Link>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <div className="skeleton-loader inner-loader-btn">
                      <div className="skeleton-btn"></div>
                    </div>
                  ) : dataOfEvent?.isExhibitorRegister ? (
                    <Link
                      to={`/conference-floor-plan`}
                      className="attendee-btn text-center"
                      state={{ conferenceId: id }}
                    >
                      View Floor
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6 right-img">
                {isLoading ? (
                  // <div className="skeleton-loader mb-3">{box}</div>
                  ""
                ) : (
                  <img
                    src={
                      dataOfEvent?.coverImage
                        ? `${Config.API_HOST_URL_live}${dataOfEvent?.coverImage}`
                        : NotFound
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NotFound;
                    }}
                    className="img-fluid banner-img"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        {dataOfEvent?.eventLists?.length > 0 ? (
          <section className={`events_sec ${isLoading ? "load" : ""}`}>
            <div className="container">
              <div className="top_title">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div
                      className="skeleton-line skeleton-title mb-4"
                      style={{ width: "20%" }}
                    ></div>
                    <div
                      className="skeleton-line"
                      style={{ width: "20%" }}
                    ></div>
                  </div>
                ) : (
                  <h2>
                    <span> Featured Events</span>
                  </h2>
                )}
                <p>Stay Informed and Save the Date!</p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      640: {
                        slidesPerView: 2,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {isLoading ? (
                      <></>
                    ) : (
                      dataOfEvent?.eventLists?.map((DE, i) => (
                        <SwiperSlide key={i}>
                          <div className="wht-box">
                            <div className="thumb-img">
                              <div className="poster_img">
                                <img
                                  src={
                                    DE?.displayImage
                                      ? `${Config.API_HOST_URL_live}${DE?.displayImage}`
                                      : logo
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = logo;
                                  }}
                                  alt=""
                                  key={i}
                                />
                              </div>
                              <div className="text_info">
                                <h5>{DE?.eventName}</h5>
                                <p>
                                  {DE?.eventDescription
                                    ? DE?.eventDescription?.slice(0, 185)
                                    : "No Description"}{" "}
                                  {DE?.eventDescription
                                    ? DE?.eventDescription?.length > 185 && (
                                        <span
                                          className="description cr"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            showReadMore(
                                              DE?.eventName,
                                              DE?.eventDescription
                                            )
                                          }
                                        >
                                          ...read more
                                        </span>
                                      )
                                    : ""}
                                </p>
                              </div>
                              <span className="date">
                                <FaCalendarWeek className="icon" />
                                {DE?.eventStartdate
                                  ? convert(DE?.eventStartdate?.split("T")[0])
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        <section className={`session-section ${isLoading ? "load" : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div
                      className="skeleton-line skeleton-title mb-4"
                      style={{ width: "20%" }}
                    ></div>
                  </div>
                ) : (
                  <h2>Session/Class</h2>
                )}
                {sessionList?.length === 0 ? (
                  <div className=" f-20-600">No Data Found</div>
                ) : (
                  ""
                )}
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      {isLoading ? (
                        <div className="skeleton-loader inner-loader-btn">
                          <div className="skeleton-btn tab"></div>
                        </div>
                      ) : (
                        <TabList
                          onChange={handleChange2}
                          variant="scrollable"
                          scrollButtons={false}
                          aria-label="lab API tabs example"
                          className={`session-tab ${isLoading ? "w-50" : ""}`}
                        >
                          {sessionList?.map((se, i) => (
                            <Tab
                              key={i}
                              label={`${se.day + " " + se.date}`}
                              value={`${i + 1}`}
                              className="head-tab"
                            />
                          ))}
                        </TabList>
                      )}
                    </Box>

                    {seDetail?.classList?.map((de, i) => (
                      <div className="tab-content" key={i}>
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-12">
                            <div className="left-tab">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  {isLoading ? (
                                    <div className="skeleton-loader inner-loader-rounded mb-3">
                                      <div className="skeleton-round"></div>
                                    </div>
                                  ) : speakerId ? (
                                    de?.classId === speakerId?.classId ? (
                                      <img
                                        src={
                                          speakerId?.speakerimage
                                            ? `${Config.API_HOST_URL_live}${speakerId?.speakerimage}`
                                            : logo
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = logo;
                                        }}
                                        alt=""
                                        className={`tab-img ${
                                          speakerId?.speakerimage
                                            ? ""
                                            : "hide_brr"
                                        }`}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          de?.speakerInfos[0]?.speakerimage
                                            ? `${Config.API_HOST_URL_live}${de?.speakerInfos[0]?.speakerimage}`
                                            : logo
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = logo;
                                        }}
                                        alt=""
                                        className={`tab-img ${
                                          de?.speakerInfos[0]?.speakerimage
                                            ? ""
                                            : "hide_brr"
                                        }`}
                                      />
                                    )
                                  ) : (
                                    <img
                                      src={
                                        de?.speakerInfos[0]?.speakerimage
                                          ? `${Config.API_HOST_URL_live}${de?.speakerInfos[0]?.speakerimage}`
                                          : logo
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = logo;
                                      }}
                                      alt=""
                                      className={`tab-img ${
                                        de?.speakerInfos[0]?.speakerimage
                                          ? ""
                                          : "hide_brr"
                                      }`}
                                    />
                                  )}
                                </div>
                                <div>
                                  {isLoading ? (
                                    <div className="skeleton-loader">
                                      <div
                                        className="skeleton-line skeleton-title mb-4"
                                        style={{ width: "100%" }}
                                      ></div>
                                    </div>
                                  ) : de?.speakerInfos?.length > 0 ? (
                                    speakerId ? (
                                      de?.classId === speakerId?.classId ? (
                                        <Link
                                          to={`/speaker-public-profile`}
                                          className="common_btn"
                                          state={{
                                            speakerId: speakerId?.speakerId,
                                          }}
                                        >
                                          Contact
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/speaker-public-profile`}
                                          className="common_btn"
                                          state={{
                                            speakerId:
                                              de?.speakerInfos[0]?.speakerId,
                                          }}
                                        >
                                          Contact
                                        </Link>
                                      )
                                    ) : (
                                      <Link
                                        to={`/speaker-public-profile`}
                                        className="common_btn"
                                        state={{
                                          speakerId:
                                            de?.speakerInfos[0]?.speakerId,
                                        }}
                                      >
                                        Contact
                                      </Link>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {isLoading ? (
                                <div className="skeleton-loader">
                                  <div
                                    className="skeleton-line skeleton-title mb-4"
                                    style={{ width: "100%" }}
                                  ></div>
                                </div>
                              ) : de?.speakerInfos?.length > 0 ? (
                                <Select
                                  isMulti={false}
                                  name="colors"
                                  options={de?.speakerInfos?.map((sp) => {
                                    return {
                                      label: sp?.speakerName,
                                      value: sp?.speakerId,
                                    };
                                  })}
                                  isClearable={false}
                                  className="company-multi-select"
                                  classNamePrefix="select"
                                  placeholder="Select Speaker"
                                  filterOption={customFilter}
                                  value={de?.speakerInfos
                                    ?.map((sp) => {
                                      return {
                                        ...sp,
                                        label: sp?.speakerName,
                                        value: sp?.speakerId,
                                      };
                                    })
                                    ?.filter(
                                      (obj) => {
                                        if (speakerId) {
                                          if (
                                            de?.classId === speakerId?.classId
                                          ) {
                                            return (
                                              obj.value === speakerId?.speakerId
                                            );
                                          }

                                          return (
                                            obj.value ===
                                            de?.speakerInfos[0]?.speakerId
                                          );
                                        }
                                        return (
                                          obj.value ===
                                          de?.speakerInfos[0]?.speakerId
                                        );
                                      }
                                      // obj.value === selectConference?.value
                                    )}
                                  onChange={(e) => handleSpeaker(de, e?.value)}
                                />
                              ) : (
                                // <select
                                //   value={
                                //     speakerId
                                //       ? de?.classId === speakerId?.classId
                                //         ? speakerId?.speakerId
                                //         : de?.speakerInfos[0]?.speakerId
                                //       : de?.speakerInfos[0]?.speakerId
                                //   }
                                //   onChange={(e) =>
                                //     handleSpeaker(de, e.target.value)
                                //   }
                                //   className="form-select mb-2"
                                // >
                                //   {de?.speakerInfos?.map((sp, i) => (
                                //     <option value={sp?.speakerId} key={i}>
                                //       {sp?.speakerName}
                                //     </option>
                                //   ))}

                                //   {/* <h4>{de?.speakerInfos[0]?.speakerName}</h4> */}
                                // </select>
                                ""
                              )}

                              {isLoading ? (
                                <div className="skeleton-loader">
                                  <div className="skeleton-line skeleton-title mb-4"></div>
                                  <div className="skeleton-line"></div>
                                  <div className="skeleton-line"></div>
                                </div>
                              ) : (
                                <ul>
                                  {de?.keyarea?.length > 0 ? (
                                    <li className="key-area">
                                      <div className="icon">
                                        <img
                                          src={"/assets/icons/infocircle.svg"}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                      <div className="info">
                                        <h5>Key Area : </h5>
                                        {de?.keyarea?.map((cat, i) => (
                                          <p key={i}>
                                            {cat?.courseCategoryCode} -{" "}
                                            {cat?.courseCategoryName}
                                          </p>
                                        ))}
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {de?.learningObjectives?.length > 0 ? (
                                    <li className="key-area">
                                      <div className="icon">
                                        <img
                                          src={"/assets/icons/infocircle.svg"}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                      <div className="info">
                                        <h5>Learning Objectives : </h5>
                                        {de?.learningObjectives?.map(
                                          (lear, i) => (
                                            <p key={i}>
                                              {lear?.courseCategoryCode} -{" "}
                                              {lear?.courseCategoryName}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {de?.trainingTopics?.length > 0 ? (
                                    <li className="key-area">
                                      <div className="icon">
                                        <img
                                          src={"/assets/icons/infocircle.svg"}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                      <div className="info">
                                        <h5>Training Topics : </h5>
                                        {de?.trainingTopics?.map(
                                          (traintopic, i) => (
                                            <p key={i}>
                                              {traintopic?.courseCategoryCode} -{" "}
                                              {traintopic?.courseCategoryName}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-9 col-lg-8 col-12">
                            {isLoading ? (
                              <div className="skeleton-loader mb-3">{box}</div>
                            ) : (
                              <div className="right-tab">
                                <ul className="location-block">
                                  <li>
                                    <img src={cities} alt="" title="Location" />
                                    <h6>
                                      Meeting Room No : {de.meetingRoomNumber}
                                    </h6>
                                  </li>

                                  <li>
                                    <img
                                      src={clockimg}
                                      alt=""
                                      title="Location"
                                    />
                                    <h6>
                                      {" "}
                                      {TimeTo12HourFormat(de?.startTime)} -{" "}
                                      {TimeTo12HourFormat(de?.endTime)}
                                    </h6>
                                  </li>
                                </ul>
                                <h3>{de?.classTitle}</h3>
                                {de?.descriptions && (
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {de?.descriptions}
                                  </p>
                                )}
                                {de?.documentPath?.length > 0 ? (
                                  <Accordion
                                    className="pannel"
                                    expanded={expanded === `panel${i + 1}`}
                                    onChange={handleChange(`panel${i + 1}`)}
                                  >
                                    <AccordionSummary
                                      className="pannel_title"
                                      aria-controls="panel1d-content"
                                      id="panel1d-header"
                                    >
                                      Session/Class Documents
                                    </AccordionSummary>
                                    <AccordionDetails className="pannel_contain">
                                      {de?.documentPath?.map((docPth, no) => (
                                        <div
                                          className="document-block "
                                          key={no}
                                        >
                                          <div className="d-flex gap-3">
                                            <div className="num">{no + 1}.</div>
                                            <div className="text_data">
                                              {docPth.fileName
                                                ? docPth.fileName
                                                : "Session PDF"}
                                            </div>
                                          </div>
                                          <Link
                                            to={`${Config.API_HOST_URL_live}/${docPth.documentPath}`}
                                            target="_blank"
                                            className="btn_dlt"
                                          >
                                            <HiOutlineEye className="icon" />
                                          </Link>
                                        </div>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                ) : (
                                  ""
                                )}
                                {de?.speakerInfos?.length > 0 ? (
                                  <>
                                    {speakerId ? (
                                      de?.classId === speakerId?.classId ? (
                                        <>
                                          {speakerId?.speakerDocuments?.length >
                                          0 ? (
                                            <ShowSpeakerDocument
                                              expanded={expanded}
                                              handleChange={handleChange}
                                              speakerId={speakerId}
                                              de={de}
                                              i={i}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {speakerId?.speakerBrochers?.length >
                                          0 ? (
                                            <ShowSpeakerBrochures
                                              expanded={expanded}
                                              handleChange={handleChange}
                                              speakerId={speakerId}
                                              de={de}
                                              i={i}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {de?.speakerInfos[0]?.speakerDocuments
                                            ?.length ? (
                                            <ShowSpeakerDocument
                                              expanded={expanded}
                                              handleChange={handleChange}
                                              speakerId={speakerId}
                                              de={de}
                                              i={i}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {de?.speakerInfos[0]?.speakerBrochers
                                            ?.length > 0 ? (
                                            <ShowSpeakerBrochures
                                              expanded={expanded}
                                              handleChange={handleChange}
                                              speakerId={speakerId}
                                              de={de}
                                              i={i}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {de?.speakerInfos[0]?.speakerDocuments
                                          ?.length ? (
                                          <ShowSpeakerDocument
                                            expanded={expanded}
                                            handleChange={handleChange}
                                            speakerId={speakerId}
                                            de={de}
                                            i={i}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {de?.speakerInfos[0]?.speakerBrochers
                                          ?.length > 0 ? (
                                          <ShowSpeakerBrochures
                                            expanded={expanded}
                                            handleChange={handleChange}
                                            speakerId={speakerId}
                                            de={de}
                                            i={i}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </section>
        <section className={`Exhiubitor-section ${isLoading ? "load" : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div
                      className="skeleton-line skeleton-title mb-4"
                      style={{ width: "20%" }}
                    ></div>
                  </div>
                ) : (
                  <h2>Company</h2>
                )}
                {dataOfEvent?.exhibitorList?.length === 0 ? (
                  <div className=" f-20-600">No Data Found</div>
                ) : (
                  ""
                )}
              </div>
              {isLoading ? (
                <>
                  {[...Array(3).keys()]?.map((x) => (
                    <div className="col-md-4" key={x}>
                      <div className="skeleton-loader mb-3">{box}</div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {dataOfEvent?.exhibitorList
                    ?.slice(
                      (currentPage - 1) * Pagesize,
                      (currentPage - 1) * Pagesize + Pagesize
                    )
                    ?.map((x, i) => (
                      <div
                        className="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3"
                        key={i}
                      >
                        <div className=" exbitor-card">
                          <div className="parlex">
                            <Link
                              to={`/exhibitor-profile/${x?.companyId}/${id}`}
                              target="_blank"
                              className="profile_button"
                            >
                              <GrView className="icon" />
                            </Link>
                          </div>
                          <div className="img-box">
                            <img
                              src={
                                x?.companyImage
                                  ? `${Config.API_HOST_URL_live}${x?.companyImage}`
                                  : noLogo
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = noLogo;
                              }}
                              alt=""
                            />
                          </div>
                          <div className="info">
                            <h4 className="name w-auto">
                              {x?.companyName ? x?.companyName : x?.userName}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="page_nation">
                    <NewPaginations
                      className="pagination-bar justify-content-end"
                      currentPage={currentPage}
                      totalCount={dataOfEvent?.exhibitorList?.length}
                      pageSize={Pagesize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <section className="exhibitor_box_sec">
          <div className="container">
            <div className="row">
              <div className="top_title">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <div
                      className="skeleton-line skeleton-title mb-4"
                      style={{ width: "20%" }}
                    ></div>
                  </div>
                ) : (
                  <h5>
                    <span>Gallery</span>
                  </h5>
                )}
                <h2>Conference Photos Gallery</h2>
                <p>
                  Known as the Conference Planner of the Year, the Conference
                  Planner (CP) unites thousands of school nutrition program
                  operators, industry partners, and allied organizations for an
                  unparalleled educational and networking experience every July.
                </p>
              </div>
              <div className="col-md-12">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {glList?.map((ga, i) => (
                    <SwiperSlide key={i}>
                      {isLoading ? (
                        <div className="skeleton-loader">{box}</div>
                      ) : (
                        <div className="popup">
                          {i % 2 === 0 ? (
                            <h5>{ga?.title ? ga?.title : "Gallery Images"}</h5>
                          ) : (
                            ""
                          )}
                          <ImgPopup img={ga.image} className="img-fluid" />
                          {i % 2 !== 0 ? (
                            <h5>{ga?.title ? ga?.title : "Gallery Images"}</h5>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <WebFooter />
      </Suspense>
    </>
  );
}

export default ConferencesDetail;
