import React, { useEffect, useState } from "react";
import "./index.scss";
import QRCode from "react-qr-code";
import { Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { customFilter } from "../../../Modules/Components/CustomFilterForReactSelect";
import { GetCallApi } from "../../../Action/Action";
import Select from "react-select";
import { IndustryDrop } from "../../../Modules/Components/AssociationMember";

const HighLinerFoodserviceLogo = "/assets/images/HighLinerFoodserviceLogo.png";
const logo = "/assets/images/ohio_logo_school.png";

function CompanyProfileQr() {
  const [openCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [selectCompany, setSelectCompany] = useState();

  useEffect(() => {
    getConfList(LogDetail?.userId);
    getLEAList();
    getAgencyist();
  }, []);

  const getConfList = async (uId) => {
    setConList([]);
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${uId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let co = res?.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.conferenceId,
          label: x?.conferenceName,
        };
      });
      setConList(co);
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        LogDetail?.userId
      }`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let data = res?.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.associationMemberId,
          label: x?.name,
        };
      });
      setLeaList(data);
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        LogDetail?.userId
      }`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let data = res?.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.associationMemberId,
          label: x?.name,
        };
      });
      setAgencyList(data);
    }
  };

  const handleChangeCompany = (e) => {
    setSelectCompany(e);
  };
  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };
  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };
  const handleChangeConference = (e) => {
    setConfId(e);
  };
  const HandleClearFilter = () => {
    setConfId();
    setSelectCompany();
    setSelectAgency([]);
    setSelectLea([]);
  };

  return (
    <>
      <section className="company-profile-qr-list">
        <div className="main_wrapper">
          <div className="card mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Conference</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conList?.filter((obj) => obj.value === confId)}
                      onChange={(e) => handleChangeConference(e?.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">LEA Member</label>
                    <Select
                      isMulti
                      name="colors"
                      options={leaList}
                      className="company-multi-select"
                      classNamePrefix="select"
                      placeholder="Select LEA Member"
                      filterOption={customFilter}
                      value={leaList?.filter((obj) =>
                        selectLea?.includes(obj.value)
                      )}
                      onChange={handleChangeLea}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label">State Agencies</label>
                    <Select
                      isMulti
                      name="colors"
                      options={agencyList}
                      className="company-multi-select"
                      classNamePrefix="select"
                      placeholder="Select State Agencies"
                      filterOption={customFilter}
                      value={agencyList?.filter((obj) =>
                        selectAgency?.includes(obj.value)
                      )}
                      onChange={handleChangeAgency}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <IndustryDrop
                    isMulti={false}
                    selectedIndustry={[selectCompany]}
                    handleChangeIndustry={(e) => handleChangeCompany(e?.value)}
                  />
                </div>

                <div className="col-md-6 d-flex justify-content-start mt-3">
                  <div className="form-group mt-auto">
                    {/* {userArray?.length > 0 ? ( */}
                    <Button
                      className="common_btn ms-3"
                      // type="submit"
                      disabled={openCircle}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Apply Filter"
                      )}
                    </Button>{" "}
                    <Button
                      className="back_button ms-3"
                      // type="submit"

                      onClick={() => HandleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                    {/* ) : (
                        ""
                      )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-list-content">
            <div className="CompanyProfileQr">
              <img src={logo} width={100} className="img-fluid" alt="" />
              <div className="content">
                <h1>
                  Let&apos;s Chat
                  <span>After the show!</span>
                </h1>
                <div className="line"></div>
                <QRCode value={""} size={200} />
                <img
                  src={HighLinerFoodserviceLogo}
                  className="img-fluid mt-3 qrlogo"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CompanyProfileQr;
