import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { AttendeeRegistrationConference } from "../../../../../../reducers/Common";
import ReactQuill from "react-quill";
import {
  // CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import {
  SwalMessage,
  ToastError,
  ToastSuccess,
} from "../../../../../Components/Toast";
import { isPositiveInteger } from "../../../../../Components/Validation";
import PayByCash from "../../../../../Components/PaybyCash";
import StripeCardPayment from "../../../../../Components/StripeCardPayment";

const deleteIcon = "/assets/images/deleteIcon.png";

function EditAssociationUserPayment(props) {
  const { id, invoiceId } = useParams();
  const { companyData } = props;
  const [subtotal, setSubTotal] = useState("");
  const { setValue } = useForm();
  const [clientPublicKey, setClientPublicKey] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [btnLoad1, setBtnLoad1] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [payment, setPayment] = useState();
  const [acBtn, setAcBtn] = useState(true);

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  let ATRC = useSelector((state) => state.Common?.ATRC);
  let dispatch = useDispatch();
  let nav = useNavigate();
  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.subtotal);
      setSubTotal(companyData?.subtotal);
      // if (companyData?.paymentMode !== "Invoice") {
      PCSec();
      // }
    }
    // eslint-disable-next-line
  }, [companyData]);
  let adAM =
    parseFloat(subtotal) - companyData?.paymentDetail?.additionalAmount;
  let showADAM = isPositiveInteger(adAM);

  const CLSec = async () => {
    if (subtotal === 0 || adAM === 0) {
      if (companyData?.paymentMode !== "Invoice") {
        PaymentApiForOnline({
          isPayByCash: adAM === 0 ? null : true,
          note:
            adAM === 0
              ? `Amount is not changed`
              : `The refunded cash amount is ${companyData?.paymentDetail?.actualAmount}, resulting in a final amount of 0.`,
        });
      } else {
        handlePayByCash({
          isPayByCash: adAM === 0 ? null : true,
          note:
            adAM === 0
              ? `Amount is not changed`
              : `The refunded cash amount is ${companyData?.paymentDetail?.actualAmount}, resulting in a final amount of 0.`,
        });
      }
    } else {
      setPayment(true);
    }
  };

  const PCSec = async () => {
    let seData = {
      url: `/api/AssociationPayment/GetPaymentKeysByAssociationId?associationId=${id}`,
      body: {},
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200 && res?.data.success) {
      const clientPub = res?.data?.payload?.publicKey;
      setClientPublicKey(clientPub);
    } else {
      ToastError(res?.data.message);
    }
  };

  const stripePromise = loadStripe(clientPublicKey);

  const RequestInvoice = async (data) => {
    setBtnLoad(true);
    let addiAmount =
      parseFloat(subtotal) - companyData?.paymentDetail?.additionalAmount;
    let seData = {
      url: `/api/AssociationPayment/AddAssociationInvoice`,
      body: {
        ...data,
        associationInvoiceId: companyData?.paymentDetail?.associationInvoiceId,
        invoiceNumber: companyData?.paymentDetail?.invoiceNumber,
        createdBy: LogDetails?.userId,
        totalAmount: subtotal,
        addressLine1: data?.address1,
        zipCode: data?.zip,
        invoiceInstructions: data?.editorValue,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        // discountValue: discountValue?.discountValue,
        discountValue: discountValue?.reduce(
          (acc, curr) => acc + curr?.discountValue,
          0
        ),
        promoCodeList:
          discountValue?.map((x) => {
            return {
              promoCodeId: x?.promoCodeId,
              discountAmount: x?.discountValue,
            };
          }) ?? [],
        associationName: companyData?.name,
        associationPrice: subtotal.toString(),
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
        associationMemberId: companyData?.associationMemberId,
        companyName: companyData?.companyName,
        paymentAddress:
          ATRC?.paymentRemittanceAddress +
          " \r\n" +
          ATRC?.paymentCity +
          " " +
          ATRC?.paymentState +
          " " +
          ATRC?.paymentZip,
        registerType: "AssociationMember",
        isEdit: companyData?.paymentDetail?.invoiceStatus === "Complete",
        actualAmount: companyData?.paymentDetail?.actualAmount,
        additionalAmount: addiAmount,
        isRefund:
          adAM === 0 ? null : isPositiveInteger(addiAmount) ? false : true,
        // change this before implement
        refundStatus:
          adAM === 0 ? null : isPositiveInteger(addiAmount) ? null : "Success",
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res?.data?.success) {
      await SendUser({
        paymentMode: "Invoice",
        associationInvoiceId: res?.data?.payload?.associationInvoiceId,
        associationPaymentId: res?.data?.payload?.associationPaymentId,
      });
      dispatch(AttendeeRegistrationConference());
      nav(
        `/association-invoice-list/view/${res?.data?.payload?.associationInvoiceId}`,
        { replace: true }
      );
    } else {
      setBtnLoad(false);
    }
  };

  const ShowInvoice = ({ RequestInvoice, invoiceId }) => {
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();
    const [editorValue, setEditorValue] = useState("");
    const [derror, setDerror] = useState();

    useEffect(() => {
      if (invoiceId) {
        getInvoiceData(invoiceId);
      }
      // eslint-disable-next-line
    }, [invoiceId]);
    const getInvoiceData = async (ind) => {
      let res = await GetCallApi({
        url: `/api/AssociationPayment/GetAssociationMembershipDataByAssociationPaymentId?associationPaymentId=${ind}`,
      });
      if (res?.status === 200) {
        if (res?.data.success) {
          let useDe = res?.data.payload.ai;
          setValue("name", useDe?.name);
          setValue("email", useDe?.email);
          setValue("address1", useDe?.addressLine1);
          setValue("purchaseOrderNumber", useDe?.purchaseOrderNumber);
          setValue("city", useDe?.city);
          setValue("State", useDe?.state);
          setValue("zip", useDe?.zipCode);
          setEditorValue(useDe?.invoiceInstructions);
        }
      }
    };

    const handleEditotValue = (value) => {
      if (value?.length > 1000) {
        setDerror({ ed: "Max 1000 character" });
      } else {
        setDerror({ ed: "" });
        setEditorValue(value);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data, editorValue });
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              Email Invoice To
              <span className="red-color"> *</span>
            </label>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                {...register("email", {
                  required: "This field is require",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors?.email && (
                <span className="error_text">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <div>
                <label className="form-label">Address</label>
              </div>
              <label className="form-label">
                Name<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                {...register("name", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter max 500 charactor",
                  },
                })}
              />
              {errors?.name && (
                <span className="error_text">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3 mt-auto">
            <div className="form-group  mb-3">
              <label className="form-label">
                Address Line 1<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                {...register("address1", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.address1 && (
                <span className="error_text">{errors.address1.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <label className="form-label">PO Number </label>
              <input
                type="text"
                placeholder="Enter PO Number"
                className="form-control"
                {...register("purchaseOrderNumber", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.purchaseOrderNumber && (
                <span className="error_text">
                  {errors.purchaseOrderNumber.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                City<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                {...register("city", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.city && (
                <span className="error_text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                State<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter State"
                {...register("State", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.State && (
                <span className="error_text">{errors.State.message}</span>
              )}
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                Zip Code
                <span className="red-color"> *</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "You can enter max 10 charactor",
                  },
                })}
              />
              {errors?.zip && (
                <span className="error_text">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="form-label">Invoice Introduction</label>
            <ReactQuill
              value={editorValue}
              onChange={(value) => handleEditotValue(value)}
              // style={{ height: "200px", overflow: "auto" }}
              className="ql_show"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              theme="snow"
            />
            {derror?.ed && (
              <span role="alert" className="d-block error_text">
                {derror.ed}
              </span>
            )}
          </div>
          <div className="col-md-12 ">
            <Button className="common_btn mt-3" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const SendUser = async (data) => {
    let Dt =
        companyData?.associationCompanyList
          ?.filter((curr) => curr?.discount > 0)
          ?.reduce((acc, curr) => acc + curr?.discount, 0) ?? 0,
      ta = subtotal + Dt;

    let user = companyData?.associationCompanyList?.map((x) => {
      let cost = x?.totalAmount ?? 0,
        myDISAmount = cost > 0 ? Dt / (ta / cost) : 0,
        FA = cost > 0 ? cost - myDISAmount : 0;
      return {
        ...x,
        totalAmount: FA ? parseFloat(FA?.toFixed(2)) : FA,
        discount: myDISAmount
          ? parseFloat(myDISAmount?.toFixed(2))
          : myDISAmount,
      };
    });
    let send = { ...data, ...companyData, associationCompanyList: user };

    await PostCallApi({
      url: `/api/AssociationPayment/GroupAssociationCompanyAllocation`,
      body: send,
    });
  };

  const SaveAdditionalPayment = async (data) => {
    setBtnLoad(true);
    let addiAmount =
      parseFloat(subtotal) - companyData?.paymentDetail?.additionalAmount;
    let paymentData = {
      // ...companyData,
      ...data,
      userId: LogDetails?.userId,
      createdBy: LogDetails?.userId,
      email: LogDetails?.email,
      amount: subtotal,
      discountAmount: discountValue?.reduce(
        (acc, curr) => acc + curr?.discountValue,
        0
      ),
      // promoCodeList:
      //   discountValue?.map((x) => {
      //     return {
      //       promoCodeId: x?.promoCodeId,
      //       discountAmount: x?.discountValue,
      //     };
      //   }) ?? [],
      associationId: companyData?.associationId,
      paymentMode: companyData?.paymentMode,
      registerType: "AssociationMember",
      isEdit: true,
      actualAmount: companyData?.paymentDetail?.actualAmount,
      additionalAmount: addiAmount,
      paymentProcess:
        adAM === 0
          ? null
          : isPositiveInteger(addiAmount)
          ? "Additional"
          : "Refund",
      isRefund:
        adAM === 0 ? null : isPositiveInteger(addiAmount) ? false : true,
      // change this before implement
      refundStatus:
        adAM === 0 ? null : isPositiveInteger(addiAmount) ? null : "Success",
      invoiceNumber: companyData?.paymentDetail?.invoiceNumber,
      paymentId: companyData?.paymentDetail?.associationPaymentId,
      paymentReference: companyData?.paymentDetail?.paymentReference,
    };
    let sveCC = {
      url: `/api/AssociationPayment/AssociationPaymentAdditionalOrRefundProcess`,
      body: paymentData,
    };

    let res = await PostCallApi(sveCC);
    if (res?.status === 200 && res?.data.isPaid) {
      await SendUser({
        paymentMode: companyData?.paymentMode,
        associationInvoiceId: companyData?.paymentDetail?.associationInvoiceId,
        associationPaymentId: companyData?.paymentDetail?.associationPaymentId,
      });
      dispatch(AttendeeRegistrationConference());
      let NVID =
        companyData?.paymentMode === "Invoice"
          ? companyData?.paymentDetail?.associationInvoiceId
          : companyData?.paymentDetail?.associationPaymentId;
      NavtoReceipt(companyData?.paymentMode, NVID);
      ToastSuccess(res?.data.message);
    } else {
      setBtnLoad(false);
      ToastError(res?.data?.message);
    }
  };

  const NavtoReceipt = (mode, id) => {
    if (mode === "Invoice") {
      nav(`/association-invoice-list/view/${id}`, {
        replace: true,
      });
    } else {
      nav(`/association-stripe-recipe/${id}`, {
        replace: true,
      });
    }
  };

  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  const applyPromo = async (data) => {
    setBtnLoad1(true);
    let seData = {
      url: `/api/Payment/GetAmountAfterApplyPromoCodeOnConference`,
      body: { ...data, type: "Association" },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let am = res?.data.payload;

      setSubTotal(am?.finalAmount);
      let disc =
        discountValue?.length > 0
          ? [
              ...discountValue,
              { ...am, promoCode: data?.promoCode, codeId: generateId(10) },
            ]
          : [{ ...am, promoCode: data?.promoCode, codeId: generateId(10) }];
      setDiscountValue(disc);

      setBtnLoad1(false);
    } else {
      setBtnLoad1(false);
      ToastError(res?.data.message);
    }
  };
  const handlePromo = (d) => {
    if (d?.promoCode) {
      let send = {
        amount: subtotal,
        conferenceId: id,
        userId: LogDetails?.userId,
        promoCode: d?.promoCode,
      };
      applyPromo(send, d);
    } else {
      SwalMessage("Please enter discount code");
    }
  };
  const deletePromo = (id) => {
    let dv = discountValue?.filter((x) => x?.codeId !== id);
    setDiscountValue(dv);
    let vl = discountValue?.find((x) => x?.codeId === id).discountValue;
    setSubTotal(subtotal + vl);
  };

  const DiscountApply = ({ handlePromo, subtotal, btnLoad }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    return (
      <form onSubmit={handleSubmit(handlePromo)} className="mt-3">
        <div className="row">
          <div className="col-md-10">
            <div className="form-group">
              <label className="form-label">Discount Code</label>
              <input
                type="text"
                placeholder="Enter Discount Code"
                className="form-control"
                disabled={subtotal === 0 ? true : false}
                {...register("promoCode", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors.promoCode && (
                <span role="alert" className="error_text">
                  {errors.promoCode.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-2 my-auto text-end pe-3">
            <Button
              className="common_btn"
              type="submit"
              disabled={subtotal === 0 ? true : btnLoad ? true : false}
              // disabled={discountValue ? true : false}
            >
              {subtotal === 0 ? (
                "Apply"
              ) : btnLoad ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#fff",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Apply"
              )}
            </Button>
            {/* )} */}
          </div>
        </div>
      </form>
    );
  };

  const handlePayByCash = (data) => {
    let pd = companyData?.paymentDetail;
    if (companyData?.paymentMode === "Invoice") {
      let send = {
        ...data,
        name: pd?.name,
        email: pd?.email,
        address1: pd?.addressLine1,
        purchaseOrderNumber: pd?.purchaseOrderNumber,
        city: pd?.city,
        state: pd?.state,
        zip: pd?.zipCode,
        invoiceStatus: pd?.invoiceStatus,
        editorValue: pd?.invoiceInstructions,
      };
      RequestInvoice(send);
    } else {
      if (acBtn && adAM < 0) {
        SaveAdditionalPayment(data);
      } else {
        PaymentApiForOnline(data);
      }
    }
  };

  const PaymentApiForOnline = async (data) => {
    let addiAmount =
      parseFloat(subtotal) - companyData?.paymentDetail?.additionalAmount;
    let paymentData = {
      ...companyData,
      ...data,
      userId: LogDetails?.userId,
      email: LogDetails?.email,
      amount: subtotal,
      discountAmount: discountValue?.reduce(
        (acc, curr) => acc + curr?.discountValue,
        0
      ),
      promoCodeList:
        discountValue?.map((x) => {
          return {
            promoCodeId: x?.promoCodeId,
            discountAmount: x?.discountValue,
          };
        }) ?? [],
      associationMembershipsTypesId: companyData?.associationMembershipsTypesId,
      associationId: companyData?.associationId,
      paymentMode: "Online",
      registerType: "AssociationMember",
      isEdit: true,
      actualAmount: companyData?.paymentDetail?.actualAmount,
      additionalAmount: addiAmount,
      isRefund:
        adAM === 0 ? null : isPositiveInteger(addiAmount) ? false : true,
      // change this before implement
      refundStatus:
        adAM === 0 ? null : isPositiveInteger(addiAmount) ? null : "Success",
      invoiceNumber: companyData?.paymentDetail?.invoiceNumber,
      paymentId: companyData?.paymentDetail?.associationPaymentId,
      paymentReference: companyData?.paymentDetail?.paymentReference,
    };
    let sveCC = {
      url: `/api/AssociationPayment/AssociationPaymentProcess`,
      body: paymentData,
    };
    setBtnLoad(true);
    let res = await PostCallApi(sveCC);
    if (res?.status === 200) {
      if (res?.data.isPaid) {
        await SendUser({
          paymentMode: "Online",
          associationPaymentId: res?.data?.paymentId,
        });
        dispatch(AttendeeRegistrationConference());
        nav(`/association-stripe-recipe/${res?.data?.paymentId}`, {
          replace: true,
        });

        ToastSuccess(res?.data.message);
      } else {
        setBtnLoad(false);

        ToastError(res?.data?.message);
      }
    } else {
      setBtnLoad(false);
      ToastError(res?.data?.message);
    }
  };

  return (
    <>
      <div className="">
        <div className="title attend-title mb-3">Payment Details</div>
        <div className="col-md-8">
          <div className="conference_details border-bottom-0 mb-4">
            <ul>
              <li>
                Fees
                <span>
                  {companyData?.subtotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>
              {discountValue?.length > 0 ? (
                <>
                  <h5 className="mt-3">Discount Code</h5>
                  {discountValue?.map((x, i) => (
                    <li key={i}>
                      <p>
                        {/* Discount */}
                        {x?.promoCode}
                        <span className="ms-auto">
                          {" "}
                          -
                          {x
                            ? x.discountValue?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}
                        </span>
                        {!payment ? (
                          <button
                            type="button"
                            className="deleteBtn"
                            onClick={() => deletePromo(x?.codeId)}
                          >
                            <img
                              src={deleteIcon}
                              className="img-fluid"
                              alt=""
                            />
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                    </li>
                  ))}
                  <li>
                    <p>
                      Total Discount
                      <span className="ms-auto">
                        {" "}
                        -
                        {discountValue
                          ?.reduce((acc, curr) => acc + curr?.discountValue, 0)
                          ?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </span>
                    </p>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <div className="subtotal">
              <p>
                Total{" "}
                <span>
                  {subtotal
                    ? subtotal?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "0.00"}
                </span>
              </p>
            </div>
            <ul>
              {companyData?.paymentDetail?.invoiceStatus === "Complete" ||
              companyData?.paymentDetail?.paymentMode !== "Invoice" ? (
                <>
                  <li>
                    Old Paid Amount
                    <span>
                      -
                      {companyData?.paymentDetail?.additionalAmount?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    </span>
                  </li>
                  <li>
                    {showADAM
                      ? "Additional Payable Amount"
                      : adAM === 0
                      ? "Amount is not changed"
                      : "Refundable Amount"}
                    <span>
                      {adAM?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <div className="row">
              <div className="col-md-10 ">
                {!payment && (
                  <DiscountApply
                    handlePromo={handlePromo}
                    discountValue={discountValue}
                    subtotal={subtotal}
                    btnLoad={btnLoad1}
                  />
                )}
              </div>
              <div className="col-md-2 text-end my-auto">
                {!payment && (
                  <div className="mt-3 text-end">
                    <Button
                      className="common_btn"
                      onClick={CLSec}
                      disabled={btnLoad}
                    >
                      {btnLoad ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : showADAM || subtotal === 0 || adAM === 0 ? (
                        "Pay"
                      ) : (
                        "Refund"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {payment ? (
          companyData?.paymentMode === "Invoice" &&
          companyData?.paymentDetail?.invoiceStatus !== "Complete" ? (
            <ShowInvoice
              RequestInvoice={RequestInvoice}
              invoiceId={invoiceId}
            />
          ) : (
            <>
              <div className="d-flex gap-3 mb-3">
                {showADAM ? (
                  <>
                    <Button
                      className={`${acBtn ? "common_btn" : "back_button"}`}
                      onClick={() => setAcBtn(true)}
                    >
                      Pay Online
                    </Button>

                    <Button
                      className={`${!acBtn ? "common_btn" : "back_button"}`}
                      onClick={() => setAcBtn(false)}
                    >
                      Pay By Cash
                    </Button>
                  </>
                ) : companyData?.paymentMode === "Invoice" ? (
                  <Button className={`common_btn`}>Refund By Cash</Button>
                ) : (
                  <>
                    <Button
                      className={`${acBtn ? "common_btn" : "back_button"}`}
                      onClick={() => setAcBtn(true)}
                    >
                      Refund Online
                    </Button>

                    <Button
                      className={`${!acBtn ? "common_btn" : "back_button"}`}
                      onClick={() => setAcBtn(false)}
                    >
                      Refund By Cash
                    </Button>
                  </>
                )}
              </div>
              {showADAM ? (
                acBtn ? (
                  <Elements stripe={stripePromise}>
                    <StripeCardPayment CallBackApi={SaveAdditionalPayment} />
                  </Elements>
                ) : (
                  <PayByCash
                    handlePayByCash={handlePayByCash}
                    btnLoad={btnLoad}
                  />
                )
              ) : (
                <PayByCash
                  handlePayByCash={handlePayByCash}
                  btnLoad={btnLoad}
                />
              )}
            </>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default EditAssociationUserPayment;
