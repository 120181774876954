export function sortArray(arr, order = "asc") {
  // Check if the array is empty
  if (!Array.isArray(arr) || arr.length === 0) return arr;

  // Determine if the array contains only numbers or strings
  const isNumberArray = arr.every((item) => typeof item?.sortKey === "number");
  const isStringArray = arr.every((item) => typeof item?.sortKey === "string");

  // Sort the array based on type and order
  if (isNumberArray) {
    return arr.sort((a, b) =>
      order === "asc" ? a?.sortKey - b?.sortKey : b?.sortKey - a?.sortKey
    );
  } else if (isStringArray) {
    return arr.sort((a, b) =>
      order === "asc"
        ? a?.sortKey?.localeCompare(b?.sortKey)
        : b?.sortKey?.localeCompare(a?.sortKey)
    );
  } else {
    throw new Error("Array must contain only strings or only numbers.");
  }
}
