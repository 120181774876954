import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import "./index.scss";
import { Button, CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

function EditUser() {
  const { id } = useParams();
  const [roleList, setRoleList] = useState([]);
  // const [selectUser, setSelectUser] = useState([]);
  // const [derror, setDerror] = useState();
  // const [comList, setComList] = useState([]);
  // const [comMul, setComMul] = useState([]);
  const [userType, setUserType] = useState("");

  const [roleVal, setRoleVal] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  let nav = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const loginDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    GetRoleList();
    // GetCompanyList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (roleList?.length > 0) {
      if (id) {
        getUserDetails();
      }
    }
    // eslint-disable-next-line
  }, [id, roleList]);

  const getUserDetails = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({ url: `/api/UserMst/EditUser?userId=${id}` });
    if (res?.status === 200 && res?.data.success) {
      let useDe = res?.data.payload;
      setRoleVal(
        roleList?.filter((x) => x.roleId === useDe.roleId)[0].roleName
      );
      setValue("firstName", useDe?.firstName);
      setValue("lastName", useDe?.lastName);
      setValue("phone", useDe?.phone);
      setValue("email", useDe?.email);
      setTimeout(() => {
        setValue("roleId", useDe?.roleId);
      }, 500);
      setUserType(useDe?.roleId);

      setValue("userId", useDe?.userId);
      setValue("password", useDe?.password);
      // setSelectUser(useDe?.roleData?.map((x)=> x?.roleId))
      // setRoleVal(useDe?.roleId);
      // if (useDe?.roleId === 4) {
      //   setTimeout(() => {
      //     setComMul(useDe?.comapnyList);
      //   }, 1000);
      // }
      setOpenCircle(false);
    } else {
      setOpenCircle(false);
    }
  };

  const onSubmit = async (data) => {
    // if (selectUser?.length === 0) {
    //   setDerror({ err: "Select minimum one role" })
    // } else if (selectUser?.length > 0) {
    //   setDerror({ err: "" })

    // let formData = new FormData()
    // selectUser?.map((c) => formData?.append('lstRoleId', c))

    let userD = {
      ...data,
      createdBy: loginDetail?.userId,
      UserType: roleList?.filter((x) => x.roleId === data.roleId)[0].roleName,
    };
    // if (data?.roleId === 4) {
    //   userD = { ...userD, CompanyList: comMul };
    // }

    // for (var key in userD) {
    //   formData.append(key, userD[key]);
    // }
    if (userD?.UserType === "Speaker") {
      let seData1 = {
        url: "/api/Speaker/CreateSpeaker",
        body: {
          ...userD,
          SpeakerName: userD?.firstName,
          SpeakerId: id ? id : "",
        },
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      setOpenCircle(true);
      let res1 = await PostCallApi(seData1);
      setOpenCircle(false);
      if (res1?.status === 200 && res1?.data.success) {
        setOpenCircle(true);
        let spId = res1?.data.payload?.speakerId;
        let seData = {
          url: "/api/UserMst/CreateUser",
          // body: formData,
          body: { ...userD, UserId: spId },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        };

        let res = await PostCallApi(seData);
        if (res?.status === 200 && res?.data.success) {
          ToastSuccess(res?.data.message);
          reset();
          setTimeout(function () {
            nav("/users", { replace: true });
          }, 2000);
        } else {
          ToastError(res?.data.message);
        }
      } else {
        ToastError(res1?.data.message);
      }
    } else {
      setOpenCircle(true);
      let seData = {
        url: "/api/UserMst/CreateUser",
        // body: formData,
        body: userD,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      };

      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data.success) {
        ToastSuccess(res?.data.message);
        reset();
        setTimeout(function () {
          nav("/users", { replace: true });
        }, 2000);
      } else {
        ToastError(res?.data.message);
      }
    }
    // }
  };
  const GetRoleList = () => {
    Axios.get(`/api/Role/RoleList?userId=${loginDetail?.userId}`, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data.success) {
            let comList = res?.data?.payload?.map((c) => {
              return {
                roleId: c?.roleId,
                roleName: c?.roleName,
                value: c?.roleId,
                label: c?.roleName,
              };
            });
            setRoleList(comList);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const handleBack = () => {
    nav("/users", { replace: true });
  };
  // const handleChangeComMulti = (e) => {
  //   setComMul(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };

  // const handleChangeUser = (e) => {
  //   setSelectUser(Array.isArray(e) ? e?.map((x) => x?.value) : [])
  // }

  const handleUserType = (e) => {
    setUserType(e);
    setValue("roleId", e);
  };

  return (
    <section className="edit_user">
      <div className="main_wrapper">
        <div className="title mb-3">{id ? "Edit" : "Create"} User</div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                {/* <div className="col-md-12">
                  <div className="title mb-3">
                    {id ? "Edit" : "Create"} User
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      First name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter first name"
                      {...register("firstName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.firstName && (
                      <span role="alert" className="error_text">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Last Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter last name"
                      {...register("lastName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.lastName && (
                      <span role="alert" className="error_text">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                {!id ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Password
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Password"
                        {...register("Password", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Password && (
                        <span role="alert" className="error_text">
                          {errors.Password.message}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Email
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter email"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                      })}
                    />
                    {errors.email && (
                      <span role="alert" className="error_text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Phone
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter phone"
                      {...register("phone", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span role="alert" className="error_text">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Select Role
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={
                        id
                          ? roleVal !== "Speaker"
                            ? roleList?.filter((x) => x?.roleName !== "Speaker")
                            : roleList
                          : roleList
                      }
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Role"
                      filterOption={customFilter}
                      value={roleList?.filter((obj) => obj.value === userType)}
                      onChange={(e) => handleUserType(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      {...register("roleId", {
                        required: "This field is required",
                        // onChange: (e) => setRoleVal(e.target.value),
                        valueAsNumber: true,
                      })}
                    >
                      <option value="">Select Role</option>
                      {id ? (
                        <>
                          {roleVal === "Speaker"
                            ? roleList &&
                              roleList?.map((role, i) => (
                                <option key={i} value={role.roleId}>
                                  {role.roleName}
                                </option>
                              ))
                            : roleList &&
                              roleList
                                ?.filter((x) => x?.roleName !== "Speaker")
                                ?.map((role, i) => (
                                  <option key={i} value={role.roleId}>
                                    {role.roleName}
                                  </option>
                                ))}
                        </>
                      ) : (
                        <>
                          {roleList &&
                            roleList?.map((role, i) => (
                              <option key={i} value={role.roleId}>
                                {role.roleName}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                    {errors.roleId && (
                      <span role="alert" className="error_text">
                        {errors.roleId.message}
                      </span>
                    )}
                    {/* <Select
                      isMulti
                      name="colors"
                      options={roleList}
                      className="company-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Role"
                      value={roleList.filter((obj) =>
                        selectUser?.includes(obj.value)
                      )}
                      onChange={handleChangeUser}
                    /> */}
                    {/* {derror?.err && (
                      <span role="alert" className="error_text">
                        {derror?.err}
                      </span>
                    )} */}
                  </div>
                </div>
                {/* {parseInt(roleVal) === 4 ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Select Company</label>
                      <Select
                        isMulti
                        name="colors"
                        options={comList}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Companys"
                        value={comList?.filter((obj) =>
                          comMul?.includes(obj.value)
                        )}
                        onChange={handleChangeComMulti}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button className="back_button " onClick={handleBack}>
                      Back
                    </Button>
                    <Button className="common_btn ms-3" type="submit">
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditUser;
