import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { ToastError, ToastSuccess } from "../../../../../Components/Toast";

function AssociationStoreCreate() {
  const { id, asid } = useParams();
  const Logdetails = useSelector((state) => state.login.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);
  const nav = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      GetStoreDetails(id);
    }
  }, [id]);

  const GetStoreDetails = async (id) => {
    let seData = {
      url: `/api/AssociationStore/GetAssociationStoreById?associationStoreId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        let sendData = res?.data?.payload;
        //    setEcDe(sendData);
        setValue("associationStoreId", id);
        setValue("storeName", sendData?.storeName);
        setValue("description", sendData?.description);
      }
    }
  };

  const onSubmit = async (data) => {
    let seData = {
      url: "/api/AssociationStore/CreateAssociationStore",
      body: {
        ...data,
        associationId: asid,
        createdBy: Logdetails?.userId,
      },
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200 && res?.data?.success) {
      reset();
      nav(-1);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data?.message);
    }
  };

  return (
    <section className="association_store_create">
      <div className="main_wrapper">
        <div className="title mb-3">
          {id ? "Edit" : "Create"} Association Store{" "}
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      {...register("storeName", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 200,
                          message: "Max 200 characters",
                        },
                      })}
                    ></input>
                    {errors.storeName && (
                      <span role="alert" className="error_text">
                        {errors.storeName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      Description
                      {/* <span className="red-color"> *</span> */}
                    </label>
                    <textarea
                      type="text"
                      rows={5}
                      className="form-control"
                      placeholder="Enter Description"
                      {...register("description", {
                        // required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 1000,
                          message: "Max 1000 characters",
                        },
                      })}
                    ></textarea>
                    {errors.description && (
                      <span role="alert" className="error_text">
                        {errors.description.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <div className="form-group">
                    <Button
                      className="common_btn"
                      type="submit"
                      disabled={openCircle}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Create"
                      )}
                    </Button>
                    <Button
                      onClick={() => nav(-1)}
                      className="back_button ms-2"
                    >
                      {" "}
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AssociationStoreCreate;
