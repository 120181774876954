import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { VscEye } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi.jsx";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import { PostCallApi } from "../../../Action/Action.jsx";
import { ToastError } from "../../Components/Toast/index.jsx";
import { useForm } from "react-hook-form";
import TableHeader from "../../../TableHeader.json";
import ImgPopUp from "../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../Utils/Config.jsx";
const NotFound = "/assets/images/Image_not_available.jpg";

const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function BoothApproval() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);

  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [status, setStatus] = useState();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    document.title = "FIG Events | Booth Approval";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const GetBoothRegisterList = async (data) => {
    setOpenCircle(true);
    setDataOfTable1([]);
    let res = await PostCallApi({
      url: `/api/ExhibitorBoothAllocation/GetAllConferenceBookingDataByUserId`,
      body: {
        ...data,
        conferenceId: data?.conferenceId ? data?.conferenceId : null,
        userId: logindetail?.userId,
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);

    if (res?.status === 200 && res?.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          ...c,
          id: i + 1,
          groupUserList:
            c?.boothComapanyDetails?.length > 0
              ? c?.boothComapanyDetails?.map((x, index) => {
                  return {
                    ...x,
                    id: index + 1,
                    companyLogo: x?.companyLogo ? (
                      <ImgPopUp
                        img={`${Config.API_HOST_URL_live}${x?.companyLogo}`}
                        addClass={"h_fix"}
                        hide_icon={true}
                      />
                    ) : (
                      <ImgPopUp
                        img={NotFound}
                        addClass={"h_fix"}
                        hide_icon={true}
                      />
                    ),
                  };
                })
              : "",
          Action: (
            <>
              {c?.status === "Rejected" ? (
                ""
              ) : (
                <Link
                  className="btn_edit d-flex align-items-center"
                  to={`${c?.conferenceId}/${c?.userId}/${c?.boothBookingPaymentId}`}
                >
                  <VscEye />
                </Link>
              )}
            </>
          ),
        };
      });
      setDataOfTable1(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
  };

  const statusOption = [
    { value: "Approve", label: "Approve" },
    { value: "InProgress", label: "InProgress" },
    { value: "Rejected", label: "Rejected" },
  ];
  const handleStatus = (e) => {
    setStatus(e);
    setValue("boothStatus", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setStatus();
    setDataOfTable1([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetBoothRegisterList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Conference</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={conList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) => handleConfereceSelect(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">User Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter User Name"
                              {...register("userName", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.userName && (
                              <span role="alert" className="error_text">
                                {errors.userName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Email"
                              {...register("email", {
                                // pattern: {
                                //   value: /\S+@\S+\.\S+/,
                                //   message: "Please Enter Valid Email",
                                // },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.email && (
                              <span role="alert" className="error_text">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Status</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={statusOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Status"
                              filterOption={customFilter}
                              value={statusOption?.filter(
                                (obj) => obj.value === status
                              )}
                              onChange={(e) => handleStatus(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    headCells={TableHeader?.RegisterExhibitorListHeader}
                    title="Register Exhibitor List"
                    action={true}
                    actionFirst={true}
                    ListData={dataOfTable1}
                    dropdown={false}
                    btnshow={false}
                    filterPage={filterPage}
                    showUserListInTable={true}
                    innerProduct={true}
                    fileName={"Register Exhibitor List"}
                    exportDataList={true}
                    subHeadCells={[
                      {
                        id: "id",
                        label: "#",
                      },
                      {
                        id: "companyLogo",
                        label: "Logo",
                        exportTab: false,
                      },
                      {
                        id: "companyName",
                        label: "Company Name",
                      },
                      {
                        id: "boothNumber",
                        label: "Booth No",
                      },
                      {
                        id: "boothStatus",
                        label: "Booth Status",
                      },
                      {
                        id: "companyPhone",
                        label: "Phone",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default BoothApproval;
