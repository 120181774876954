import React, { useEffect, useMemo, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import "./index.scss";
import { useForm } from "react-hook-form";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  // LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useResponsiveFontSize from "../../../SuperAdmin/MemberManagement/Association/UserAssociation/useResponsiveFontSize";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import { IndustryDrop } from "../../../Components/AssociationMember";
const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};
function SubscriptionBuy() {
  const { id } = useParams();
  const [clientPublicKey, setClientPublicKey] = useState();
  const [amt, setAmt] = useState();
  const [subData, setSubData] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const LogDetails = useSelector((state) => state.login.LoginDetails);

  let nav = useNavigate();

  useEffect(() => {
    getSubData(id);
  }, []);
  const getSubData = async (id) => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/Subscription/GetSubscriptionBySubscriptionId?subscriptionId=${id}`,
    });
    if (res?.status === 200 && res?.data?.success) {
      let allSubData = res?.data?.payload;
      let amount = allSubData?.isDiscountedSubscription
        ? allSubData?.price
        : allSubData?.totalSubscriptionPrice;
      setSubData(allSubData);
      setAmt(amount);
      if (parseFloat(amount) > 0) {
        PCSec();
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const PCSec = async () => {
    let seData = {
      url: `/api/ExhibitorSubscription/GetPublicKeyOfExhibitorSubscription`,
    };
    const res = await GetCallApi(seData);

    if (res?.status === 200 && res?.data.success) {
      const clientPub = res?.data?.payload;
      setClientPublicKey(clientPub);
      setOpenCircle(false);
    } else {
      ToastError(res?.data.message);
      setOpenCircle(false);
    }
  };
  const stripePromise = loadStripe(clientPublicKey);
  const CheckoutForm = ({ subData, amt }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [dis, setDis] = useState(false);
    const [companyId, setCompanyId] = useState();

    const options = useOptions();
    const [derror, setDerror] = useState();

    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 500);
    }, []);
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (parseFloat(amt) > 0) {
          if (!stripe || !elements) {
            return;
          }

          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: data?.cardName,
              address: { postal_code: data?.zip ? data?.zip : null },
            },
            // card: elements.getElement(CardNumberElement),
          });

          if (error) {
            // handle payment error
          } else {
            const sourceId = paymentMethod.id;
            await SubscriptionPaymentCall(data, sourceId);
          }
        } else {
          await SubscriptionPaymentCall(data);
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };

    const SubscriptionPaymentCall = async (data, sourceId) => {
      let paymentData = {
        userId: LogDetails?.userId,
        email: LogDetails?.email,
        amount: parseFloat(amt),
        subscriptionId: parseInt(id),
        paymentMode: "Online",
        companyId: data?.CompanyId,
        PaymentMethodId: sourceId ? sourceId : "",
      };
      let sveCC = {
        url: `/api/ExhibitorSubscription/ExhibitorSubscriptionProcessPayment`,
        body: paymentData,
      };
      setDis(true);
      let res = await PostCallApi(sveCC);
      if (res?.status === 200 && res?.data.isPaid) {
        let newSend = {
          url: `/api/ExhibitorSubscription/CreateExhibitorSubscription`,
          body: {
            userId: LogDetails?.userId,
            subscriptionId: parseInt(id),
            SubscriptionPrice: parseFloat(amt),
            remindBeforeDays: data?.remindBeforeDays
              ? parseInt(data?.remindBeforeDays)
              : 1,
            companyId: data?.CompanyId,
            paymentId: res?.data?.paymentId,
          },
        };
        let resNew = await PostCallApi(newSend);
        if (resNew?.status === 200 && resNew?.data.success) {
          setDis(false);
          nav(`/exhibitor-subscription-list/${res?.data?.paymentId}`, {
            replace: true,
          });
          ToastSuccess(res?.data.message);
        } else {
          ToastError(res?.data.message);
          setDis(false);
        }
      } else {
        setDis(false);

        ToastError(res?.data.message);
      }
    };

    const handleCompany = (e) => {
      setValue("CompanyId", e);
      setCompanyId(e);
    };

    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="conference_details border-bottom-0 mb-4">
              <ul>
                <li>
                  {subData?.subscriptionTitle}
                  <span>
                    {amt?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </li>{" "}
              </ul>
              <div className="subtotal">
                <p>
                  Total{" "}
                  <span>
                    {amt
                      ? amt?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : "0.00"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <IndustryDrop
              isMulti={false}
              selectedIndustry={[companyId]}
              handleChangeIndustry={(e) => handleCompany(e?.value)}
            />
            {/* <div className="form-group">
              <label className="form-label">Company</label> */}
            {/* <Select
                isMulti={false}
                name="colors"
                options={companyList}
                className="company-multi-select"
                classNamePrefix="select"
                placeholder="Select Company"
                filterOption={customFilter}
                value={companyList?.filter((obj) => companyId === obj.value)}
                onChange={(e) => handleCompany(e?.value)}
              /> */}
            <input
              type="text"
              className="form-select d-none"
              disabled
              {...register("CompanyId", {
                required: "This field is required",
              })}
            ></input>
            {errors.CompanyId && (
              <span role="alert" className="error_text">
                {errors.CompanyId.message}
              </span>
            )}
            {/* </div> */}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">
                Subscription Expire Reminder Before Days
              </label>{" "}
              <input
                className="form-control"
                type="number"
                min={1}
                max={30}
                placeholder="Enter Reminder Before Days"
                {...register("remindBeforeDays", {
                  required: "This field is required",
                  // onChange: (e) => localStorage.setItem("reminderEx", e),
                })}
              />
              {errors.remindBeforeDays && (
                <span role="alert" className="error_text">
                  {errors.remindBeforeDays.message}
                </span>
              )}
            </div>
          </div>
          {parseFloat(amt) > 0 ? (
            <>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Name On Card</label>{" "}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name on card"
                    {...register("cardName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.cardName && (
                    <span role="alert" className="error_text">
                      {errors.cardName.message}
                    </span>
                  )}
                </div>
              </div>
              <div
                className="col-md-6 "
                // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
              >
                <div className="form-group">
                  <label className="form-label">Card Number</label>
                  <CardNumberElement
                    className="form-control"
                    options={options}
                  />
                </div>
                {/* <CardElement options={CARD_OPTIONS} /> */}
              </div>
              <div className="col-md-6 ">
                <div className="form-group">
                  <label className="form-label"> Expiration date</label>
                  <CardExpiryElement
                    className="form-control"
                    options={options}
                  />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-group">
                  <label className="form-label">CVC</label>
                  <CardCvcElement className="form-control" options={options} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Zip Code</label>{" "}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Zip Code"
                    {...register("zip", {
                      // required: "This field is required",
                      maxLength: {
                        value: 6,
                        message: "Max 6 characters",
                      },
                    })}
                  />
                  {errors.zip && (
                    <span role="alert" className="error_text">
                      {errors.zip.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6"></div>
            </>
          ) : (
            ""
          )}
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        {parseFloat(amt) > 0 ? (
          <Button
            className="common_btn"
            type="submit"
            disabled={!stripe || dis}
          >
            {dis ? (
              <CircularProgress
                color="inherit"
                sx={{
                  color: "#fff",
                  marginLeft: "0.5rem",
                  height: "23px !important",
                  width: "23px !important",
                }}
              />
            ) : (
              "Complete Payment"
            )}
          </Button>
        ) : (
          <Button className="common_btn" type="submit" disabled={dis}>
            {dis ? (
              <CircularProgress
                color="inherit"
                sx={{
                  color: "#fff",
                  marginLeft: "0.5rem",
                  height: "23px !important",
                  width: "23px !important",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        )}
        <Button className="back_button ms-3" onClick={() => nav(-1)}>
          Cancel
        </Button>
      </form>
    );
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="subscription_page">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="title attend-title mb-3">Payment Details</div>
              <Elements stripe={stripePromise}>
                <CheckoutForm subData={subData} amt={amt} />
              </Elements>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscriptionBuy;
