export const validateImage = (file) => {
  const validVideoAudioExtensions = /\.(jpg|jpeg|png|webp)$/i;

  return validVideoAudioExtensions.test(file);
};

// const handleExcelFile = (e) => {
//   let file = e.target?.files[0];
//   if (file) {
//     if (file.name.match(/\.(xlsx)$/i)) {
//       setValue("DisplayImageUrl", file.name);
//       setValue("AttendeeList", file);
//     } else {
//       Swal.fire({
//         position: "center",
//         width: "50%",
//         title: "Please Select Only .xlsx File",
//         confirmButtonText: "Close",
//         confirmButtonColor: "#E64942",
//       });
//     }
//   }
// };

export function isValidRange(newStart, newEnd, existingRanges) {
  for (const { date, endDate } of existingRanges) {
    // Check for overlap
    let sd = new Date(date);
    let ed = new Date(endDate);
    let cns = new Date(newStart).toLocaleDateString("en-us", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    cns = new Date(cns);
    let cne = new Date(newEnd).toLocaleDateString("en-us", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    cne = new Date(cne);
    if (
      (cns >= sd && cns <= ed) || // New start is within an existing range
      (cne >= sd && cne <= ed) || // New end is within an existing range
      (cns < ed && cne > sd) // New range overlaps with existing range
    ) {
      return false; // Overlap found
    }
  }
  return true; // No overlap
}

export function isPositiveInteger(num) {
  return num > 0;
}
