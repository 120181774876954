import React, { Suspense, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import NewLIstTable from "../../Components/NewLIstTable";
import Select from "react-select";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import TableHeader from "../../../TableHeader.json";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { ToastError, ToastSuccess } from "../../Components/Toast";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function SessionAttend() {
  const [conferenceList, setConferenceList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openCircleBTn, setOpenCircleBTn] = useState(false);
  const [confId, setConfId] = useState();
  const [sessionId, setSessionId] = useState();

  const [prdAddAr, setPrdAddAr] = useState([]);

  useEffect(() => {
    document.title = "FIG Events | Approve For Session Attend";
    GetAllEventsList();
    // eslint-disable-next-line
  }, []);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const GetAllSessionList = async (id) => {
    setSessionList([]);
    setDataOfTable1([]);
    setConfId(id);
    setValue("conferenceId", id);
    if (id) {
      let url = `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&userId=${logindetail?.userId}&isAlphanumericOrder=true`;
      let res = await GetCallApi({ url: url });

      if (res?.status === 200 && res?.data.success) {
        let comList = res?.data?.payload?.classLists?.map((x) => {
          return {
            ...x,
            value: x?.classId,
            label: x?.classTitle,
          };
        });
        setSessionList(comList);
      }
    }
  };
  const GetAllEventsList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logindetail?.userId}`,
    });

    if (res?.status === 200) {
      if (res?.data.success) {
        let comList = res?.data?.payload?.map((x) => {
          return {
            ...x,
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConferenceList(comList);
      }
    }
  };

  const GetRegistrationList = async (data) => {
    setDataOfTable1([]);

    setOpenCircleBTn(true);
    let res = await GetCallApi({
      url: `/api/SessionAttendance/GetAllAttendeesAndKeySpeakerList?confernceId=${
        data?.conferenceId ? data?.conferenceId : ""
      }&sessionId=${data?.sessionOrEventId ? data?.sessionOrEventId : ""}`,
    });
    setOpenCircleBTn(false);

    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          ...c,
          id: i + 1,
          Action: (
            <>
              <label className=" personal_detail_label">
                <input
                  type="checkbox"
                  className="login_check"
                  name="Remember Me"
                  checked={c?.status}
                  // disabled={c?.status}
                  onChange={(e) =>
                    handleAddProduct(e?.target?.checked, c?.userId)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </>
          ),
        };
      });
      let ar = comList?.filter((x) => x.status === true)?.map((x) => x.userId);
      setPrdAddAr(ar);
      setDataOfTable1(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleAddProduct = (val, userId) => {
    if (val) {
      setPrdAddAr((oldArray) => [...oldArray, userId]);
    } else {
      setPrdAddAr((oldArray) => oldArray?.filter((data) => data !== userId));
    }
  };

  const handleSelectAll = (val, list) => {
    if (val) {
      setPrdAddAr(list?.map((x) => x.userId));
    } else {
      setPrdAddAr([]);
    }
  };

  useEffect(() => {
    if (prdAddAr?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [prdAddAr]);

  const changetbData = () => {
    let newtb = dataOfTable1?.map((pd) => {
      return {
        ...pd,
        Action: (
          <label className=" personal_detail_label">
            <input
              type="checkbox"
              className="login_check"
              name="Remember Me"
              checked={
                prdAddAr?.filter((x) => x === pd?.userId)?.length > 0
                  ? true
                  : false
              }
              onChange={(e) => handleAddProduct(e?.target?.checked, pd?.userId)}
            />
            <span className="checkmark"></span>
          </label>
        ),
      };
    });
    setDataOfTable1(newtb);
  };

  const AddAttend = async (data) => {
    let userData = dataOfTable1
      .filter((x) => prdAddAr.includes(x.userId))
      ?.map((us) => {
        return {
          userId: us?.userId,
          typeOfUser: us?.roleName,
        };
      });
    let sendData = {
      ...data,
      userData: userData,
      status: "Attended",
      createdBy: logindetail?.userId,
      sessionEventType: "Session",
    };
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/SessionAttendance/CreateSessionAttendance`,
      body: sendData,
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res?.data.message);
      handleClearFilter();
    } else {
      ToastError(res?.data.message);
    }
  };

  const handleSession = (ssId) => {
    setSessionId(ssId);
    setValue("sessionOrEventId", ssId);
  };

  const handleClearFilter = () => {
    setConfId();
    setSessionId();
    setPrdAddAr([]);
    setSessionList([]);
    setDataOfTable1([]);
    reset();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>
                        <img
                          src={"/assets/icons/filterIcon.svg"}
                          alt="filter"
                        />
                        <span className="filter_by">Filter By</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              Select Conference
                            </label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={conferenceList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conferenceList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) => GetAllSessionList(e?.value)}
                            />
                            <select
                              className="form-select d-none"
                              // value={confId}
                              // onChange={(e) =>
                              //   handleChangeValue(ssId, e.target.value)
                              // }
                              {...register("conferenceId", {
                                required: "This Field Is Requierd",
                                onChange: (e) =>
                                  GetAllSessionList(e.target.value),
                              })}
                              style={{ minWidth: "250px" }}
                            >
                              <option value="">Select Conference</option>
                              {conferenceList &&
                                conferenceList?.map((con, i) => (
                                  <option value={con?.conferenceId} key={i}>
                                    {con?.conferenceName}
                                  </option>
                                ))}
                            </select>
                            {errors.conferenceId && (
                              <span role="alert" className="error_text">
                                {errors.conferenceId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Select Session</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={sessionList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Session"
                              filterOption={customFilter}
                              value={sessionList?.filter(
                                (obj) => obj.value === sessionId
                              )}
                              onChange={(e) => handleSession(e?.value)}
                            />
                            <select
                              className="form-select d-none"
                              // value={ssId}
                              // onChange={(e) => handleChangeValue(e.target.value, confId)}
                              {...register("sessionOrEventId", {
                                required: "This Field Is Requierd",
                                onChange: (e) => handleSession(e.target.value),
                              })}
                              style={{ minWidth: "250px" }}
                            >
                              <option value="">Select Session</option>
                              {sessionList &&
                                sessionList?.map((con, i) => (
                                  <option value={con?.classId} key={i}>
                                    {con?.classTitle}
                                  </option>
                                ))}
                            </select>
                            {errors.sessionOrEventId && (
                              <span role="alert" className="error_text">
                                {errors.sessionOrEventId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              onClick={handleSubmit(GetRegistrationList)}
                              disabled={openCircleBTn ? true : false}
                            >
                              {openCircleBTn ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="col-md-12">
                  <div className="accordian_card">
                    <NewLIstTable
                      addressLink={`/speaker-create`}
                      headCells={TableHeader?.sessionAttendHeader}
                      title="Session Attend"
                      action={true}
                      actionFirst={true}
                      ListData={dataOfTable1}
                      dropdown={false}
                      btnshow={false}
                      selectAllBtn={true}
                      selectingAll={handleSelectAll}
                      AddStudents={() =>
                        AddAttend({
                          sessionOrEventId: getValues("sessionOrEventId"),
                          conferenceId: getValues("conferenceId"),
                        })
                      }
                      prdAddAr={prdAddAr}
                      checkBtnName={"Save"}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SessionAttend;
