import React from "react";
import WebMenu from "../Shared/WebMenu";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function PublicRoute() {
  // function PublicRoute({ children }) {
  return (
    <>
      <WebMenu />
      <Outlet />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default PublicRoute;
