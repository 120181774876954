
const blogData = [
  {
    id: "creating-nutritious-and-fun-school-lunches",
    title: "Creating Nutritious and Fun School Lunches",
    sortdesc: "As an event planner for school nutrition programs, one of our primary goals is to ensure that students receive meals that are both nutritious and enjoyable.",
    date: "September 26, 2022",
    img: "/assets/images/product-img.jpg",
    alt: "Creating Nutritious and Fun School Lunches",
    cap: "Creating Nutritious and Fun School Lunches",
    BlogPageData: [
      {
        topicPoints: [
          {
            title: "Introduction",
            desc: "As an event planner for school nutrition programs, one of our primary goals is to ensure that students receive meals that are both nutritious and enjoyable. Balancing health and taste can be challenging, but with some creativity, we can make school lunches exciting and beneficial for growing bodies.",
          },
          {
            title: "The Importance of Nutritious School Lunches:",
            desc: "Children require a balanced diet to support their growth, learning, and overall health. Nutritious school lunches can help improve concentration, boost energy levels, and promote better academic performance. It's essential to include a variety of food groups to provide the necessary vitamins and minerals.",
          }
        ],
        topicPoints1: [
          {
            title: "Tips for Creating Nutritious and Fun School Lunches:",
            points: [
              {
                point: "1. Colorful Plates:",
                pointsdes: "Incorporate a rainbow of fruits and vegetables. Not only are they packed with essential nutrients, but they also make the meal visually appealing. Think carrots, bell peppers, strawberries, and blueberries."
              },
              {
                point: "2. Interactive Meals:",
                pointsdes: "Create DIY lunch kits where children can assemble their own meals. For example, a taco bar with whole grain tortillas, lean protein, cheese, and various veggies allows kids to customize their lunch, making it more engaging."
              },
              {
                point: "3. Healthy Swaps:",
                pointsdes: "Replace less nutritious options with healthier alternatives. Use whole grain bread instead of white bread, swap sugary snacks with fresh fruit or yogurt, and opt for baked items over fried ones."
              },
              {
                point: "4. Involve the Kids:",
                pointsdes: "Get students involved in the menu planning process. Conduct surveys or hold focus groups to understand their preferences. When children have a say in their meals, they're more likely to eat and enjoy them."
              },
              {
                point: "Conclusion",
                pointsdes: "By incorporating these tips, we can create school lunches that are not only nutritious but also enjoyable for students. Remember, the key is to make healthy food fun and appealing, so kids look forward to their meals every day."
              }
            ]
          }
        ]
      }
    ]
  }
]
export default blogData