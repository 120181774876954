// src/features/dataSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDataLEA,
  fetchDataAgency,
  fetchDataIndustry,
  fetchDataAssociation,
} from "../Action/AssociationMember"; // Import your thunks

const initialState = {
  LEA: { data: null, status: "idle", error: null, loading: false },
  Agency: { data: null, status: "idle", error: null, loading: false },
  Industry: { data: null, status: "idle", error: null, loading: false },
  Association: { data: null, status: "idle", error: null, loading: false },
};

const associationMemberSlice = createSlice({
  name: "AssociationMember",
  initialState,
  reducers: {
    resetAssociationMember: () => initialState,
  },
  extraReducers: (builder) => {
    // Handle fetchDataLEA
    builder
      .addCase(fetchDataLEA.pending, (state) => {
        state.LEA.loading = true;
        state.LEA.status = "loading";
      })
      .addCase(fetchDataLEA.fulfilled, (state, action) => {
        state.LEA.loading = false;
        state.LEA.status = "succeeded";
        state.LEA.data = action.payload;
        state.LEA.error = null;
      })
      .addCase(fetchDataLEA.rejected, (state, action) => {
        state.LEA.loading = false;
        state.LEA.status = "failed";
        state.LEA.error = action.error.message;
      })
      // Handle fetchDataAgency
      .addCase(fetchDataAgency.pending, (state) => {
        state.Agency.loading = true;
        state.Agency.status = "loading";
      })
      .addCase(fetchDataAgency.fulfilled, (state, action) => {
        state.Agency.loading = false;
        state.Agency.status = "succeeded";
        state.Agency.data = action.payload;
        state.Agency.error = null;
      })
      .addCase(fetchDataAgency.rejected, (state, action) => {
        state.Agency.loading = false;
        state.Agency.status = "failed";
        state.Agency.error = action.error.message;
      })
      // Handle fetchDataIndustry
      .addCase(fetchDataIndustry.pending, (state) => {
        state.Industry.loading = true;
        state.Industry.status = "loading";
      })
      .addCase(fetchDataIndustry.fulfilled, (state, action) => {
        state.Industry.loading = false;
        state.Industry.status = "succeeded";
        state.Industry.data = action.payload;
        state.Industry.error = null;
      })
      .addCase(fetchDataIndustry.rejected, (state, action) => {
        state.Industry.loading = false;
        state.Industry.status = "failed";
        state.Industry.error = action.error.message;
      })
      // Handle fetchDataAssociation
      .addCase(fetchDataAssociation.pending, (state) => {
        state.Association.loading = true;
        state.Association.status = "loading";
      })
      .addCase(fetchDataAssociation.fulfilled, (state, action) => {
        state.Association.loading = false;
        state.Association.status = "succeeded";
        state.Association.data = action.payload;
        state.Association.error = null;
      })
      .addCase(fetchDataAssociation.rejected, (state, action) => {
        state.Association.loading = false;
        state.Association.status = "failed";
        state.Association.error = action.error.message;
      });
  },
});

export const selectLEA = (state) => state.AssociationMember.LEA;
export const selectStateAgency = (state) => state.AssociationMember.Agency;
export const selectIndustry = (state) => state.AssociationMember.Industry;
export const selectAssociation = (state) => state.AssociationMember.Association;
export const { resetAssociationMember } = associationMemberSlice.actions;
export default associationMemberSlice.reducer;
