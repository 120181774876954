import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <h3>{value}</h3>
      <h5>{type}</h5>
    </div>
  );
};

export default DateTimeDisplay;
