import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CropModel from "../../../../Components/CropModel";
import "./index.scss";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";
import {
  SwalMessage,
  ToastError,
  ToastSuccess,
} from "../../../../Components/Toast";
import { isValidRange } from "../../../../Components/Validation";
import { VscEdit } from "react-icons/vsc";
import CropFix from "../../../../Components/CropModel/CropFix";
import {
  fetchDataAgency,
  fetchDataIndustry,
  fetchDataLEA,
} from "../../../../../Action/AssociationMember";
const NewLIstTable = lazy(() => import("../../../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsMembership = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "associationMembershipsTypesName",
    label: "Membership Type",
  },
  {
    id: "planStatus",
    label: "Plan Status",
  },
  {
    id: "isManually",
    label: "IsManually",
  },
  {
    id: "planType",
    label: "Plan Type",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",
  background: "#ffffff",
  maxHeight: "90%",
  overflowY: "auto",
};

const AssociationMembershipTable = (props) => {
  const { id } = useParams();
  const { pathname, aList, asMem, associationId, setAssociationId } = props;
  const [dataOfTable, setDataOfTable] = useState([]);
  const [dataOfEdit, setDataOfEdit] = useState();
  const [openPop, setOpenPop] = useState(false);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [dataOfAssociation, setDataOfAssociation] = useState();
  const [memType, setMemType] = useState();
  const [aId, setAId] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const PopupOpen = (x) => {
    setOpenPop(true);
    if (x) {
      setValue(`mId`, x?.associationMembershipsTypesId);
      setValue(`aId`, x?.associationId);
      setValue(`mstartDate`, x?.sd ? x?.sd?.split("T")[0] : "");
      setValue(`mendDate`, x?.ed ? x?.ed?.split("T")[0] : "");
      setDataOfEdit(x);
      setAId(x?.associationId);
      setMemType(x?.associationMembershipsTypesId);
      getAssoData(x?.associationId);
      setSelectedOption(x?.planType);
    }
  };

  const getAssoData = async (aId) => {
    let seData = {
      url: `/api/Association/GetAssociationByAssociationId?associationId=${aId}`,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      let useDe = res?.data.payload;
      setDataOfAssociation(useDe);
    }
  };

  const PopupClose = () => {
    setOpenPop(false);
    reset();
    setAId();
    setMemType();
    setDataOfEdit();
    setDataOfAssociation();
  };

  useEffect(() => {
    getMembershipList();
  }, []);

  const getMembershipList = async () => {
    let res = await GetCallApi({
      url: `/api/AssociationMembers/GetAssociationMembershipSubscriptionDetails?associationMemberId=${id}`,
    });
    if (res?.status === 200 && res?.data?.success) {
      let svData = res?.data?.payload?.map((x, i) => {
        return {
          ...x,
          id: i + 1,
          isManually: x?.isManually ? "True" : "",
          sd: x?.startDate,
          ed: x?.endDate,
          startDate: x?.startDate
            ? new Date(x?.startDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          endDate: x?.endDate
            ? new Date(x?.endDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          Action: (
            <Button
              sx={{
                minWidth: "max-content",
                backgroundColor: "#86c65a !important",
              }}
              onClick={() =>
                PopupOpen({
                  ...x,
                  sd: x?.startDate,
                  ed: x?.endDate,
                  id: i + 1,
                })
              }
              className="btn_edit "
            >
              <VscEdit />
            </Button>
          ),
        };
      });
      setDataOfTable(svData);
    } else {
      setDataOfTable([]);
    }
  };

  const HandleRequest = async (data) => {
    let is_success = true;
    let sameList = dataOfTable
      ?.filter((x) => x?.id !== dataOfEdit?.id)
      ?.filter(
        (x) =>
          x?.associationId === dataOfEdit?.associationId ||
          x?.associationId === aId
      )
      ?.map((x) => {
        return { ...x, date: x?.sd, endDate: x?.ed };
      });
    let aName = dataOfTable?.find(
      (x) =>
        x?.associationId === dataOfEdit?.associationId ||
        x?.associationId === aId
    );
    if (sameList?.length > 0) {
      if (isValidRange(data?.mstartDate, data?.mendDate, sameList)) {
        // console.log("working");
      } else {
        // PopupClose();
        SwalMessage(
          `The ${aName?.associationName} association membership already exists with same date range`
        );
        // console.log("The new date range overlaps with existing ranges.");
        is_success = false;
      }
    }
    if (is_success) {
      setOpenCircle(true);

      let reqData = {
        ...dataOfEdit,
        startDate: data?.mstartDate,
        endDate: data?.mendDate,
        createdBy: LogDetails?.userId,
        associationId: aId,
        totalAmount:
          dataOfAssociation?.associationMembershipsTypes?.find(
            (obj) => memType === obj.associationMembershipsTypesId
          )?.cost ?? 0,
        associationMembershipsTypesId: memType,
        asscoiationMemberId: id,
        isManually: dataOfEdit ? dataOfEdit?.isManually : true,
        planType: selectedOption,
      };

      let seData = {
        url: `/api/AssociationMembers/UpdateManuallyAssociationMemberSubscription`,
        body: reqData,
      };

      let resp = await PostCallApi(seData);
      setOpenCircle(false);
      if (resp?.status === 200 && resp.data.success) {
        setOpenCircle(false);
        if (!dataOfEdit) {
          let clAPI = associationId?.find((x) => x === aId);
          if (!clAPI) {
            setAssociationId((old) => [...old, aId]);
          }
        }
        PopupClose();
        getMembershipList();
        ToastSuccess(resp.data.message);
      } else {
        setOpenCircle(false);
        ToastError(resp.data.message);
      }
    }
  };
  var dtToday = new Date().toISOString().split("T")[0];

  const handleAssociation = (e) => {
    setAId(e);
    setValue("aId", e);
    getAssoData(e);
  };
  const handleMembership = (e) => {
    setMemType(e);
    setValue("mId", e);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === selectedOption) {
      setSelectedOption();
    } else {
      setSelectedOption(value);
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  headCells={headCellsMembership}
                  title="Membership List"
                  action={asMem?.isEditAccess}
                  ListData={dataOfTable}
                  addBtn={asMem?.isEditAccess}
                  onAddBtnClick={() => PopupOpen()}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      {openPop && (
        <>
          <Modal
            sx={{ zIndex: 9 }}
            open={openPop}
            onClose={PopupClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <Backdrop
                open={openCircle}
                sx={{ color: "#fff", zIndex: () => 9999 }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="popup_delete_detail_box">
                <h6>Membership </h6>
                <form onSubmit={handleSubmit(HandleRequest)}>
                  <div className="popup_delete_detail">
                    <div className="form-group">
                      <label className="form-label">
                        Association
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={aList}
                        isClearable={true}
                        isDisabled={dataOfEdit ? true : false}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Association"
                        filterOption={customFilter}
                        value={aList?.filter((obj) => obj.value === aId)}
                        onChange={(e) => handleAssociation(e?.value)}
                      />
                      <input
                        type="text"
                        className="d-none"
                        {...register("aId", {
                          required: "This field is require",
                        })}
                      />
                      {errors?.aId && (
                        <span className="error_text">{errors.aId.message}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Membership Type
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={dataOfAssociation?.associationMembershipsTypes
                          ?.filter((x) => x?.membershipType === pathname)
                          ?.map((x) => {
                            return {
                              ...x,
                              label: x?.title,
                              value: x?.associationMembershipsTypesId,
                            };
                          })}
                        className="company-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Membership"
                        filterOption={customFilter}
                        value={dataOfAssociation?.associationMembershipsTypes
                          ?.filter((x) => x?.membershipType === pathname)
                          ?.map((x) => {
                            return {
                              ...x,
                              label: x?.title,
                              value: x?.associationMembershipsTypesId,
                            };
                          })
                          ?.filter((obj) => memType === obj.value)}
                        onChange={(e) => handleMembership(e?.value)}
                      />
                      <input
                        type="text"
                        className="d-none"
                        {...register("mId", {
                          required: "This field is require",
                        })}
                      />
                      {errors?.mId && (
                        <span className="error_text">{errors.mId.message}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Start Date
                        <span className="red-color"> *</span>
                      </label>

                      <input
                        type="date"
                        className="form-control"
                        max={dtToday}
                        {...register("mstartDate", {
                          required: "This field is require",
                          valueAsDate: true,
                        })}
                      />
                      {errors?.mstartDate && (
                        <span className="error_text">
                          {errors.mstartDate.message}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        End Date
                        <span className="red-color"> *</span>
                      </label>

                      <input
                        type="date"
                        className="form-control"
                        {...register("mendDate", {
                          required: "This field is require",
                          valueAsDate: true,
                          validate: (val) => {
                            if (watch("mstartDate") > val) {
                              return "Your end date cannot be earlier than the start date";
                            }
                          },
                        })}
                      />
                      {errors?.mendDate && (
                        <span className="error_text">
                          {errors.mendDate.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="Active"
                          checked={selectedOption === "Active"}
                          onChange={handleChange}
                        />
                        <span className="ms-2">Active</span>
                      </label>
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="Expired"
                          checked={selectedOption === "Expired"}
                          onChange={handleChange}
                        />
                        <span className="ms-2">Expired</span>
                      </label>
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="Non Renew"
                          checked={selectedOption === "Non Renew"}
                          onChange={handleChange}
                        />
                        <span className="ms-2">Non Renew</span>
                      </label>
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="Prospect"
                          checked={selectedOption === "Prospect"}
                          onChange={handleChange}
                        />
                        <span className="ms-2">Prospect</span>
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="popup_btn delete"
                      // onClick={HandleRequest}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={PopupClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

function AssociationMemberCreate() {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [openCircle, setOpenCircle] = React.useState(false);
  const [derror, setDerror] = useState();
  const [stateList, setStateList] = useState([]);
  const [aList, setAList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectAdmin, setSelectAdmin] = useState([]);
  const [associationId, setAssociationId] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [asMem, setAsMem] = useState();
  const [prCatId, setPrCatId] = useState();
  const [comType, setComType] = useState();
  const [companyList, setCompanyList] = useState();
  const [parentCompanyId, setParentCompanyId] = useState();
  let nav = useNavigate();
  let { pathname } = useLocation();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   // if (userList?.length > 0) {
  //   // if (stateList?.length > 0) {
  //   // if (aList?.length > 0) {
  //   if (id) {
  //     getAssociationMemberData(id);
  //   }
  //   // }
  //   // }
  //   // }
  //   // eslint-disable-next-line
  // }, [id]);

  useEffect(() => {
    GetStateList();
    GetAssociationList();
    // eslint-disable-next-line
  }, []);
  let pName = pathname?.split("/")[1];
  useEffect(() => {
    if (id) {
      getAssociationMemberData(id);
      GetUserList();
    } else {
      GetCompanyRegistrationList(
        pName === "state-agencies"
          ? "State Agency"
          : pName === "lea-members"
          ? "LEA"
          : pName === "industry-members"
          ? "Industry"
          : ""
      );
    }
    // eslint-disable-next-line
  }, [id]);

  const getAssociationMemberData = async (id) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersByAssociationMembersId?associationMembersId=${id}&userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let useDe = res?.data.payload;
      setAsMem(useDe);
      setValue("AssociationMembersId", id);
      setValue("associationName", res?.data?.payload?.association);
      setValue("AssociationId", useDe?.associationId);
      setAssociationId(useDe?.associationId);
      setValue("memberNumber", useDe?.memberNumber);
      setValue("Name", useDe?.name);
      setValue("Address", useDe?.address);
      setValue("Type", useDe?.type);
      setValue("TShirtSize", useDe?.tshirtSize);
      setValue("mealRequirements", useDe?.mealRequirements);
      setValue("Email", useDe?.email);
      setValue("Phone", useDe?.phone);
      setValue("DisplayImage", useDe?.profilePicture);
      setValue("DisplayImageUrl", useDe?.profilePicture);
      setValue("City", useDe?.city);
      setValue("State", useDe?.state);
      setStateValue(useDe?.state);
      setValue("Zip", useDe?.zip);
      setValue("Accreditations", useDe?.accreditations?.toString());
      setValue("ContactName", useDe?.contactName);
      setValue("CoverImage2", useDe?.coverImage);
      setValue("AlternateContactName", useDe?.alternateContactName);
      setValue("AlternateEmailId", useDe?.alternateEmailId);
      setValue("AlternatePhoneNumber", useDe?.alternatePhoneNumber);
      setValue("Website", useDe?.website);
      setValue("GLNNumber", useDe?.glnnumber);
      setValue("CompanyTitle", useDe?.title);

      handlePRCAt(useDe?.companyProductCategory);
      handleComType(useDe?.companyType);
      let pName = pathname?.split("/")[1];
      GetCompanyRegistrationList(
        pName === "state-agencies"
          ? "State Agency"
          : pName === "lea-members"
          ? "LEA"
          : pName === "industry-members"
          ? "Industry"
          : "",
        useDe
      );
      let useId = useDe?.associationMemberAdminList;
      if (useId?.length > 0) {
        setSelectAdmin(useId?.map((x) => x?.userId));
        setValue(
          "adminId",
          useId?.map((x) => x?.userId)
        );
      }
      // if (useId) {
      //   setValue("adminId", useId);
      // }
      setOpenCircle(false);
    }
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let EData = {
      ...data,
      AssociationMemberAdminList: selectAdmin?.length > 0 ? selectAdmin : [],
      profilePicture: data?.DisplayImage,
      CreatedBy: LogDetails?.userId,
      Type:
        pathname?.split("/")[1] === "state-agencies"
          ? "State Agency"
          : pathname?.split("/")[1] === "lea-members"
          ? "LEA"
          : "Industry",
    };

    let seData = {
      url: `/api/AssociationMembers/CreateAssociationMembers`,
      body: EData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let res = await PostCallApi(seData);

    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      if (pathname?.split("/")[1] === "state-agencies") {
        dispatch(fetchDataAgency(LogDetails?.userId));
      } else if (pathname?.split("/")[1] === "lea-members") {
        dispatch(fetchDataLEA(LogDetails?.userId));
      } else {
        dispatch(fetchDataIndustry(LogDetails?.userId));
      }
      reset();
      ToastSuccess(res?.data.message);
      nav(-1);
    } else {
      setDerror({ err: res?.data?.message });

      ToastError(res?.data.message);
    }
  };

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        setStateList(
          res?.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.stateName,
              value: x?.stateName,
            };
          })
        );
      }
    }
  };

  const GetAssociationList = async () => {
    let seData = {
      url: `/api/Association/GetAllAssociationList?userId=${LogDetails?.userId}`,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        setAList(
          res?.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.name,
              value: x?.associationId,
            };
          })
        );
      }
    }
  };

  const GetUserList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Association/GetAllAssociationMembersTypeAllocationListByAssociationMembersId?associationMembersId=${id}`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c) => {
          return {
            value: c?.userId,
            label: c?.userName,
          };
        });
        setUserList(comList);
      }
    }
  };

  const GetCompanyRegistrationList = async (type, com) => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${type}&userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload
        ?.filter((_) => id !== _?.associationMemberId)
        ?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            value: c?.associationMemberId,
            label: c?.name,
          };
        });

      setCompanyList(comList);
      if (com?.parentCompanyId) {
        setTimeout(() => {
          handleParentCompany(com?.parentCompanyId);
        }, 500);
      }
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("DisplayImage", url);
        setValue("DisplayImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );

  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end
  const fixSize = { fixwidth: "1920", fixheight: "240" };

  // crop code start
  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("CoverImage", url);
        setValue("CoverImage2", url?.name);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  const handleAssociation = (e) => {
    setAssociationId(Array.isArray(e) ? e?.map((x) => x?.value) : []);
    setValue("AssociationId", Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };
  const handleChangeAdmin = (e) => {
    setSelectAdmin(Array.isArray(e) ? e?.map((x) => x?.value) : []);
    setValue("adminId", Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleState = (e) => {
    setStateValue(e);
    setValue("State", e);
  };

  const CompanyProductCategoryOption = [
    { value: "Consulting", label: "Consulting" },
    { value: "Equipment", label: "Equipment" },
    { value: "Food", label: "Food" },
    { value: "Marketing", label: "Marketing" },
    { value: "Other", label: "Other" },
    { value: "Packaging", label: "Packaging" },
    { value: "Technology", label: "Technology" },
  ];
  const CompanyTypeOption = [
    { value: "Primary", label: "Primary" },
    { value: "Secondary", label: "Secondary" },
  ];

  const handlePRCAt = (e) => {
    setValue("CompanyProductCategory", e);
    setPrCatId(e);
  };
  const handleComType = (e) => {
    setValue("CompanyType", e);
    setComType(e);
  };
  const handleParentCompany = (e) => {
    setValue("ParentCompanyId", e);
    setParentCompanyId(e);
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <form onSubmit={handleSubmit(onSubmit)} className="form_box mt-3">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="title">
                      {id ? "Edit" : "Create"}{" "}
                      {id
                        ? pathname?.split("/")[1] === "state-agencies"
                          ? "State Agency"
                          : pathname?.split("/")[1] === "lea-members"
                          ? "LEA Member"
                          : "Industry Member"
                        : pathname === "/state-agencies/create"
                        ? "State Agency"
                        : pathname === "/lea-members/create"
                        ? "LEA Member"
                        : "Industry Member"}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Name
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        {...register("Name", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 250,
                            message: "Max 250 characters",
                          },
                        })}
                      />
                      {errors.Name && (
                        <span role="alert" className="error_text">
                          {errors.Name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Association
                        <span className="red-color"> *</span>
                      </label>
                      <span>
                        {LogDetails?.roleId === 1 ? (
                          <>
                            <Select
                              isMulti
                              name="colors"
                              options={aList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Association"
                              filterOption={customFilter}
                              value={aList?.filter((obj) =>
                                associationId?.includes(obj.value)
                              )}
                              onChange={handleAssociation}
                            />
                            <input
                              type="text"
                              className="form-select d-none"
                              {...register("AssociationId", {
                                required: "This field is required",
                              })}
                            ></input>
                          </>
                        ) : id ? (
                          <input
                            type="text"
                            className="form-select"
                            disabled={id ? true : false}
                            {...register("associationName", {
                              required: "This field is required",
                            })}
                          ></input>
                        ) : (
                          <>
                            <Select
                              isMulti
                              name="colors"
                              options={aList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select District/Organization"
                              filterOption={customFilter}
                              value={aList?.filter((obj) =>
                                associationId?.includes(obj.value)
                              )}
                              onChange={handleAssociation}
                            />
                            <input
                              type="text"
                              className="form-select d-none"
                              {...register("AssociationId", {
                                required: "This field is required",
                              })}
                            ></input>
                          </>
                        )}
                      </span>
                      {errors.AssociationId && (
                        <span role="alert" className="error_text">
                          {errors.AssociationId.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  {pName === "industry-members" && (
                    <>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Contact Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your contact name"
                            {...register("ContactName", {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                            })}
                          />
                          {errors.ContactName && (
                            <span role="alert" className="error_text">
                              {errors.ContactName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Category<span className="red-color"> *</span>
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={CompanyProductCategoryOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Category"
                            filterOption={customFilter}
                            value={CompanyProductCategoryOption?.filter(
                              (obj) => obj.value === prCatId
                            )}
                            onChange={(e) => handlePRCAt(e?.value)}
                          />
                          <select
                            className="form-select d-none"
                            {...register("CompanyProductCategory", {
                              required: "This field is required",
                              // onChange: (e) => setCategory(e.target.value),
                            })}
                          >
                            <option value="">Select Category</option>
                            <option value="Consulting">Consulting</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Food">Food</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Other">Other</option>
                            <option value="Packaging">Packaging</option>
                            <option value="Technology">Technology</option>
                          </select>
                          {errors.CompanyProductCategory && (
                            <span role="alert" className="error_text">
                              {errors.CompanyProductCategory.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Company Type<span className="red-color"> *</span>
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={CompanyTypeOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Company Type"
                            filterOption={customFilter}
                            value={CompanyTypeOption?.filter(
                              (obj) => obj.value === comType
                            )}
                            onChange={(e) => handleComType(e?.value)}
                          />
                          <select
                            className="form-select d-none"
                            {...register("CompanyType", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Category</option>
                            <option value="Primary">Primary</option>
                            <option value="Secondary">Secondary</option>
                          </select>
                          {errors.CompanyType && (
                            <span role="alert" className="error_text">
                              {errors.CompanyType.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {watch("CompanyType") === "Secondary" ? (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Parent Company
                              {/* <span className="red-color"> *</span> */}
                            </label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={companyList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Company Type"
                              filterOption={customFilter}
                              value={companyList?.filter(
                                (obj) => obj.value === parentCompanyId
                              )}
                              onChange={(e) => handleParentCompany(e?.value)}
                            />
                            {errors.CompanyType && (
                              <span role="alert" className="error_text">
                                {errors.CompanyType.message}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Member Number
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Member Number"
                        {...register("memberNumber", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9+-]+$/,
                            message: "Enter valid number",
                          },
                          maxLength: {
                            value: 100,
                            message: "Max 100 characters",
                          },
                        })}
                      />
                      {errors.memberNumber && (
                        <span role="alert" className="error_text">
                          {errors.memberNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Phone
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone"
                        {...register("Phone", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          pattern: {
                            value:
                              /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: "Enter valid phone number",
                          },
                          maxLength: {
                            value: 20,
                            message: "Max 20 characters",
                          },
                        })}
                      />
                      {errors.Phone && (
                        <span role="alert" className="error_text">
                          {errors.Phone.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Email
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        {...register("Email", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Please Enter Valid Email",
                          },
                          maxLength: {
                            value: 150,
                            message: "Max 150 characters",
                          },
                        })}
                      />
                      {errors.Email && (
                        <span role="alert" className="error_text">
                          {errors.Email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Address
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        {...register("Address", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 1000,
                            message: "Max 1000 characters",
                          },
                        })}
                      />
                      {errors.Address && (
                        <span role="alert" className="error_text">
                          {errors.Address.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        City
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        {...register("City", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 100,
                            message: "Max 100 characters",
                          },
                        })}
                      />
                      {errors.City && (
                        <span role="alert" className="error_text">
                          {errors.City.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        State
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={stateList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select State"
                        filterOption={customFilter}
                        value={stateList?.filter(
                          (obj) => obj.value === stateValue
                        )}
                        onChange={(e) => handleState(e?.value)}
                      />
                      <input
                        type="text"
                        className="form-select d-none"
                        placeholder="Enter State"
                        disabled
                        {...register("State", {
                          required: "This field is required",
                        })}
                      ></input>
                      {errors.State && (
                        <span role="alert" className="error_text">
                          {errors.State.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        {" "}
                        Zip
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Zip"
                        {...register("Zip", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 20,
                            message: "Max 20 characters",
                          },
                        })}
                      />
                      {errors.Zip && (
                        <span role="alert" className="error_text">
                          {errors.Zip.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {id ? (
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Admin
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="colors"
                          options={userList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Admin"
                          filterOption={customFilter}
                          value={userList?.filter((obj) =>
                            selectAdmin?.includes(obj.value)
                          )}
                          onChange={handleChangeAdmin}
                        />
                        <input
                          type="text"
                          className="form-select d-none"
                          {...register("adminId", {
                            required: "This field is required",
                          })}
                        ></input>
                        {errors.adminId && (
                          <span role="alert" className="error_text">
                            {errors.adminId.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Profile Picture
                        <span className="red-color"> *</span>
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`DisplayImageUrl`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropModel
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                        />
                      )}
                      {/* crop code end */}
                      {errors.DisplayImageUrl && (
                        <span role="alert" className="error_text">
                          {errors.DisplayImageUrl.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {pName === "industry-members" && (
                    <>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Cover Image
                            <span className="red-color"> *</span>
                          </label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              placeholder="Enter Cover Image"
                              {...register("CoverImage2", {
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              })}
                            />
                            <IconButton
                              component="label"
                              className="upload_btn m-0"
                            >
                              Choose File
                              <input
                                id="upload"
                                type="file"
                                accept="image/*"
                                className="form-control"
                                hidden
                                onChange={(e) => handleFile1(e)}
                              />
                            </IconButton>
                          </div>
                          {/* crop code start */}
                          {src1 && (
                            <CropFix
                              coverCropImage={true}
                              src={src1}
                              filename={filename1}
                              setCroppedImageUrl={setCroppedImageUrlCallback1}
                              openCropModal={openCropModal1}
                              setCropModal={setCropModal1}
                              fixSize={fixSize}
                            />
                          )}
                          {/* crop code end */}
                          {errors.CoverImage2 && (
                            <span role="alert" className="error_text">
                              {errors.CoverImage2.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Website</label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your website url "
                            {...register("Website", {})}
                          />
                          {errors.Website && (
                            <span role="alert" className="error_text">
                              {errors.Website.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Title</label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your title "
                            {...register("CompanyTitle", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 300,
                                message: "You can enter max 300 charactor",
                              },
                            })}
                          />
                          {errors.CompanyTitle && (
                            <span role="alert" className="error_text">
                              {errors.CompanyTitle.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            GLN Number
                            {/* {category === "Food" ? (
                    <span className="red-color"> *</span>
                  ) : (
                    ""
                  )} */}
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your GLN Number"
                            {...register("GLNNumber", {
                              maxLength: {
                                value: 13,
                                message: "Max 13 Numbers",
                              },
                              minLength: {
                                value: 13,
                                message: "Enter Minimum 13 Numbers",
                              },
                              // validate: (val) => {
                              //   if (
                              //     watch("CompanyProductCategory") === "Food" &&
                              //     val === ""
                              //   ) {
                              //     return "This field is required";
                              //   }
                              // },
                            })}
                          />
                          {errors.GLNNumber && (
                            <span role="alert" className="error_text">
                              {errors.GLNNumber.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Alternate contact name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your alternate contact name"
                            {...register("AlternateContactName", {})}
                          />
                          {errors.AlternateContactName && (
                            <span role="alert" className="error_text">
                              {errors.AlternateContactName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Alternate email ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter alternate email ID"
                            {...register("AlternateEmailId", {
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please Enter Valid Email",
                              },
                            })}
                          />
                          {errors.AlternateEmailId && (
                            <span role="alert" className="error_text">
                              {errors.AlternateEmailId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Alternate phone number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your alternate phone number"
                            {...register("AlternatePhoneNumber", {
                              // required: {
                              //   value: true,
                              //   message: "This field is required",
                              // },
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                              maxLength: {
                                value: 20,
                                message: "Max 20 characters",
                              },
                            })}
                          />
                          {errors.AlternatePhoneNumber && (
                            <span role="alert" className="error_text">
                              {errors.AlternatePhoneNumber.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Cancel
                      </Button>
                      <Button
                        className="common_btn ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                      {derror?.err && (
                        <span role="alert" className="d-block error_text">
                          {derror.err}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {id ? (
        <AssociationMembershipTable
          pathname={
            pathname?.split("/")[1] === "state-agencies"
              ? "State Agency"
              : pathname?.split("/")[1] === "lea-members"
              ? "LEA"
              : "Industry"
          }
          aList={aList}
          asMem={asMem}
          associationId={associationId}
          setAssociationId={setAssociationId}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AssociationMemberCreate;
