import axios from "axios";
import { Config } from "./Config";
import { store } from "../store";

const Axios = axios.create({
  baseURL: `${Config.API_HOST_URL_live}`,
});
Axios.interceptors.request.use(
  (config) => {
    const token = store.getState().login.LoginDetails.accessToken; // Retrieve token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers["Content-Type"] = `application/json`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
