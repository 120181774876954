import React, { Suspense, lazy, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import "./index.scss";

const BookByCompany = lazy(() => import("./BookByCompany"));
const FloorPlanBook = lazy(() => import("./FloorPlanBook"));
const PaymentProcess = lazy(() => import("./PaymentProcess"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ConferenceEchibitorRegister() {
  const [eventId, setEventId] = useState("");
  const [companyData, setCompanyData] = useState("");
  const { id } = useParams();
  // const LogDetails = useSelector((state) => state.login.LoginDetails);
  // let nav = useNavigate();
  useEffect(() => {
    document.title = "FIG Events | Register As Exhibitor";
    if (id) {
      setEventId(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const [activeStep, setActiveStep] = useState(1);
  // const [steps, setSteps] = useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return "";
      case 1:
        return (
          <FloorPlanBook
            eventId={eventId}
            companyData={companyData}
            setCompanyData={setCompanyData}
            handleNext={() => handleNext()}
            handlePrev={() => handlePrev()}
          />
        );
      case 2:
        return (
          <BookByCompany
            handleNext={() => handleNext()}
            eventId={eventId}
            companyData={companyData}
            setCompanyData={setCompanyData}
          />
        );
      case 3:
        return (
          <section className="register_user">
            <PaymentProcess eventId={eventId} companyData={companyData} />
          </section>
        );
      default:
        return "";
    }
  };

  return (
    <Suspense fallback={renderLoader()}>
      {/* <Navigation /> */}
      <section className="eventSetupProcss_dashboard py-0 mt-0">
        <Box sx={{ width: "100%" }}>
          {/* <div className={`main_wrapper ${activeStep === 2 ? "" : "mb-2"}`}>
            <div className="main_box m-auto">
              <div className="card">
                <div className="card-body">
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className="stepper_box_label"
                  >
                    {steps?.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div> */}
          {/* {activeStep === 2 ? "" : <hr />} */}
          {getStepContent(activeStep)}
        </Box>
      </section>
    </Suspense>
  );
}

export default ConferenceEchibitorRegister;
