import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Config } from "../../Utils/Config.jsx";
import { useDispatch } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ComapanyList, LoginUser } from "../../reducers/login";
import { useNavigate } from "react-router-dom";
import { LoginHeadMenuList } from "../../reducers/menu";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import logo from "../../assets/images/fig_events.png";
import {
  ToastError,
  ToastSuccess,
} from "../../Modules/Components/Toast/index.jsx";

var CryptoJS = require("crypto-js");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function Login() {
  const { id } = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [derror, setDerror] = useState();
  const [reT, setReT] = useState(true);
  const [openCircle, setOpenCircle] = useState(false);
  const [userData, setUserData] = useState();
  const [roleData, setRoleData] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  let nav = useNavigate();
  let dispatch = useDispatch();
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  useEffect(() => {
    document.title = "FIG Events | Login";
    if (id) {
      UpdateUser(id);
    }
  }, [id]);
  const UpdateUser = async (id) => {
    let seData = {
      url: `/api/UserMst/UserEmailConfirmStatus?userId=${id}`,
    };
    await GetCallApi(seData);
  };
  const nows = new Date();

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Login/Login`,

      body: data,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        let loginDeatil = {
          ...res?.data?.payload,
          authenticate: true,
          remember: data.remember,
        };

        setUserData(loginDeatil);

        loginDeatil = {
          ...loginDeatil,
        };
        LoginUserManage(loginDeatil);
        setOpenCircle(false);

        if (data.remember) {
          setWithExpiry(
            "expireDate",
            loginDeatil?.email,
            loginDeatil?.password,
            nows.getDate() + 7,
            nows.getMonth() + 1
          );
        }

        reset();

        setDerror({ Log: "" });
      } else {
        setOpenCircle(false);
        setDerror({ Log: res?.data?.message });
        ToastError(res?.data.message);
      }
    } else {
      setOpenCircle(false);
      ToastError(res?.data?.message);
      console.log(res);
    }
  };

  const ed = JSON?.parse(localStorage?.getItem("expireDate"));
  const now = new Date();
  useEffect(() => {
    if (ed) {
      if (reT) {
        var decrypted = CryptoJS.AES.decrypt(ed?.password, "my-secret-key@123");
        let today = now.getMonth() + 1;

        if (today < ed?.month) {
          let logDe = {
            email: ed?.email,
            password: decrypted?.toString(CryptoJS.enc.Utf8),
          };
          let url = `${Config.API_HOST_URL_live}/api/Login/Login`;
          axios.post(url, logDe).then((response) => {
            if (response?.data.success) {
              let loginDeatil = {
                ...response?.data?.payload,
                authenticate: true,
              };
              LoginUserManage(loginDeatil);

              setReT(false);
            }
          });
        } else if (today === ed?.month) {
          if (now.getDate() > ed.expiry) {
            if (localStorage.getItem("expireDate")) {
              localStorage.removeItem("expireDate");
            }
            dispatch(LoginUser({ authenticate: false }));
          } else {
            let logDe = {
              email: ed?.email,
              password: decrypted?.toString(CryptoJS.enc.Utf8),
            };
            let url = `${Config.API_HOST_URL_live}/api/Login/Login`;
            axios.post(url, logDe).then((response) => {
              if (response?.data.success) {
                let loginDeatil = {
                  ...response?.data?.payload,
                  authenticate: true,
                };
                LoginUserManage(loginDeatil);

                setReT(false);
              }
            });
          }
        }
      }
    } else {
      if (localStorage.getItem("expireDate")) {
        localStorage.removeItem("expireDate");
      }
      dispatch(LoginUser({ authenticate: false }));
    }
  }, [ed]);
  const LoginUserManage = (loginDeatil) => {
    dispatch(LoginUser(loginDeatil));
    getMenu(loginDeatil?.roleId, loginDeatil?.accessToken, loginDeatil);
    ToastSuccess("Login Successfully");
    if (loginDeatil?.remember) {
      setWithExpiry(
        "expireDate",
        loginDeatil?.email,
        loginDeatil?.password,
        nows.getDate() + 7,
        nows.getMonth() + 1,
        loginDeatil?.roleId,
        loginDeatil?.roleName
      );
    }
  };

  function setWithExpiry(key, e, p, expiration, mon, roleId, roleName) {
    var encrypted = CryptoJS.AES.encrypt(p, "my-secret-key@123").toString();
    const item = {
      email: e,
      password: encrypted,
      roleId: roleId,
      roleName: roleName,
      expiry: expiration > 30 ? 1 : expiration,
      month: expiration > 30 ? mon + 1 : mon,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  const getMenu = async (rId, token, log) => {
    setOpenCircle(true);

    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Menu/GetMenuHierarchical?roleId=${rId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        if (res?.status === 200) {
          if (res?.data.success) {
            setOpenCircle(true);

            dispatch(LoginHeadMenuList(res?.data.payload));
            let { status, data } = await axios.get(
              `${Config.API_HOST_URL_live}/api/AssociationMembers/GetAssociationMembersListbyType?type=Industry&userId=${log?.userId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (status === 200 && data?.success) {
              dispatch(ComapanyList(data?.payload));
            }
            let exEmail = sessionStorage.getItem("email");
            let call = exEmail === log?.email ? await checkREQCall(log) : false;
            if (call) {
              nav(`/messages/${call}`, {
                replace: true,
              });
            } else {
              setTimeout(function () {
                if (exEmail) {
                  nav(`/message/${log?.userId}`, {
                    replace: true,
                  });
                } else if (id && log?.roleName === "Exhibitor") {
                  nav(`/exhibitor`, {
                    replace: true,
                  });
                } else {
                  if (log?.roleName === "Exhibitor") {
                    nav("/exhibitor-dashboard", { replace: true });
                  } else {
                    nav(
                      res?.data.payload[0]?.url
                        ? res?.data.payload[0]?.url
                        : "/home",
                      { replace: true }
                    );
                  }
                }
              }, 500);
            }
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);

        console.log(e);
      });
  };
  const SaveRoleLogin = () => {
    let role = userData?.roleData?.filter(
      (x) => x.roleId === parseInt(roleData)
    )[0];
    LoginUserManage({ ...userData, ...role });
  };

  const checkREQCall = async (log) => {
    let exExhibitor = sessionStorage.getItem("newExhibitor");

    let stChat = {
      userIdOne: log?.userId,
      userIdSecond: exExhibitor,
      createdBy: log?.userId,
      isDeleted: false,
    };
    let response = await PostCallApi({
      url: `/api/Chat/CreateChat`,
      body: stChat,
    });

    if (response?.status === 200) {
      if (response?.data.success) {
        sessionStorage.removeItem("newExhibitor");
        sessionStorage.removeItem("email");
        return response?.data.payload?.chatId;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="login_page">
        <div className="row m-0">
          <div className="col-xl-6 col-lg-6 col-12 p-0 text-center left_main">
            <div className="inner_box left">
              <div className="content">
                <h1>Log In to Fig Conference Planner</h1>
                <p>
                  Welcome to FIG Conference Planner! As a member, you play a
                  crucial role in shaping the future of K12 Nutrition.
                </p>
              </div>
              <img
                src="/assets/icons/login_banner.svg"
                className="img-fluid"
                alt="login banner"
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-12 p-0 right_main">
            <div className="inner_box right">
              <img src={logo} className="img-fluid logo" alt="logo" />
              <form
                className="d-flex flex-column"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group">
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="w-100"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error_text">{errors.email.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <div className="password">
                    <TextField
                      label="Password"
                      variant="outlined"
                      name="current-password"
                      className="w-100"
                      type={passwordShown ? "text" : "password"}
                      {...register("password", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Must be 8 characters",
                        },
                        maxLength: { value: 15, message: "Max 15 characters" },
                        pattern: {
                          value: /^\S+$/,
                          message: "Space not allowed",
                        },
                      })}
                    />
                    <span className="eye" onClick={togglePasswordVisiblity}>
                      <img
                        src={
                          passwordShown
                            ? "/assets/icons/eye.svg"
                            : "/assets/icons/eye-off.svg"
                        }
                        alt="toggle visibility"
                      />
                    </span>
                  </div>
                  {errors.password && (
                    <span className="error_text">
                      {errors.password.message}
                    </span>
                  )}
                  {derror?.Log && (
                    <span className="error_text">{derror.Log}</span>
                  )}
                </div>
                <div className="links">
                  <FormControlLabel
                    control={<Checkbox {...register("remember")} />}
                    label="Keep me logged in"
                  />
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <button
                  disabled={openCircle}
                  type="submit"
                  className="submit_btn"
                >
                  Login
                </button>
                <h6>
                  Don’t have an account? <Link to="/register">REGISTER</Link>
                </h6>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Modal
        sx={{ zIndex: 9 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="district_toggle_img_Show"
      >
        <Box sx={style} className="delete_data_box">
          <form>
            <div className="modal-header">
              <h4>Select Role</h4>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Role </label>
                  <select
                    className="form-select"
                    onChange={(e) => setRoleData(e.target.value)}
                  >
                    <option value="">Select Role </option>
                    {userData?.roleData?.map((x) => (
                      <option value={x?.roleId} key={x?.roleId}>
                        {x?.roleName}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {roleData ? (
                <Button className="common_btn" onClick={SaveRoleLogin}>
                  Save
                </Button>
              ) : (
                ""
              )}

              <Button className="ms-2 back_button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default Login;
