import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function SubscriptionReceipt() {
  const [ATRC, setATRC] = useState();
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const { id } = useParams();
  const tableRef = useRef(null);
  let nav = useNavigate();
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  let Time = new Date();

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    if (id) {
      getStripeData();
    }
    // eslint-disable-next-line
  }, [id]);

  const getStripeData = async () => {
    if (id) {
      setOpen(true);
      let seData = {
        url: `/api/ExhibitorSubscription/ViewReceiptExhibitorSubscription?exhibitorSubscriptionPaymentId=${id}&userId=${LogDetails?.userId}&isResend=false`,
      };

      let res = await GetCallApi(seData);
      setOpen(false);
      if (res?.status === 200) {
        if (res?.data?.success) {
          const cData = res?.data?.payload;
          setATRC(cData);
        }
      }
    }
  };

  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav(-1);
      });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    // maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  let newtime = new Date(ATRC?.invoiceDate);
  newtime?.setDate(newtime?.getDate() + 30);
  newtime = newtime.toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return (
    <>
      <section className="subscriptionReceipt py-4">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <Button
                className="common_btn mb-3"
                onClick={() => setDownloadpdf(true)}
              >
                Download
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <section
                className="user-subscription-stripe-receipt"
                ref={tableRef}
                style={back}
              >
                <div className="invoice-section">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="logo">
                        <img
                          src={logo}
                          style={{
                            height: "60px",
                          }}
                          alt="Confrence Planner"
                          title="Confrence Planner"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="d-flex align-items-center gap-3 justify-content-end">
                        <h2>
                          Online/Stripe
                          {/* {ATRC?.paymentMode === "Online"
                            ? "Online/Stripe"
                            : "Invoice"} */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">Fig Events</h5>
                      <div className="Billto-wrap">
                        <p style={{ whiteSpace: "pre-line" }}>
                          5189 Stewart Street,
                          <br /> Milton,
                          <br />
                          Florida 32570,
                          <br />
                          United States
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 text-end">
                      <div className="invoice-details">
                        <ul>
                          {/* {ATRC?.paymentMode === "Online" ? ( */}
                          <li>
                            <span className="primarylight-color">
                              Payment Reference
                            </span>
                            <span
                              className="invoice-text"
                              style={{
                                maxWidth: "150px",
                                wordBreak: "break-all",
                              }}
                            >
                              {ATRC?.paymentReference}
                            </span>
                          </li>
                          {/* ) : (
                            ""
                          )} */}

                          {/* {ATRC?.paymentMode === "Online" ? ( */}
                          <>
                            <li>
                              <span className="primarylight-color">
                                Payment Date
                              </span>
                              <span className="invoice-text">
                                {ATRC?.subscriptionStartDate
                                  ? new Date(
                                      ATRC?.subscriptionStartDate
                                    ).toLocaleString("en-us", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  : ""}
                              </span>
                            </li>
                            <li>
                              <span className="primarylight-color">Status</span>
                              <span className="invoice-text">Paid</span>
                            </li>
                          </>
                          {/* ) : (
                            <>
                              <li>
                                <span className="primarylight-color">
                                  Invoice Date
                                </span>
                                <span className="invoice-text">
                                  {ATRC?.invoiceDate
                                    ? new Date(
                                        ATRC?.invoiceDate
                                      ).toLocaleString("en-us", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="primarylight-color">
                                  Due Date
                                </span>
                                <span className="invoice-text">{newtime}</span>
                              </li>
                            </>
                          )} */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6  mb-4">
                      <h5 className="text-uppercase">Billed To</h5>
                      <div className="Billto-wrap">
                        {/* <p>{ATRC?.companyName ? ATRC?.companyName : ""}</p> */}
                        <p>{ATRC?.exhibitorName ? ATRC?.exhibitorName : ""}</p>
                        <p>{ATRC?.shippingAddressType}</p>

                        <p style={{ whiteSpace: "pre-line" }}>
                          {ATRC?.exhibitorAddress ? ATRC?.exhibitorAddress : ""}
                        </p>
                        <p>{ATRC?.exhibitorEmail ?? ""}</p>
                      </div>
                    </div>
                  </div>
                  <div className="table-wrap mt-5">
                    <Table className="table-bordered Conference-table">
                      <TableHead className="thead-primary">
                        <StyledTableRow>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Item
                          </StyledTableCell>{" "}
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Company
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Duration
                          </StyledTableCell>{" "}
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Plan Status
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Line Total
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          {/* <StyledTableCell colSpan={4}>
                            Subscription Title: {ATRC?.subscriptionTitle}
                            <br />
                            {ATRC?.subscriptionValue} Leads
                          </StyledTableCell> */}
                          {/* <StyledTableCell>{ATRC?.duration}</StyledTableCell>
                          <StyledTableCell>{ATRC?.planStatus}</StyledTableCell>
                          <StyledTableCell>
                          {ATRC?.subscriptionPrice?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })} 
                          </StyledTableCell>*/}
                        </StyledTableRow>
                        {ATRC?.companySubscriptionDetails?.map((x, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell>
                              {x?.subscriptionTitle}
                              <br />
                              {x?.subscriptionValue} Leads
                            </StyledTableCell>
                            <StyledTableCell>{x?.companyName}</StyledTableCell>
                            <StyledTableCell>{x?.duration}</StyledTableCell>
                            <StyledTableCell>{x?.planStatus}</StyledTableCell>
                            <StyledTableCell>
                              {x?.subscriptionPrice?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        {ATRC?.discountValue ? (
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                textAlign: "right",
                                borderBottom: "1px solid #808080",
                              }}
                              colSpan={3}
                            >
                              Discount
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: "white",
                                borderBottom: "1px solid #808080",
                              }}
                            >
                              -
                              {ATRC?.discountValue?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          ""
                        )}
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              textAlign: "right",
                              borderBottom: "1px solid #808080",
                            }}
                            colSpan={4}
                          >
                            <b>Total</b>
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              borderBottom: "1px solid #808080",
                            }}
                          >
                            <b>
                              {ATRC?.subscriptionTotalPrice?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </b>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="table-wrap mt-3">
                    <h5>User Details</h5>
                    <Table className="table-bordered Conference-table">
                      <TableHead className="thead-primary">
                        <StyledTableRow>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            User Name
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Phone
                          </StyledTableCell>
                          <StyledTableCell
                            className="txt_st"
                            style={{
                              borderTop: "1px solid #808080",
                            }}
                          >
                            Price
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>
                            {ATRC?.exhibitorName ?? ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ATRC?.exhibitorEmail ?? ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ATRC?.exhibitorPhone ?? ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {ATRC?.subscriptionTotalPrice?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="row term-text mt-4">
                    <div className="col-md-12">
                      <h5>Benifits:</h5>
                      <div className="row all_details">
                        {ATRC?.benefits.split("\n")?.map((v, i) => {
                          return (
                            <div className="col-12" key={i}>
                              <img src={"/assets/icons/rightTick.svg"} alt="" />
                              <span className="ms-3">{v}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-lg-12 text-lg-end mt-3">
                      <h5 className="mt-lg-7">Becki Swan</h5>
                      <p>Co-founder, Fig Events</p>
                    </div>
                  </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscriptionReceipt;
