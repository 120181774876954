import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
// import Axios from "../../../Utils/AxiosConfig";
import CropModel from "../../Modules/Components/CropModel";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
// import { PostCallApi } from "../../../Action/Action";
import { CompanyData } from "../../reducers/menu";
import CropFix from "../../Modules/Components/CropModel/CropFix";
import { ToastSuccess } from "../../Modules/Components/Toast";
import { GetCallApi } from "../../Action/Action";
import { customFilter } from "../../Modules/Components/CustomFilterForReactSelect";
import Select from "react-select";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CompanyForm() {
  // const [category, setCategory] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [associationList, setAssociationList] = useState([]);
  const [associationId, setAssociationId] = useState([]);

  let dispatch = useDispatch();

  let nav = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setOpenCircle(true);
    ToastSuccess("Company is created");
    setTimeout(() => {
      dispatch(CompanyData(data));
      nav(-1);
      setOpenCircle(false);
    }, 2000);
  };

  useEffect(() => {
    getASList();
  }, []);
  const getASList = async () => {
    let res = await GetCallApi({
      url: `/api/Association/GetAllAssociationWithoutToken`,
    });
    if (res?.status === 200 && res?.data.success) {
      let sv = res?.data.payload?.map((x, i) => {
        return {
          ...x,
          id: i + 1,
          value: x?.associationId,
          label: x?.name,
        };
      });
      setAssociationList(sv);
    }
  };

  const fixSize = { fixwidth: "1920", fixheight: "240" };
  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("CoverImage", url);
        setValue("CoverImage1", url?.name);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end
  // crop code start
  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("profilePicture", url);
        setValue("CoverImage2", url?.name);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  const handleAssociation = (e) => {
    setAssociationId(Array.isArray(e) ? e?.map((x) => x?.value) : []);
    setValue("AssociationId", Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_company">
          <div className="main_wrapper">
            <div className="title">Create Company</div>
            {/* <div className="create_course"> */}
            <div className="card m-0">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          {...register("Name", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max 250 characters",
                            },
                          })}
                        />
                        {errors.Name && (
                          <span role="alert" className="error_text">
                            {errors.Name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Association
                          <span className="red-color"> *</span>
                        </label>
                        <span>
                          <Select
                            isMulti
                            name="colors"
                            options={associationList ?? []}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select District/Organization"
                            filterOption={customFilter}
                            value={
                              associationList?.filter((obj) =>
                                associationId?.includes(obj.value)
                              ) ?? []
                            }
                            onChange={handleAssociation}
                          />
                          <input
                            type="text"
                            className="form-select d-none"
                            {...register("AssociationId", {
                              required: "This field is required",
                            })}
                          ></input>
                        </span>
                        {errors.AssociationId && (
                          <span role="alert" className="error_text">
                            {errors.AssociationId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Contact Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your contact name"
                          {...register("ContactName", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        />
                        {errors.ContactName && (
                          <span role="alert" className="error_text">
                            {errors.ContactName.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Category<span className="red-color"> *</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("CompanyProductCategory", {
                            required: "This field is required",
                            // onChange: (e) => setCategory(e.target.value),
                          })}
                        >
                          <option value="">Select Category</option>
                          <option value="Food">Food</option>
                          <option value="Equipment">Equipment</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Technology">Technology</option>
                          <option value="Packaging">Packaging</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.CompanyProductCategory && (
                          <span role="alert" className="error_text">
                            {errors.CompanyProductCategory.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Member Number
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Member Number"
                          {...register("memberNumber", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9+-]+$/,
                              message: "Enter valid number",
                            },
                            maxLength: {
                              value: 100,
                              message: "Max 100 characters",
                            },
                          })}
                        />
                        {errors.memberNumber && (
                          <span role="alert" className="error_text">
                            {errors.memberNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Phone
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone"
                          {...register("Phone", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                            maxLength: {
                              value: 20,
                              message: "Max 20 characters",
                            },
                          })}
                        />
                        {errors.Phone && (
                          <span role="alert" className="error_text">
                            {errors.Phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("Email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                            maxLength: {
                              value: 150,
                              message: "Max 150 characters",
                            },
                          })}
                        />
                        {errors.Email && (
                          <span role="alert" className="error_text">
                            {errors.Email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Address
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          {...register("Address", {
                            required: "This field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 1000,
                              message: "Max 1000 characters",
                            },
                          })}
                        />
                        {errors.Address && (
                          <span role="alert" className="error_text">
                            {errors.Address.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          City
                          <span className="red-color"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your city"
                          {...register("City", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        />
                        {errors.City && (
                          <span role="alert" className="error_text">
                            {errors.City.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          State
                          <span className="red-color"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your state"
                          {...register("State", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        />
                        {errors.State && (
                          <span role="alert" className="error_text">
                            {errors.State.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Zip
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your zip "
                          {...register("Zip", {
                            required: "This field is required",
                            maxLength: {
                              value: 6,
                              message: "Max 6 Number",
                            },
                          })}
                        />
                        {errors.Zip && (
                          <span role="alert" className="error_text">
                            {errors.Zip.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Profile Photo
                          <span className="red-color"> *</span>
                        </label>
                        <div className="d-flex upload_btn_sec">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            placeholder=" Profile Photo"
                            {...register("CoverImage2", {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                            })}
                          />
                          <IconButton
                            component="label"
                            className="upload_btn m-0"
                          >
                            Choose File
                            <input
                              id="upload"
                              type="file"
                              accept="image/*"
                              className="form-control"
                              hidden
                              onChange={(e) => handleFile1(e)}
                            />
                          </IconButton>
                        </div>
                        {/* crop code start */}
                        {src1 && (
                          <CropModel
                            src={src1}
                            filename={filename1}
                            setCroppedImageUrl={setCroppedImageUrlCallback1}
                            openCropModal={openCropModal1}
                            setCropModal={setCropModal1}
                          />
                        )}
                        {/* crop code end */}
                        {errors.CoverImage2 && (
                          <span role="alert" className="error_text">
                            {errors.CoverImage2.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Cover Image
                          <span className="red-color"> *</span>
                        </label>
                        <div className="d-flex upload_btn_sec">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            placeholder="Enter Cover Image"
                            {...register("CoverImage1", {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                              onChange: (e) => handleFile(e),
                            })}
                          />
                          <IconButton
                            component="label"
                            className="upload_btn m-0"
                          >
                            Choose File
                            <input
                              id="upload"
                              type="file"
                              accept="image/*"
                              className="form-control"
                              hidden
                              onChange={(e) => handleFile(e)}
                            />
                          </IconButton>
                        </div>
                        {/* crop code start */}
                        {src && (
                          <CropFix
                            coverCropImage={true}
                            src={src}
                            filename={filename}
                            setCroppedImageUrl={setCroppedImageUrlCallback}
                            openCropModal={openCropModal}
                            setCropModal={setCropModal}
                            fixSize={fixSize}
                          />
                        )}
                        {/* crop code end */}
                        {errors.CoverImage1 && (
                          <span role="alert" className="error_text">
                            {errors.CoverImage1.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Website</label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your website url "
                          {...register("Website", {})}
                        />
                        {errors.Website && (
                          <span role="alert" className="error_text">
                            {errors.Website.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Title</label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your title "
                          {...register("CompanyTitle")}
                        />
                        {errors.CompanyTitle && (
                          <span role="alert" className="error_text">
                            {errors.CompanyTitle.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          GLN Number
                          {/* {category === "Food" ? (
                            <span className="red-color"> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your GLN Number"
                          {...register("GLNNumber", {
                            maxLength: {
                              value: 13,
                              message: "Max 13 Numbers",
                            },
                            minLength: {
                              value: 13,
                              message: "Enter Minimum 13 Numbers",
                            },
                            // validate: (val) => {
                            //   if (category === "Food" && val === "") {
                            //     return "This field is required";
                            //   }
                            // },
                          })}
                        />
                        {errors.GLNNumber && (
                          <span role="alert" className="error_text">
                            {errors.GLNNumber.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Alternate contact name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your alternate contact name"
                          {...register("AlternateContactName", {})}
                        />
                        {errors.AlternateContactName && (
                          <span role="alert" className="error_text">
                            {errors.AlternateContactName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Alternate email ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter alternate email ID"
                          {...register("AlternateEmailId", {
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Please Enter Valid Email",
                            },
                          })}
                        />
                        {errors.AlternateEmailId && (
                          <span role="alert" className="error_text">
                            {errors.AlternateEmailId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Alternate phone number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your alternate phone number"
                          {...register("AlternatePhoneNumber", {})}
                        />
                        {errors.AlternatePhoneNumber && (
                          <span role="alert" className="error_text">
                            {errors.AlternatePhoneNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Back
                      </Button>
                      <Button type="submit" className="common_btn ms-3">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </Suspense>
    </>
  );
}

export default CompanyForm;
