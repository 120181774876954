import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { CircularProgress, IconButton, Button } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import CropModel from "../../../../Components/CropModel";
import { VscAdd } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../Components/Toast";
import { fetchDataAssociation } from "../../../../../Action/AssociationMember";

function AssociationCreate() {
  const { id } = useParams();
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectAdmin, setSelectAdmin] = useState([]);
  const [stateValue1, setStateValue1] = useState();
  const [stateValue2, setStateValue2] = useState();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "membership",
  });
  const handleChangeAdmin = (e) => {
    setSelectAdmin(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const dispatch = useDispatch();
  useEffect(() => {
    GetStateList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      GetUserList();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    // if (userList?.length > 0) {
    if (stateList?.length > 0) {
      if (id) {
        getAssoData(id);
      } else {
        append({
          title: "",
          cost: "",
          frequency: "Annual",
          description: "",
        });
      }
    }
    // }
    // eslint-disable-next-line
  }, [stateList, userList, id]);

  const GetStateList = async () => {
    let seData = {
      url: "/api/Common/GetStateList",
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        setStateList(
          res?.data.payload?.map((x) => {
            return {
              ...x,
              label: x?.stateName,
              value: x?.stateName,
            };
          })
        );
      }
    }
  };
  const GetUserList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Association/GetAllAssociationAllocationListByAssociationId?associationId=${id}`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c) => {
          return {
            value: c?.userId,
            label: c?.userName,
          };
        });
        setUserList(comList);
      }
    }
  };

  const getAssoData = async () => {
    let seData = {
      url: `/api/Association/GetAssociationByAssociationId?associationId=${id}`,
      headers: headers,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let useDe = res?.data.payload;
        setValue("associationId", id);
        setValue("name", useDe?.name);
        setValue("DisplayImageUrl", useDe?.logo);

        setValue("Address", useDe?.address);
        setValue("City", useDe?.city);
        setValue("State", useDe?.state);
        setStateValue1(useDe?.state);
        setValue("Zip", useDe?.zip);
        setValue("ContactName", useDe?.contactName);
        setValue("Email", useDe?.email);
        setValue("Phone", useDe?.phone);
        setValue("PaymentRemittanceAddress", useDe?.paymentRemittanceAddress);
        setValue("PaymentCity", useDe?.paymentCity);
        setValue("PaymentState", useDe?.paymentState);
        setStateValue2(useDe?.paymentState);
        setValue("PaymentZip", useDe?.paymentZip);
        setValue("type", useDe?.type);
        setValue("website", useDe?.website);
        setValue("PublicKey", useDe?.publicKey);
        setValue("SecretKey", useDe?.secretKey);
        setValue("Currency", useDe?.currency);
        // setValue(
        //   "adminId",
        //   useDe?.associationAdminList[0]?.userId
        //     ? useDe?.associationAdminList[0]?.userId
        //     : ""
        // );
        if (useDe?.associationAdminList?.length > 0) {
          setSelectAdmin(useDe?.associationAdminList?.map((x) => x?.userId));
        }
        if (useDe?.associationMembershipsTypes?.length > 0) {
          setValue("membership", useDe?.associationMembershipsTypes);
        } else {
          append({
            title: "",
            cost: "",
            frequency: "Annual",
            description: "",
          });
        }
      }
    }
  };
  const onSubmit = async (data) => {
    let Menu = {
      ...data,
      AssociationAdminList: selectAdmin?.length > 0 ? selectAdmin : [],
      CreatedBy: logDetail?.userId,
    };
    let seData = {
      url: "/api/Association/CreateAssociation",
      body: Menu,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);

    if (res?.status === 200 && res?.data.success) {
      let asId = res?.data.payload;
      dispatch(fetchDataAssociation(logDetail?.userId));

      if (data?.membership?.length > 0) {
        let sendData = {
          url: `/api/Association/CreateAssociationMembershipsTypes`,
          body: {
            associationId: asId?.associationId,
            membershipsTypesList: data?.membership?.map((x) => {
              return { ...x, cost: parseFloat(x?.cost) };
            }),
            CreatedBy: logDetail?.userId,
          },
          headers: headers,
        };
        await PostCallApi(sendData);
        setOpenCircle(false);

        //for warning remove
        // let wr = res1;
        // res1 = wr;
      }
      ToastSuccess(res?.data.message);
      reset();
      nav(-1);
      setDerror({ err: "" });
    } else {
      setDerror({ err: res?.data?.message });
      ToastError(res?.data.message);
    }
  };
  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("Logo", url);
        setValue("DisplayImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png|svg|webp)$/i)) {
        const reader = new FileReader();
        setFileName(e.target.files[0].name);
        reader.addEventListener(
          "load",
          () => {
            setSrc(reader.result);
            setCropModal(true);
          },
          false
        );
        reader.readAsDataURL(e.target.files[0]);
        e.target.value = null;
      } else {
        Swal.fire({
          position: "center",
          width: "50%",
          // icon: "success",
          title: "Please Select Only Image",
          // text: des,
          confirmButtonText: "Close",
          confirmButtonColor: "#E64942",
        });
      }
    }
  };
  // crop code end

  const handleState1 = (e) => {
    setStateValue1(e);
    setValue("State", e);
  };

  const handleState2 = (e) => {
    setStateValue2(e);
    setValue("PaymentState", e);
  };

  return (
    <section className="association_create">
      <div className="main_wrapper">
        <div className="title mb-3">{id ? "Edit" : "Create"} Association </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 250,
                          message: "Max 250 characters",
                        },
                      })}
                    ></input>
                    {errors.name && (
                      <span role="alert" className="error_text">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Logo
                      <span className="red-color"> *</span>
                    </label>
                    <div className="d-flex upload_btn_sec">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        placeholder="No file choose"
                        {...register(`DisplayImageUrl`, {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          // onChange: (e) => handleFile(e),
                        })}
                      />
                      <IconButton component="label" className="upload_btn m-0">
                        Choose File
                        <input
                          id="upload"
                          type="file"
                          accept="image/*"
                          className="form-control"
                          hidden
                          onChange={(e) => handleFile(e)}
                        />
                      </IconButton>
                    </div>
                    {/* crop code start */}
                    {src && (
                      <CropModel
                        src={src}
                        filename={filename}
                        setCroppedImageUrl={setCroppedImageUrlCallback}
                        openCropModal={openCropModal}
                        setCropModal={setCropModal}
                      />
                    )}
                    {/* crop code end */}
                    {errors.DisplayImageUrl && (
                      <span role="alert" className="error_text">
                        {errors.DisplayImageUrl.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      Address
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  address"
                      {...register("Address", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 1000,
                          message: "Max 1000 characters",
                        },
                      })}
                    />
                    {errors.Address && (
                      <span role="alert" className="error_text">
                        {errors.Address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      City
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  City"
                      {...register("City", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 100,
                          message: "Max 100 characters",
                        },
                      })}
                    />
                    {errors.City && (
                      <span role="alert" className="error_text">
                        {errors.City.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      State
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={stateList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select State"
                      filterOption={customFilter}
                      value={stateList?.filter(
                        (obj) => obj.value === stateValue1
                      )}
                      onChange={(e) => handleState1(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      placeholder="Enter  State"
                      {...register("State", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select State</option>

                      {stateList &&
                        stateList?.map((st, i) => (
                          <option value={st?.stateName} key={i}>
                            {st?.stateName}
                          </option>
                        ))}
                    </select>
                    {errors.State && (
                      <span role="alert" className="error_text">
                        {errors.State.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      Zip
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter  Zip"
                      {...register("Zip", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 6,
                          message: "You can enter max 6 charactor",
                        },
                      })}
                    />
                    {errors.Zip && (
                      <span role="alert" className="error_text">
                        {errors.Zip.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Contact Name
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  contact name"
                      {...register("ContactName", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 250,
                          message: "Max 250 characters",
                        },
                      })}
                    />
                    {errors.ContactName && (
                      <span role="alert" className="error_text">
                        {errors.ContactName.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Email
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email ID"
                      {...register("Email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                        maxLength: {
                          value: 100,
                          message: "Max 100 characters",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span role="alert" className="error_text">
                        {errors.Email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Phone Number
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your phone number"
                      {...register("Phone", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                        maxLength: {
                          value: 20,
                          message: "Max 20 characters",
                        },
                      })}
                    />
                    {errors.Phone && (
                      <span role="alert" className="error_text">
                        {errors.Phone.message}
                      </span>
                    )}
                  </div>
                </div>
                {id && (
                  <>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {" "}
                          Admin
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="colors"
                          options={userList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Admin"
                          filterOption={customFilter}
                          value={userList?.filter((obj) =>
                            selectAdmin?.includes(obj.value)
                          )}
                          onChange={handleChangeAdmin}
                        />
                        {/* <select
                          type="text"
                          className="form-select"
                          {...register("adminId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Admin</option>
                          {userList &&
                            userList?.map((u) => (
                              <option value={u?.value} key={u?.value}>
                                {u?.label}
                              </option>
                            ))}
                        </select> */}
                        {errors.adminId && (
                          <span role="alert" className="error_text">
                            {errors.adminId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6"></div>
                    <div className="col-lg-4 col-md-6"></div>
                  </>
                )}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <div>
                      <label className="form-label">Payment Remittance</label>
                    </div>
                    <label className="form-label">
                      {" "}
                      Address
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  address"
                      {...register("PaymentRemittanceAddress", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 1000,
                          message: "Max 1000 characters",
                        },
                      })}
                    />
                    {errors.PaymentRemittanceAddress && (
                      <span role="alert" className="error_text">
                        {errors.PaymentRemittanceAddress.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-auto">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      City
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter  City"
                      {...register("PaymentCity", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 100,
                          message: "Max 100 characters",
                        },
                      })}
                    />
                    {errors.PaymentCity && (
                      <span role="alert" className="error_text">
                        {errors.PaymentCity.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-auto">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      State
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={stateList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select State"
                      filterOption={customFilter}
                      value={stateList?.filter(
                        (obj) => obj.value === stateValue2
                      )}
                      onChange={(e) => handleState2(e?.value)}
                    />
                    <select
                      type="text"
                      className="form-select d-none"
                      placeholder="Enter  State"
                      {...register("PaymentState", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select State</option>

                      {stateList &&
                        stateList?.map((st, i) => (
                          <option value={st?.stateName} key={i}>
                            {st?.stateName}
                          </option>
                        ))}
                    </select>
                    {errors.PaymentState && (
                      <span role="alert" className="error_text">
                        {errors.PaymentState.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      {" "}
                      Zip
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter  Zip"
                      {...register("PaymentZip", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 6,
                          message: "You can enter max 6 charactor",
                        },
                      })}
                    />
                    {errors.PaymentZip && (
                      <span role="alert" className="error_text">
                        {errors.PaymentZip.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Type
                      <span className="red-color"> *</span>
                    </label>
                    <div>
                      <input
                        type="radio"
                        placeholder="Enter Type"
                        value={"Association/Organization"}
                        {...register(`type`, {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      ></input>{" "}
                      Association/Organization
                    </div>
                    <div>
                      <input
                        type="radio"
                        placeholder="Enter Type"
                        value={"State Association"}
                        {...register(`type`, {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      ></input>{" "}
                      State Association
                    </div>
                    {errors.type && (
                      <span role="alert" className="error_text">
                        {errors.type.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label"> Website</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter website"
                      {...register("website", {
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        maxLength: {
                          value: 100,
                          message: "Max 100 characters",
                        },
                      })}
                    />
                    {errors.website && (
                      <span role="alert" className="error_text">
                        {errors.website.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Public Key
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter public key"
                      {...register("PublicKey", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors.PublicKey && (
                      <span role="alert" className="error_text">
                        {errors.PublicKey.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Secret Key
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter secret key"
                      {...register("SecretKey", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors.SecretKey && (
                      <span role="alert" className="error_text">
                        {errors.SecretKey.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Currency
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter currency"
                      {...register("Currency", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors.Currency && (
                      <span role="alert" className="error_text">
                        {errors.Currency.message}
                      </span>
                    )}
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <h5>Membership Fee&apos;s</h5>
                  <Button
                    className="common_btn"
                    onClick={() =>
                      append({
                        title: "",
                        cost: "",
                        frequency: "Annual",
                        membershipType: "",
                        description: "",
                      })
                    }
                  >
                    <VscAdd className="me-2" />
                    Add
                  </Button>
                </div>
                {fields?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Title
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                            {...register(`membership.${index}.title`, {
                              required: "this field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 250,
                                message: "Max 250 characters",
                              },
                            })}
                          />
                          {errors.membership?.[index]?.title && (
                            <span role="alert" className="error_text">
                              {errors.membership?.[index]?.title.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Cost
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Cost"
                            {...register(`membership.${index}.cost`, {
                              required: "this field is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Enter valid value the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                              },
                            })}
                          />
                          {errors.membership?.[index]?.cost && (
                            <span role="alert" className="error_text">
                              {errors.membership?.[index]?.cost.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Frequency
                            <span className="red-color"> *</span>
                          </label>
                          <select
                            type="text"
                            className="form-select"
                            {...register(`membership.${index}.frequency`, {
                              required: "this field is required",
                            })}
                          >
                            <option value="Annual">Annual</option>
                          </select>
                          {errors.membership?.[index]?.frequency && (
                            <span role="alert" className="error_text">
                              {errors.membership?.[index]?.frequency.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Membership Type
                            <span className="red-color"> *</span>
                          </label>
                          <select
                            type="text"
                            className="form-select"
                            {...register(`membership.${index}.membershipType`, {
                              required: "this field is required",
                            })}
                          >
                            <option value="">Select Membership Type</option>
                            <option value="Industry">Industry/Company</option>
                            <option value="LEA">LEA</option>
                            <option value="State Agency">State Agency</option>
                            <option value="User">User</option>
                          </select>
                          {errors.membership?.[index]?.membershipType && (
                            <span role="alert" className="error_text">
                              {
                                errors.membership?.[index]?.membershipType
                                  .message
                              }
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-5">
                        <div className="form-group">
                          {/* <div className="d-flex justify-content-between"> */}
                          <label className="form-label"> Description</label>

                          {/* </div> */}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Description"
                            {...register(`membership.${index}.description`, {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            })}
                          />
                          {errors.membership?.[index]?.description && (
                            <span role="alert" className="error_text">
                              {errors.membership?.[index]?.description.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {index === 0 ? (
                        ""
                      ) : (
                        <div className="col-md-1 my-auto text-end">
                          <Button
                            className="delet_Icon_button"
                            onClick={() => remove(index)}
                          >
                            {" "}
                            <MdDelete fontSize={24} />
                          </Button>
                        </div>
                      )}
                      {fields?.length === index + 1 ? "" : <hr />}
                    </React.Fragment>
                  );
                })}
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button className="back_button" onClick={() => nav(-1)}>
                      Back
                    </Button>
                    <Button
                      className="common_btn ms-3"
                      type="submit"
                      disabled={openCircle}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>

                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AssociationCreate;
