import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { VscCheck, VscEdit, VscEye } from "react-icons/vsc";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import {
  IndustryDrop,
  LeaDrop,
  StateAgencyDrop,
} from "../../Components/AssociationMember";
// import ResendPopup from "../../Components/ResendPopup";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const PermenentDeletePopup = lazy(() =>
  import("../../Components/PermenentDeletePopup")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "associationMemberName",
    label: "Group Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "invoiceName",
    label: "Invoice User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "invoiceEmail",
    label: "Invoice Email",
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
  },
  {
    id: "poNumber",
    label: "PONumber",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "invoiceDate",
    label: "Submit Date",
  },
  {
    id: "dueDate",
    label: "Due Date",
  },
  {
    id: "receivedPaymentDate",
    label: "Pymt Date",
  },
  {
    id: "checkNumber",
    label: "Check Number",
  },
  {
    id: "isManually",
    label: "IsManually",
  },
  {
    id: "isPayByOnline",
    label: "Paid Online",
  },
  {
    id: "invoiceStatus",
    label: "Status",
    color: true,
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function AssociationMembershipInvoiceApproval() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const [filterPage, setFilterPage] = useState(false);
  const [asList, setASList] = useState([]);
  const [asId, setASId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [selectLea, setSelectLea] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [selectIntry, setSelectIntry] = useState([]);

  useEffect(() => {
    document.title = "FIG Events | Association Membership Invoice Approval";
    getAssoList();
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListForFliter?userId=${logindetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        let data = res?.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setASList(data);
      }
    }
  };

  const handleOpenPop = (eId, st) => {
    setApData({ ...eId, st: st });
    setOpenPop(true);
  };

  const handleClose = () => {
    setApData();
    setOpenPop(false);
    reset();
  };

  const GetMembersInvoiceList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let memberList = [];
    if (selectLea?.length > 0) {
      memberList = selectLea;
    }
    if (selectAgency?.length > 0) {
      memberList = [...memberList, ...selectAgency];
    }
    if (selectIntry?.length > 0) {
      memberList = [...memberList, ...selectIntry];
    }
    let seData = {
      url: `/api/AssociationPayment/GetAssociationInvoiceList`,
      body: {
        ...data,
        invoiceStatus: data?.invoiceStatus ? data?.invoiceStatus : "",
        userId: logindetail?.userId,
        associationMemberId: memberList?.length > 0 ? memberList : [],
      },
    };
    let res = await PostCallApi(seData);
    setFilterPage(!filterPage);
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        let astype = c?.associationType ? " - " + c?.associationType : "";
        // let bd = {
        //   email:
        //     c?.resendEmailList?.length > 0
        //       ? c?.resendEmailList?.split(";")
        //       : c?.associationMemberEmail
        //       ? [c?.associationMemberEmail]
        //       : [c?.email],
        //   associationPaymentId: c?.associationPaymentId,
        //   createdBy: logindetail?.userId,
        // };
        return {
          id: i + 1,
          ...c,
          bgcolor:
            c?.invoiceStatus === "Complete"
              ? ""
              : c?.days > 90
              ? "#f3c2c3"
              : c?.days > 60
              ? "#fffaca"
              : c?.days > 30
              ? "#18bad447"
              : // ? "#73c04d6b"
                "#18bad447",
          color:
            c?.invoiceStatus === "Approve"
              ? "new_white"
              : c?.invoiceStatus === "Complete"
              ? "approv"
              : "created",
          isManually: c?.isManually ? "True" : "",
          isPayByOnline: c?.isPayByOnline ? "Yes" : "",
          dueDate: c?.isPayByOnline
            ? ""
            : c?.dueDate
            ? new Date(c?.dueDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          invoiceDate: c?.invoiceDate
            ? new Date(c?.invoiceDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          receivedPaymentDate: c?.isPayByOnline
            ? c?.payOnlineDate
              ? new Date(c?.payOnlineDate)?.toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  hour: "2-digit",
                  hour12: true,
                })
              : ""
            : c?.receivedPaymentDate
            ? new Date(c?.receivedPaymentDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          associationMemberName: c?.associationMemberName
            ? c?.associationMemberName + astype
            : "",
          invoiceStatus:
            c?.invoiceStatus === "Complete" ? "Completed" : c?.invoiceStatus,
          Action: (
            <>
              {c?.invoiceStatus === "Complete" ? (
                logindetail?.roleId === 1 || logindetail?.roleId === 2 ? (
                  <Link
                    to={
                      c?.registerType === "User"
                        ? `edit/${c?.associationId}/${c?.associationPaymentId}`
                        : `company/edit/${c?.associationId}/${c?.associationPaymentId}`
                    }
                    className="btn_edit me-3 d-flex align-items-center "
                  >
                    <VscEdit />
                  </Link>
                ) : (
                  ""
                )
              ) : (
                <>
                  {logindetail?.roleId === 1 || logindetail?.roleId === 2 ? (
                    <>
                      <Tooltip title="Complete">
                        <button
                          type="button"
                          className="btn_edit me-3"
                          onClick={() => handleOpenPop(c, "Complete")}
                        >
                          <VscCheck />
                        </button>
                      </Tooltip>
                      {/* <button
                          type="button"
                          className="btn_dlt me-3"
                          onClick={() => handleOpenPop(c, "Reject")}
                        >
                          <VscChromeClose className="icon" />
                        </button> */}
                    </>
                  ) : c?.viewApprove ? (
                    <Tooltip title="Complete">
                      <button
                        type="button"
                        className="btn_edit me-3"
                        onClick={() => handleOpenPop(c, "Complete")}
                      >
                        <VscCheck />
                      </button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  <Link
                    to={
                      c?.registerType === "User"
                        ? `edit/${c?.associationId}/${c?.associationPaymentId}`
                        : `company/edit/${c?.associationId}/${c?.associationPaymentId}`
                    }
                    className="btn_edit me-3 d-flex align-items-center "
                  >
                    <VscEdit />
                  </Link>
                </>
              )}
              <Link
                to={`view/${c?.associationInvoiceId}`}
                className="btn_edit me-3 d-flex align-items-center"
              >
                <VscEye />
              </Link>
              <PermenentDeletePopup
                title={"Delete User Association Invoice"}
                text={c?.userName + "'s invoice"}
                url={`/api/AssociationPayment/DeleteAssociationMembershipByPaymentId?associationPaymentId=${c?.associationPaymentId}&createdBy=${logindetail?.userId}&deletePermanently=`}
                callBack={true}
                callBackFunction={CallBackApi}
              />
              {/* <ResendPopup
                title={`Resend Association Product Invoice`}
                body={bd}
                url={`/api/AssociationPayment/AddEmailsForAssociationPaymentReceipt`}
                afterAPICallAnotherURl={`/api/AssociationPayment/ViewOrResendAssociationInvoiceDetails?associationInvoiceId=${c?.associationInvoiceId}&isResend=true`}
              /> */}
              <Button
                className="ms-3"
                style={{
                  background:
                    "linear-gradient(90.24deg, #18BAD4 2.7%, #31B680 54.38%, #80C242 98.4%)",
                  padding: "0.5rem",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "none",
                }}
                onClick={() => handleOpenPop(c, "Resend")}
              >
                Resend
              </Button>
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data.message);
    }
  };

  const HandleRequest = async (data) => {
    setOpenCircle(true);
    if (apData?.st === "Resend") {
      let seData = {
        url: `/api/AssociationPayment/ViewOrResendAssociationInvoiceDetails?associationInvoiceId=${apData?.associationInvoiceId}&isResend=true`,
      };

      setOpenCircle(true);
      let resp = await GetCallApi(seData);
      setOpenCircle(false);
      if (resp?.status === 200 && resp.data.success) {
        handleClose();
        ToastSuccess(resp.data.message);
      } else {
        ToastError(resp.data.message);
      }
    } else {
      let reqData = {
        ...data,
        registerType: apData?.registerType,
        associationInvoiceId: apData?.associationInvoiceId,
        createdBy: logindetail?.userId,
        invoiceStatus: apData?.st,
      };

      let seData = {
        url: `/api/AssociationPayment/ApproveOrCompleteAssociationInvoice`,
        body: reqData,
      };

      let resp = await PostCallApi(seData);
      setOpenCircle(false);
      if (resp?.status === 200 && resp.data.success) {
        setOpenCircle(false);
        handleClose();
        let msg = resp.data.message;
        CallBackApi();
        ToastSuccess(msg);
      } else {
        setOpenCircle(false);
        ToastError(resp.data.message);
      }
    }
  };
  var dtToday = new Date().toISOString().split("T")[0];

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIntry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const CallBackApi = () => {
    let send = {
      associationId: getValues("associationId"),
      invoiceStatus: getValues("invoiceStatus"),
      poNumber: getValues("poNumber") ? getValues("poNumber") : "",
      paymentDate: getValues("paymentDate") ? getValues("paymentDate") : null,
    };
    GetMembersInvoiceList(send);
  };

  const handleAS = (e) => {
    setASId(e);
    setValue("associationId", e);
  };

  const PaymentStatusOption = [
    { value: "Approve", label: "Approve" },
    { value: "Complete", label: "Complete" },
  ];

  const handlePaymentStatus = (e) => {
    setPaymentStatus(e);
    setValue("invoiceStatus", e);
  };

  const handleClearFilter = () => {
    setASId();
    setPaymentStatus();
    setSelectLea([]);
    setSelectIntry([]);
    setSelectAgency([]);
    setDataOfTable([]);
    reset();
  };

  var dtTen = new Date();
  dtTen?.setFullYear(dtTen?.getFullYear() + 10);
  dtTen = dtTen.toISOString().split("T")[0];

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetMembersInvoiceList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Association</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={asList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Association"
                              filterOption={customFilter}
                              value={asList?.filter(
                                (obj) => obj.value === asId
                              )}
                              onChange={(e) => handleAS(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">PO Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter PO Number"
                              {...register("poNumber", {
                                pattern: {
                                  value: /^[^\s]+(\s+[^\s]+)*$/,
                                  message:
                                    "Starting and Ending Space not allowed",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Max 200 characters",
                                },
                              })}
                            />
                            {errors.poNumber && (
                              <span role="alert" className="error_text">
                                {errors.poNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Status</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={PaymentStatusOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Payment Status"
                              filterOption={customFilter}
                              value={PaymentStatusOption?.filter(
                                (obj) => obj.value === paymentStatus
                              )}
                              onChange={(e) => handlePaymentStatus(e?.value)}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Payment Date</label>
                            <input
                              type="date"
                              className="form-control"
                              max={dtTen}
                              {...register("paymentDate", {
                                valueAsDate: true,
                              })}
                            />
                            {errors.paymentDate && (
                              <span role="alert" className="error_text">
                                {errors.paymentDate.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <LeaDrop
                            selectedLea={selectLea}
                            handleChangeLea={handleChangeLea}
                          />
                        </div>
                        <div className="col-md-3">
                          <StateAgencyDrop
                            handleChangeAgency={handleChangeAgency}
                            selectedAgency={selectAgency}
                          />
                        </div>
                        <div className="col-md-3">
                          <IndustryDrop
                            selectedIndustry={selectIntry}
                            handleChangeIndustry={handleChangeIndustry}
                          />
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    headCells={headCellsEvent}
                    title="Association Membership Invoice Approval List"
                    action={true}
                    ListData={dataOfTable}
                    filterPage={filterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  open={openCircle}
                  sx={{ color: "#fff", zIndex: () => 9999 }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>Invoice {apData?.st} </h6>
                  <form onSubmit={handleSubmit(HandleRequest)}>
                    <div className="popup_delete_detail">
                      <p className="delete_text">
                        Are you sure you want to {apData?.st.toLowerCase()}{" "}
                        invoice {apData?.st === "Resend" ? "to" : "of"}{" "}
                        <span>
                          {" "}
                          {apData?.invoiceName
                            ? apData?.invoiceName
                            : apData?.userName}
                          ?
                        </span>
                      </p>
                      {apData?.st === "Complete" ? (
                        <>
                          <div className="form-group">
                            <label className="form-label">
                              Received Payment Date
                              <span className="red-color"> *</span>
                            </label>

                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter Email"
                              max={dtToday}
                              {...register("receivedPaymentDate", {
                                required: "This field is require",
                                valueAsDate: true,
                              })}
                            />
                            {errors?.receivedPaymentDate && (
                              <span className="error_text">
                                {errors.receivedPaymentDate.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="form-label">
                              Check Number
                              <span className="red-color"> *</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Check Number"
                              {...register("checkNumber", {
                                required: "This field is require",
                                pattern: {
                                  value: /^[0-9+-]+$/,
                                  message: "Enter valid number",
                                },
                              })}
                            />
                            {errors?.checkNumber && (
                              <span className="error_text">
                                {errors.checkNumber.message}
                              </span>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="submit"
                        className="popup_btn delete"
                        // onClick={HandleRequest}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default AssociationMembershipInvoiceApproval;
