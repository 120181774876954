import React from "react";
import { useParams } from "react-router-dom";
import "./ViewProduct.scss";
import productData from "./productdata";
import { Button } from "@mui/material";
import WebFooter from "../../../Shared/WebFooter";

const productDetailRightImg = "/assets/icons/productDetailRightImg.svg";

function ViewProduct() {
  let { id } = useParams();
  const FoundProduct = productData.find((element) => element.id == id);
  console.log(FoundProduct);
  return (
    <>
      <section className="ProductDetails">
        <div className="row align-items-center m-0">
          <div className="col-md-6 left">
            <div className="ProductDetailsTitle">
              <h1>
                Experience the Future with <span>{FoundProduct?.title}</span>
              </h1>
              <p>{FoundProduct?.innerDescription}</p>
              <Button className="theme-btn">Get Started</Button>
            </div>
            <div className="logos">
              {FoundProduct?.InnerLogos?.map((item, i) => (
                <div className="imgbox" key={i}>
                  <img src={item?.img} alt="" className="img-fluid" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 right">
            <img src={productDetailRightImg} className="img-fluid" alt="" />
          </div>
        </div>
      </section>
      <WebFooter />
    </>
  );
}
export default React.memo(ViewProduct);
