import React, { useState } from "react";
import "./index.scss";
import NewLIstTable from "../../Modules/Components/NewLIstTable";
import { Box, Modal } from "@mui/material";
export default function OnSiteRegistration() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const [open, setOpen] = useState(true);

  const headCellsCompanyReg = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "GenerateBadge",
      label: "Generate Badge",
    },
    {
      id: "Name",
      label: "Name",
    },
    {
      id: "Organization",
      label: "Organization",
    },
    {
      id: "Email",
      label: "Email",
    },
  ];
  const dataOfTable1 = [
    {
      id: 0,
      GenerateBadge: (
        <button className="green_button border-0">Generate Badge</button>
      ),
      Name: "Mike Johnson",
      Organization: "Giant Food Corp",
      Email: "mike@giantfood.com",
    },
    {
      id: 0,
      GenerateBadge: (
        <button className="green_button border-0">Generate Badge</button>
      ),
      Name: "Mike Johnson",
      Organization: "Giant Food Corp",
      Email: "mike@giantfood.com",
    },
  ];
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section className="on-site-registration">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h1 className="title mb-3">Lookup Conference Attendee</h1>
            <form action="" className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <label htmlFor="">Fisrt Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Fisrt Name"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group">
                  <label htmlFor="">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div className="col-xl-12">
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div className="col-xl-12">
                <div className="form-group">
                  <label htmlFor="">Select Organization</label>
                  <select className="form-select" name="" id="">
                    <option value="0">Big School District</option>
                    <option value="1">Healthy Foods Co.</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group d-flex gap-2 mt-4">
                  <button className="green_button border-0">Lookup</button>
                  <button
                    type="button"
                    className="green_button border-0"
                    onClick={handleOpen}
                  >
                    Create New User
                  </button>
                </div>
              </div>
            </form>
            <hr />
            <NewLIstTable
              headCells={headCellsCompanyReg}
              ListData={dataOfTable1}
            />
          </div>
        </div>
      </div>
      <Modal
        sx={{ zIndex: 9 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style} className="delete_data_box">
          <div className="popup_delete_detail_box">
            <h6>Enter Attendee Information</h6>
            <div className="popup_delete_detail">
              <form action="" className="row">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label htmlFor="">Fisrt Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Fisrt Name"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label htmlFor="">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label htmlFor="">Select Organization</label>
                    <select className="form-select" name="" id="">
                      <option value="0">Big School District</option>
                      <option value="1">Healthy Foods Co.</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label htmlFor="">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Password"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex gap-2 justify-content-end">
                    <button className="green_button border-0">
                      Submit & Print Badge
                    </button>
                    <button
                      type="button"
                      className="grey_button border-0"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </section>
  );
}
