export const customFilter = (option, searchText) => {
  if (
    option?.data?.label
      ?.toString()
      ?.toLowerCase()
      .includes(searchText?.toLowerCase())
  ) {
    // option.data.value.toLowerCase().includes(searchText.toLowerCase())
    return true;
  } else {
    return false;
  }
};
