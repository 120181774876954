import React, { Suspense, lazy, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";
import { Backdrop, CircularProgress } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { Config } from "../../../Utils/Config";

const speckerProfileBanner = "/assets/images/speckerProfileBanner.png";
const speckerProfileBanner1 = "/assets/images/speckerProfileBanner1.png";
const speckerProfileBanner2 = "/assets/images/speckerProfileBanner2.png";
const NotFound = "/assets/images/Image_not_available.jpg";
const Postshow = lazy(() => import("../../NewCommunity/Post_show"));
const pro_img = "/assets/images/default_profile.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ProfileForConnection() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [usDetails, setUsDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const { id } = useParams();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);

  const images = [
    speckerProfileBanner,
    speckerProfileBanner1,
    speckerProfileBanner2,
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setSelectedImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    document.title = "FIG Events | User Profile";
    if (id) {
      GetUserDetail(id);
      getListCon(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const GetUserDetail = async (id) => {
    let seData = {
      url: `/api/UserMst/GetUserProfileData?userId=${id}&loginUserId=${LogDetail?.userId}`,
      // url: `/api/UserMst/GetUserConnectionProfile?userSenderId=${LogDetail?.userId}&userReceiverId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let sv = res?.data.payload;
        let resp = await GetCallApi({
          url: `/api/UserMst/GetUserPostDataByUserAndType?userId=${id}&postType=MyPost`,
        });
        let postList;
        if (resp?.status === 200 && resp?.data?.success) {
          postList = resp.data?.payload?.map((item, i) => {
            return {
              ...item,
              key: i,
              postId: item?.communityPostId,
              name: sv?.userName,
              userPImg: sv?.userProfileImage
                ? Config.API_HOST_URL_live + sv?.userProfileImage
                : pro_img,
              postTypes: item?.postTypes,
              attachments: item.attachments?.map((att) => {
                return {
                  postId: att?.communityPostId,
                  postTransactionId: att?.communityPostTransactionId,
                  attachmentUrl: att?.attachmentUrl,
                };
              }),
              description: item?.description,
              date: item?.date
                ? new Date(item?.date)?.toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })
                : "",
              userId: item?.createdBy,
              like: item?.isLiked,
              totalLike: item?.totalLike,
              comments: item?.comments,
              totalComment: item?.totalComment,
              isCommunityPost: false,
              communityGroupName: item?.communityGroupName,
            };
          });
        }
        setUsDetails({ ...sv, postList: postList });
      }
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const getListCon = async (id) => {
    let seData = {
      url: `/api/UserProfile/GetMyConnectionsList?userId=${id}`,
    };
    let response = await GetCallApi(seData);
    if (response?.status === 200) {
      if (response?.data.success) {
        const Mylist = response?.data.payload?.map((item) => {
          return {
            ...item,
            name:
              item?.firstName === "" ||
              item?.firstName === null ||
              item?.lastName === "" ||
              item?.lastName === null
                ? ""
                : item.firstName + " " + item.lastName,
            img:
              item.profileUrl === null || item.profileUrl === ""
                ? pro_img
                : `${Config.API_HOST_URL_live}${item.profileUrl}`,
            activeHours:
              item.activeHours !== null || item.activeHours !== ""
                ? item.activeHours
                : "0",
            followers:
              item.followers === null || item.followers === ""
                ? ""
                : item.followers,
            join: item?.userJoinDate
              ? new Date(item?.userJoinDate?.split("T")[0]).toLocaleDateString(
                  "en-us",
                  {}
                )
              : "",
          };
        });
        setConList(Mylist);
      } else {
        setConList([]);
      }
    }
    // });
  };

  const [comId, setComId] = useState("");
  const [CommentReply, setCommentReply] = useState(false);
  const handleComment = (id) => {
    setCommentReply(true);
    setComId(id);
    setPsEdit(false);
    if (id === comId) {
      setCommentReply(!CommentReply);
    }
  };
  const [psEd, setPsEd] = useState("");
  const [psEdit, setPsEdit] = useState(false);
  const handleEdit = (id) => {
    setCommentReply(false);
    setPsEdit(true);
    setPsEd(id);
    if (id === psEd) {
      setPsEdit(!psEdit);
    }
  };
  const [readMore, setReadMore] = useState(false);
  const [readId, setReadId] = useState("");
  const handleReadMore = (id) => {
    setReadMore(true);
    setReadId(id);
    if (id === readId) {
      setReadMore(!readMore);
    }
  };
  const handleReadLess = (id) => {
    if (id === readId) {
      setReadMore(!readMore);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="ProfileForConnection">
          <img
            src={
              usDetails?.userCoverPhoto
                ? Config.API_HOST_URL_live + usDetails?.userCoverPhoto
                : selectedImage
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = selectedImage;
            }}
            className="profile_images_pic img-fluid w-100"
            alt=" Profile"
          />

          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="row">
                <div
                  className="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12 index-9 aos-init aos-animate"
                  data-aos="fade-in"
                >
                  <div className="profile_images">
                    <img
                      src={
                        usDetails?.userProfileImage
                          ? Config.API_HOST_URL_live +
                            usDetails?.userProfileImage
                          : NotFound
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = NotFound;
                      }}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-10 col-lg-9 col-md-8 col-sm-12 col-12 aos-init aos-animate"
                  data-aos="fade-in"
                >
                  <div className="right-image">
                    <div className="content">
                      <h2>{usDetails?.userName ? usDetails?.userName : ""}</h2>{" "}
                      <h3>{usDetails?.userTitle}</h3>
                      <p style={{ whiteSpace: "pre-line" }}>
                        {usDetails?.userDescription
                          ? usDetails?.userDescription?.slice(0, 500)
                          : ""}
                        {usDetails?.userDescription
                          ? usDetails?.userDescription?.length > 500 && (
                              <span
                                className="description cr"
                                style={{ cursor: "pointer" }}
                              >
                                ...
                              </span>
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="new_community_page Connection_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="community_wrapper">
                <div className="row">
                  <div className="title mb-3">Post</div>
                  <section className="community_feed_new">
                    <div className="row">
                      <div className="col-xl-9 col-lg-9 col-md-12">
                        <div className="post_div">
                          <div className="commented_post">
                            {usDetails?.postList?.map((item, i) => (
                              <Postshow
                                comGroupId={
                                  // grpId?.value === "1" || grpId?.value === "3"
                                  //   ?
                                  "00000000-0000-0000-0000-000000000000"
                                  // : grpId?.value
                                }
                                key={i}
                                data={item}
                                likeId={false}
                                setLikeId={() => GetUserDetail(id)}
                                comId={comId}
                                CommentReply={CommentReply}
                                handleComment={handleComment}
                                setCommentReply={setCommentReply}
                                psEd={psEd}
                                psEdit={psEdit}
                                handleEdit={handleEdit}
                                setPsEdit={setPsEdit}
                                handleReadMore={handleReadMore}
                                readMore={readMore}
                                readId={readId}
                                handleReadLess={handleReadLess}
                              />
                            ))}
                            {usDetails?.postList?.length === 0 ? (
                              <div className="text-center p_20_text mb-3">
                                No Post Found
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="connectionlist_card_new_for_profile">
                          <div className="card_title_connection d-flex">
                            <h5>Connections</h5>
                          </div>
                          <div className="card_content">
                            <div className="user_list">
                              {conList?.length > 0 ? (
                                <>
                                  {conList?.map((item, i) => (
                                    <Link
                                      className="d-flex u_box"
                                      key={i}
                                      to={`/user-connection-profile/${item?.userId}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      <img
                                        src={item?.img ? item?.img : pro_img}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = pro_img;
                                        }}
                                        className="img-fluid user_p_img"
                                        alt=""
                                      />
                                      <div className="u_name">
                                        <label style={{ cursor: "pointer" }}>
                                          {item?.name}
                                        </label>
                                        <span className="f-14-400 ">
                                          Followers : {item?.followers}
                                        </span>
                                      </div>
                                    </Link>
                                  ))}
                                </>
                              ) : (
                                <div>No Data Found</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ProfileForConnection;
