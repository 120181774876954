import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Rating from "react-rating";
import { Config } from "../../../../Utils/Config";
// import lucide_view from "../../../../assets/icons/lucide_view.svg";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import Swal from "sweetalert2";
import { GoHeart, GoHeartFill } from "react-icons/go";
import ReactPlayer from "react-player";
import { MdOutlineOndemandVideo } from "react-icons/md";
import DeletePopup from "../../../Components/DeletePopup";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
const NotFound = "/assets/images/Image_not_available.jpg";
const star_full = "/assets/images/star_full.png";
const star_blank = "/assets/images/star_blank.png";
const pro_img = "/assets/images/default_profile.jpg";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "auto",
  maxWidth: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function ProductDetailPublic() {
  const { id, conf } = useParams();
  const [prDetails, setPrDetails] = useState();
  const [openCall, setOpenCall] = React.useState(false);
  const [rate, setRate] = React.useState(5);
  const [openCircle, setOpenCircle] = useState(false);
  const [feedBackText, setFeedbackText] = useState("");
  const [multiImg, setMultiImg] = useState([]);
  const [dfImg, setDfImg] = useState();
  const [videoDetail, setVideoDetail] = useState();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const { state } = useLocation();

  const conferenceId = conf || state?.conferenceId;

  useEffect(() => {
    document.title = "FIG Events | Product";
    if (id) {
      GetProductDetails(id);
      userInView(id);
    }
    // eslint-disable-next-line
  }, [id, deleteInfo]);

  const GetProductDetails = async (id) => {
    let seData = {
      url: LogDetail?.userId
        ? `/api/ExhibitorProduct/GetProductDetailsByExhibitorProductId?exhibitorProductId=${id}&loginUserId=${LogDetail?.userId}`
        : `/api/ExhibitorProduct/GetProductDetailsByExhibitorProductId?exhibitorProductId=${id}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        setVideoDetail({ ...res?.data.payload?.productVideoLink[0], no: 1 });
        setPrDetails({
          ...res?.data.payload,
          productVideoLink: res?.data.payload?.productVideoLink?.filter(
            (x) => x?.documents !== ""
          ),
        });
        if (!res?.data.payload?.productImage) {
          setDfImg(res?.data.payload?.images[0]?.images);
        }
        setMultiImg(res?.data.payload?.images);
      }
    }
  };
  const userInLike = async (p) => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/ExhibitorProduct/CreateProductUserInteraction",
        body: {
          ExhibitorProductId: p,
          userId: LogDetail?.userId,
          conferenceId: conferenceId ? conferenceId : null,
          status: "Like",
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data.success) {
        ToastSuccess(res?.data.message);
        GetProductDetails(id);
      } else {
        ToastError(res?.data?.message);
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can like product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    }
  };
  const userInView = async (p) => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/ExhibitorProduct/CreateProductUserInteraction",
        body: {
          ExhibitorProductId: p,
          userId: LogDetail?.userId,
          conferenceId: conferenceId ? conferenceId : null,
          status: "View",
        },
      };
      setOpenCircle(true);
      await PostCallApi(seData);
      setOpenCircle(false);
      // let res =
      // if (res?.status === 200) {
      //   if (res?.data.success) {
      //   }
      // }
    }
  };
  const userRating = async () => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/ProductRating/CreateProductRating",
        body: {
          ExhibitorProductId: id,
          userId: LogDetail?.userId,
          Rating: rate,
          conferenceId: conferenceId ? conferenceId : null,
          Feedback: feedBackText,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res?.data.success) {
        GetProductDetails(id);
        ToastSuccess(res?.data.message);
        handleCloseCall();
      } else {
        ToastError(res?.data?.message);
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can rate product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_success",
      cancelButton: "btn btn_success",
    },
    buttonsStyling: true,
  });
  const handleOpenCall = (e) => {
    setRate(e);
    setOpenCall(true);
  };
  const handleCloseCall = () => {
    setOpenCall(false);
    setRate();
    setFeedbackText("");
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="product_detail">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="title">Product Details</div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                <div className="left_side">
                  <img
                    src={
                      dfImg
                        ? dfImg
                        : prDetails?.productImage
                        ? prDetails?.productImage
                        : NotFound
                    }
                    className="img-fluid"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NotFound;
                    }}
                    alt=""
                  />
                </div>
                <div className="row mt-4 thumbnail">
                  {prDetails?.productImage ? (
                    <div className="col-sm-3 col-md-2 col-xl-2 col-6 mb-4">
                      <button
                        onClick={() => setDfImg()}
                        style={{ border: "none", background: "transparent" }}
                      >
                        <img
                          src={
                            prDetails?.productImage
                              ? prDetails?.productImage
                              : NotFound
                          }
                          className="img-fluid"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NotFound;
                          }}
                          alt=""
                          // style={{ height: "100px", width: "100px" }}
                        />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {multiImg?.map((allimg, ind) => {
                    return (
                      <div
                        className="col-sm-3 col-md-2 col-xl-2 col-6 mb-4"
                        key={ind}
                      >
                        <button
                          onClick={() => setDfImg(allimg?.images)}
                          style={{
                            border: "none",
                            background: "transparent",
                            boxShadow:
                              "0px 4px 20px 0px rgba(238, 238, 238, 0.5)",
                          }}
                        >
                          <img
                            src={allimg?.images ? allimg?.images : NotFound}
                            className="img-fluid"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NotFound;
                            }}
                            alt=""
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                <div className="product_detail_content">
                  <div className="hed">
                    <div className="info">
                      <h3>{prDetails?.productName}</h3>
                      <div className="rating_box">
                        <Rating
                          emptySymbol={
                            <img src={star_blank} alt="rating_blank" />
                          }
                          fullSymbol={<img src={star_full} alt="rating-full" />}
                          initialRating={prDetails?.productRating}
                          onClick={(e) => handleOpenCall(e)}
                        />
                      </div>
                      <p className="price_tag">
                        {prDetails?.productPrice ? (
                          <>
                            ${prDetails?.productPrice?.price} (
                            {prDetails?.productPrice?.subscriptionType})
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <div className="hover_btn me-2">
                      <Button onClick={() => userInLike(id)}>
                        {prDetails?.isProductLike ? (
                          <GoHeartFill color="#fff" fontSize={25} />
                        ) : (
                          <GoHeart color="#fff" fontSize={25} />
                        )}
                      </Button>
                    </div>

                    <div className="action_btn">
                      <Link
                        to={-1}
                        className="btn_edit d-flex align-items-center text-decoration-none"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                  <div className="desc">
                    <div className="document-file gap-5 mt-3">
                      <div className="row">
                        <p className="doc_title">
                          Company Name :{" "}
                          <span style={{ fontWeight: "400" }}></span>
                          {prDetails?.companyName
                            ? prDetails?.companyName
                            : "NA"}
                        </p>
                        <p className="doc_title">
                          Brand Name :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.brandName ? prDetails?.brandName : "NA"}
                          </span>
                        </p>
                        <p className="doc_title">
                          Category Name :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.categoryName
                              ? prDetails?.categoryName
                              : "NA"}
                          </span>
                        </p>

                        <p className="doc_title">
                          Manufacturer :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.manufacturer
                              ? prDetails?.manufacturer
                              : "NA"}
                          </span>
                        </p>

                        {prDetails?.gtin ? (
                          <p className="doc_title">
                            gtin :{" "}
                            <span style={{ fontWeight: "400" }}>
                              {prDetails?.gtin ? prDetails?.gtin : "NA"}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {prDetails?.categoryName === "Equipment" ||
                        prDetails?.categoryName === "Small Wares" ||
                        prDetails?.categoryName === "Uniforms" ||
                        prDetails?.categoryName === "Software/Technology" ||
                        prDetails?.categoryName === "Consulting" ||
                        prDetails?.categoryName === "Packaging" ||
                        prDetails?.categoryName === "Other" ? (
                          ""
                        ) : (
                          <>
                            {prDetails?.upc ? (
                              <p className="doc_title">
                                upc :{" "}
                                <span style={{ fontWeight: "400" }}>
                                  {prDetails?.upc
                                    ? prDetails?.upc !== "null"
                                      ? prDetails?.upc
                                      : "NA"
                                    : "NA"}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                            {prDetails?.ingredients ? (
                              <p className="doc_long_title">
                                ingredients :{" "}
                                <span style={{ fontWeight: "400" }}>
                                  {prDetails?.ingredients
                                    ? prDetails?.ingredients !== "null"
                                      ? prDetails?.ingredients
                                      : "NA"
                                    : "NA"}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        <p className="doc_long_title">
                          Short Description :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.shortDescription
                              ? prDetails?.shortDescription
                              : "NA"}
                          </span>
                        </p>
                        {prDetails?.documents?.length > 0 ? (
                          <>
                            <p className="multi_document">Documents : </p>
                            {prDetails?.documents?.map((docPth, no) => (
                              <div className="document-box mb-2" key={no}>
                                <div className="text_data">
                                  {docPth.fileName
                                    ? docPth.fileName
                                    : "Product PDF"}
                                </div>
                                <Link
                                  to={`${Config.API_HOST_URL_live}/${docPth?.documents}`}
                                  target="_blank"
                                  className="btn_dlt"
                                >
                                  <img
                                    src={"/assets/icons/lucide_view.svg"}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 benefits mb-3">
                <div className="new_card">
                  <h3 className="card_title m-0">Benefits</h3>
                  <ul className="row">
                    {prDetails?.benefits?.map((item, i) => (
                      <li className="col-lg-3 col-md-6" key={i}>
                        <p>
                          {i + 1}. {item?.benefitTitle}:
                        </p>
                        {item?.benefitDescription}
                      </li>
                    ))}
                    {prDetails?.benefits?.length === 0 ? (
                      <div className="f-20-600 my-4">No Data Found</div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              {prDetails?.productVideoLink?.filter(
                (x) => x?.fileName || x?.documents
              )?.length > 0 ? (
                <div className="col-md-12">
                  <div className="new_card ">
                    <h3 className="card_title">Video</h3>
                    <div className="d-flex gap-3">
                      <div style={{ height: "500px" }} className="w-100">
                        <ReactPlayer
                          url={videoDetail?.documents}
                          className="react-player"
                          volume={1}
                          width="100%"
                          height="100%"
                          controls
                        />
                      </div>
                      <div className="w-100 video_list">
                        {prDetails?.productVideoLink
                          ?.filter((x) => x?.fileName || x?.documents)
                          ?.map((y, i) => (
                            <div
                              className="showmb"
                              onClick={() =>
                                setVideoDetail({ ...y, no: i + 1 })
                              }
                              key={i}
                            >
                              <div className={`name `}>
                                <div
                                  className={` ${
                                    i + 1 === videoDetail?.no ? "Active" : ""
                                  }`}
                                >
                                  {i + 1}. {y?.fileName}
                                </div>
                                <div>
                                  <MdOutlineOndemandVideo
                                    fontSize={24}
                                    color={` ${
                                      y?.speakerDocumentId ===
                                      videoDetail?.speakerDocumentId
                                        ? "#80c242"
                                        : ""
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-12 review-section mt-5">
                <div className="new_card">
                  <h3 className="card_title m-0">Reviews</h3>
                  <div className="row">
                    {prDetails?.productRatingList?.map((x, i) => (
                      <div className="col-lg-6 review-border" key={i}>
                        <div className="review-block">
                          <div className="reviewcontent-block">
                            <div className="image">
                              {x?.attendeePhoto ? (
                                <img
                                  src={`${Config.API_HOST_URL_live}${x?.attendeePhoto}`}
                                  width={40}
                                  height={40}
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = pro_img;
                                  }}
                                  alt="review-img"
                                />
                              ) : (
                                <img
                                  src={pro_img}
                                  alt="review-img"
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              )}
                            </div>
                            <div className="profile-name">
                              <h6>{x?.attendeeName}</h6>
                              <div className="rating_box">
                                <Rating
                                  emptySymbol={
                                    <img src={star_blank} alt="rating_blank" />
                                  }
                                  fullSymbol={
                                    <img src={star_full} alt="rating-full" />
                                  }
                                  initialRating={x?.rating}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                          {LogDetail?.userId === x?.userId ? (
                            <div>
                              <p>
                                {x?.date
                                  ? new Date(x?.date)?.toLocaleDateString(
                                      "en-us",
                                      {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }
                                    )
                                  : ""}
                              </p>
                              <div className="d-flex">
                                <DeletePopup
                                  title={"Delete Product Rating"}
                                  text={"your rating"}
                                  url={`/api/ProductRating/DeleteProductRatingById?productRatingById=${x?.productRatingId}`}
                                  callBack={true}
                                  callBackFunction={() => GetProductDetails(id)}
                                />
                              </div>
                            </div>
                          ) : (
                            <p>
                              {x?.date
                                ? new Date(x?.date)?.toLocaleDateString(
                                    "en-us",
                                    {
                                      year: "2-digit",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }
                                  )
                                : ""}
                            </p>
                          )}
                        </div>
                        <p>{x?.feedBack}</p>
                      </div>
                    ))}
                    {prDetails?.productRatingList?.length === 0 ? (
                      <div className="f-20-600 my-4">No Data Found</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        sx={{ zIndex: 9 }}
        open={openCall}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style1} className="delete_data_box">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openCircle}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="popup_delete_detail_box">
            <h6>Write Feedback</h6>
            <div className="popup_delete_detail">
              <div className="text-center">
                <Rating
                  emptySymbol={<img src={star_blank} alt="" />}
                  fullSymbol={<img src={star_full} alt="" />}
                  initialRating={rate}
                  className="rate_star_mb"
                  onClick={(e) => setRate(e)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">FeedBack</label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter Feedback"
                  value={feedBackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                ></textarea>
              </div>
              <Button
                type="button"
                className="common_btn me-3"
                onClick={userRating}
                disabled={openCircle}
              >
                Submit
              </Button>
              <Button
                type="button"
                className="back_button "
                onClick={handleCloseCall}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ProductDetailPublic;
