import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { PostCallApi } from "../../../../Action/Action";
import "./index.scss";
import { useSelector } from "react-redux";
import { Config } from "../../../../Utils/Config";
import ProductViewCard from "../Card";

const email = "/assets/icons/email-icon.svg";
const call = "/assets/icons/call-icon.svg";
const pro_img = "/assets/images/default_profile.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function ViewUserAnswer() {
  const [userDetails, setUserDetails] = useState();
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    getUserDetailsWithAnswer();
  }, []);
  const getUserDetailsWithAnswer = async () => {
    let ckD = JSON?.parse(sessionStorage?.getItem("viewUserAnswer"));
    let sv = {
      ExhibitorId: LogDetails?.userId,
      userId: ckD?.attendeeId,
      requestAcallUserInformationId: ckD?.requestAcallUserInformationId
        ? ckD?.requestAcallUserInformationId
        : null,
      conferenceId: ckD?.conferenceId ? ckD?.conferenceId : null,
      otherConferenceName: ckD?.conferenceId ? null : ckD?.conferenceName,
      associationMembersId: ckD?.associationMembersId
        ? ckD?.associationMembersId
        : null,
      otherAssociationMembersName: ckD?.associationMembersId
        ? null
        : ckD?.associationMembersName,
    };
    let res = await PostCallApi({
      url: "/api/ExhibitorHall/GetUserDetailsForAttendeeInterestedProductListWithFilterList",
      body: sv,
    });
    if (res?.status === 200) {
      if (res?.data?.success) {
        setUserDetails(res?.data?.payload);
      }
    }
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="ViewUserAnswer_page">
          <div className="main_wrapper">
            <div className="title mb-5">User Details</div>
            <div className="card">
              <div className="card-body">
                <div className="all_show">
                  <div className="profile_div">
                    <div className="profile_pic">
                      <img
                        src={
                          userDetails?.profileImage
                            ? Config.API_HOST_URL_live +
                              userDetails?.profileImage
                            : pro_img
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = pro_img;
                        }}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="user_information">
                    <h4 className="">{userDetails?.userName}</h4>
                    <div className="user_De">
                      <img src={email} alt="Email" />

                      {userDetails?.email}
                    </div>
                    <div className="user_De">
                      <img src={call} alt="call" />
                      {userDetails?.phone}
                    </div>
                  </div>
                  <div className="ms-auto mb-auto">
                    <span className={`status_tag   ${userDetails?.leadStatus}`}>
                      {userDetails?.leadStatus}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Group Name: {userDetails?.associationMemberName}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Conference Name: {userDetails?.conferenceName}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        IsContacted:{" "}
                        {userDetails?.isContacted ? "True" : "False"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {userDetails?.questionList?.length > 0 ? (
              <>
                <div className="title my-3">Question And Answer</div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {userDetails?.questionList[0]?.date ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              <b> Submit Date</b>:{" "}
                              {new Date(
                                userDetails?.questionList[0]?.date
                              )?.toLocaleString("en-us", {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                hour12: true,
                                minute: "2-digit",
                              })}
                            </label>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {userDetails?.questionList?.map((item, index) => (
                        <div className="col-md-6" key={index}>
                          <div className="form-group">
                            <label className="form-label">
                              {index + 1}) {item?.questionName} (
                              {item?.questionType})
                            </label>
                            <div
                              className="answer p-1"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              Answer: {item?.anwser}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="title my-3">Selected Products</div>
            <div className="row">
              <ProductViewCard data={userDetails?.exhibitorProductDetails} />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ViewUserAnswer;
