import React, { Suspense, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi.jsx";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import { GetCallApi, PostCallApi } from "../../../Action/Action.jsx";
import {
  SwalMessage,
  ToastError,
  ToastSuccess,
} from "../../Components/Toast/index.jsx";
import { useForm } from "react-hook-form";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(odd)": {
  backgroundColor: "#ffffff",
  // },
  // "&:nth-of-type(even)": {
  // backgroundColor: "#F1F6FC",
  // },
}));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCells = [
  { id: "id", label: "#" },
  { id: "add", label: "Add" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "companyName", label: "Company Name" },
  { id: "booth", label: "Booth No" },
];

function BoothTeamMemberUpdate() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openCircle1, setOpenCircle1] = useState(false);

  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [deltaPositionSave, setDeltaPositionSave] = useState();
  const [booth, setBooth] = useState();
  const [cmpList, setCmpList] = useState();
  const { handleSubmit, reset, getValues, setValue } = useForm();
  useEffect(() => {
    document.title = "FIG Events | Booth Team Member Update";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const GetBoothRegisterList = async (data) => {
    setDataOfTable1([]);
    if (data?.conferenceId && data?.boothId) {
      setOpenCircle(true);
      let seData = {
        url: `/api/ExhibitorVendor/GetAllExhibitorVendorListWithRegistrationStatus?userId=${logindetail?.userId}&conferenceId=${data?.conferenceId}`,
      };
      let res1 = await GetCallApi(seData);
      let list = [];
      if (res1?.status === 200 && res1?.data.success) {
        let Booth = deltaPositionSave?.find(
          (x) => x.value === data?.boothId
        )?.label;
        list = res1?.data.payload?.map((x) => {
          return {
            ...x,
            ...x.ev,
            isAdded: false,
            booth: Booth,
          };
        });
      }
      let cmp = cmpList?.map((x) => x?.value);
      list = list.filter((item) => cmp?.includes(item?.companyId));

      let send = {
        ...data,
        conferenceId: data?.conferenceId ? data?.conferenceId : null,
        userId: logindetail?.userId,
      };
      let res = await GetCallApi({
        url: `/api/ExhibitorBoothAllocation/GetBoothAssignTeamMemberList?conferenceId=${send?.conferenceId}&boothId=${send?.boothId}`,
      });
      setOpenCircle(false);

      if (res?.status === 200 && res?.data.success) {
        setOpenCircle(false);
        let comList = list?.map((c, i) => {
          let isVendor = res?.data.payload?.payload?.find(
            (x) => x?.venderId === c?.salesRepresentativesId
          );

          if (isVendor) {
            setValue(
              "exhibitorBoothAllocationId",
              isVendor?.exhibitorBoothAllocationId
            );
            return {
              ...c,
              ...isVendor,
              isAdded: true,
              id: i + 1,
            };
          }
          return {
            ...c,
            id: i + 1,
          };
        });
        setDataOfTable1(comList);
      } else {
        ToastError(res?.data?.message);
      }
    } else {
      if (!data?.boothId) {
        SwalMessage("Please select Booth");
      }
      if (!data?.conferenceId) {
        SwalMessage("Please select conference");
      }
    }
  };

  const getBoothList = async (e) => {
    setDeltaPositionSave([]);
    setCmpList([]);
    setDataOfTable1([]);
    setValue("boothId");
    setBooth();
    if (e) {
      let seData = {
        url: `/api/ConferenceBooths/GetConferenceBoothsStatusByConferenceIdWithoutTokenNew?conferenceId=${e}&isApprove=true`,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200 && res?.data.success) {
        let rl = logindetail?.roleId === 1;
        let pn = res?.data.payload
          ?.filter((x) => x?.status === "Approve")
          ?.filter((x) =>
            rl === true ? x : x?.exhibitor?.exhibitorsId === logindetail?.userId
          )
          ?.map((p) => {
            let company = p?.exhibitor?.exhibitorCompanyDetailsNew?.find(
              (x) => x.isFloorPlanDisplayCompany === true
            );
            let compAr = [
              company,
              ...(p?.exhibitor?.exhibitorCompanyDetailsNew?.filter(
                (x) => x.isFloorPlanDisplayCompany === false
              ) ?? []),
            ]?.map((x) => {
              return { ...x, value: x?.companyId, label: x?.companyName };
            });
            return {
              ...p,
              ...p.exhibitor,
              ...p.exhibitor?.exhibitorCompanyDetails,
              companyD: compAr,

              value: p?.eventBoothsId,
              label: p?.seatEventNumber,
            };
          });
        setDeltaPositionSave(pn);
      }
    }
  };

  const handleConfereceSelect = (e) => {
    setConfId(e);
    setValue("conferenceId", e);
    getBoothList(e);
  };

  const handleBooth = (e) => {
    setBooth(e);
    setValue("boothId", e);
    let Company = deltaPositionSave?.find((x) => x.value === e)?.companyD;
    setCmpList(Company);
    setDataOfTable1([]);
  };

  const handleClearFilter = () => {
    setConfId();
    setBooth();
    setDeltaPositionSave([]);
    setCmpList([]);
    setDataOfTable1([]);
    reset();
  };

  const handleCheckMain = (e, us) => {
    let newArr = dataOfTable1.map((obj) => {
      if (obj.salesRepresentativesId === us) {
        return {
          ...obj,
          isAdded: e,
        };
      }
      return obj;
    });
    setDataOfTable1(newArr);
  };
  const SaveBoothTeamMember = async () => {
    setOpenCircle1(true);
    const data = dataOfTable1
      ?.filter((x) => x?.isAdded === true)
      ?.map((obj) => {
        return obj?.salesRepresentativesId;
      });
    let res = await PostCallApi({
      url: `/api/ExhibitorBoothAllocation/UpdateBoothTeamMember`,
      body: {
        boothId: getValues("boothId"),
        exhibitorBoothAllocationId: getValues("exhibitorBoothAllocationId"),
        teamMemberId: data,
        userId: logindetail?.userId,
        createdBy: logindetail?.userId,
      },
    });
    if (res?.status === 200 && res?.data?.success) {
      setOpenCircle1(false);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data.message);
    }
  };
  const selectStyle = {
    control: (base) => ({
      ...base,
      borderColor: "hsl(0, 0%, 80%)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "hsl(0, 0%, 80%)",
      },
    }),
    multiValueRemove: (style, state) => {
      return {
        ...style,
        ...(state.data?.isDisabled
          ? {
              visibility: "hidden",
              width: "4px",
            }
          : {}),
      };
    },
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetBoothRegisterList)}>
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <div className="form-group">
                            <label className="form-label">Conference</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={conList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Conference"
                              filterOption={customFilter}
                              value={conList?.filter(
                                (obj) => obj.value === confId
                              )}
                              onChange={(e) => handleConfereceSelect(e?.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="form-group">
                            <label className="form-label">Booth</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={deltaPositionSave}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Booth"
                              filterOption={customFilter}
                              value={deltaPositionSave?.filter(
                                (obj) => obj.value === booth
                              )}
                              onChange={(e) => handleBooth(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                          <div className="form-group">
                            <label className="form-label">Company</label>
                            <Select
                              isMulti
                              name="colors"
                              // options={diaList}
                              isClearable={false}
                              menuIsOpen={false}
                              // isDisabled={true}
                              styles={selectStyle}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Company"
                              filterOption={customFilter}
                              value={cmpList?.map((x) => {
                                return {
                                  ...x,
                                  isDisabled: true,
                                };
                              })}
                              // onChange={handleChangeDr}
                            />
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-start mt-auto gap-3 align-items-center">
                          <Button
                            className="common_btn"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className="back_button"
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <div className="d-flex justify-content-between new_listdata_control_sec">
                    <h3 className="card_title m-0">Select Team Members</h3>
                    {openCircle1 ? (
                      <Button className="common_btn " disabled>
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      </Button>
                    ) : dataOfTable1?.find((x) => x?.isAdded === true) ? (
                      <Button
                        className="common_btn "
                        onClick={() => SaveBoothTeamMember()}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button className="common_btn " disabled>
                        Submit
                      </Button>
                    )}
                  </div>
                  <div className="new_all_list pb-3">
                    <Paper className="table-box">
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {headCells?.map((headCell) => (
                                <StyledTableCell
                                  key={headCell?.id}
                                  align="left"
                                >
                                  {headCell.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataOfTable1?.map((row, index) => {
                              return (
                                <StyledTableRow key={index}>
                                  {headCells?.map((column) => {
                                    const value = row[column?.id];
                                    if (column?.id === "booth") {
                                      return (
                                        <StyledTableCell
                                          key={column?.id}
                                          className={`column_data 
                                   `}
                                        >
                                          {row?.isAdded && (
                                            <>
                                              {column?.format &&
                                              typeof value === "number"
                                                ? column?.format(value)
                                                : value}
                                            </>
                                          )}
                                        </StyledTableCell>
                                      );
                                    }
                                    if (column?.id === "add") {
                                      return (
                                        <StyledTableCell
                                          key={column?.id}
                                          className={`column_data 
                                   `}
                                        >
                                          <label className=" personal_detail_label">
                                            <input
                                              type="checkbox"
                                              className="login_check"
                                              name="Remember Me"
                                              checked={row?.isAdded}
                                              onChange={(e) =>
                                                handleCheckMain(
                                                  e.target.checked,
                                                  row?.salesRepresentativesId
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </StyledTableCell>
                                      );
                                    }
                                    return (
                                      <StyledTableCell
                                        className={`column_data 
                                   `}
                                        key={column?.id}
                                      >
                                        {column?.format &&
                                        typeof value === "number"
                                          ? column?.format(value)
                                          : value}
                                      </StyledTableCell>
                                    );
                                  })}
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default BoothTeamMemberUpdate;
