import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  DeleteData: false,
  Post_data: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    DeleteDataFor(state, action) {
      state.DeleteData = action.payload;
    },
    CreatePost(state, action) {
      state.Post_data = action.payload;
    },
    AttendeeRegistrationConference(state, action) {
      state.ATRC = action.payload;
    },
    BlockPostCategory(state, action) {
      state.BlockPostCategoryList = action.payload;
    },
    resetCommon: () => initialState,
  },
});

export const {
  DeleteDataFor,
  CreatePost,
  AttendeeRegistrationConference,
  BlockPostCategory,
  resetCommon,
} = commonSlice.actions;

export default commonSlice.reducer;
