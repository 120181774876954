import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { MdAdd, MdDelete } from "react-icons/md";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../../../Components/Toast";

function QualifyingQuestionforNonFigEventsCreate() {
  const [openCircle, setOpenCircle] = useState(false);
  const [questionType, setQuestionType] = useState(false);
  const [questionTypeVal, setQuestionTypeVal] = useState();
  const [SPKD, setSPKD] = useState(false);
  const [rateingQuestion, setRateingQuestion] = useState(false);
  const [questionCategory, setQuestionCategory] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState();

  const { id } = useParams();
  const nav = useNavigate();
  const LogDetails = useSelector((state) => state?.login?.LoginDetails);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "optionList",
  });

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/Questions/CreateQuestion`,
      body: {
        ...data,
        questionCategory:
          LogDetails?.roleId === 1 ? data?.questionCategory : "Request a Call",
        createdBy: LogDetails?.userId,
        userId: LogDetails?.userId,
        questionType: data?.isSpeakerDropDown ? "Listbox" : data?.questionType,
      },
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data?.success) {
      nav(-1);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data?.message);
    }
  };

  useEffect(() => {
    if (id) {
      getDataById();
    } else {
      GetCompanyRegistrationList();
    }
  }, [id]);

  const GetCompanyRegistrationList = async (sv) => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=Industry&userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.associationMembersId,
          label: c?.name,
        };
      });
      setCompanyList(comList);
      if (sv?.companyId) {
        setTimeout(() => {
          handleCompany(sv?.companyId);
        }, 500);
      }
    }
  };

  const getDataById = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/Questions/GetQuestionByQuestionId?questionId=${id}`,
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data?.success) {
      let sv = res?.data?.payload;
      setValue("questionId", id);
      setValue("questionTitle", sv?.questionTitle);
      setValue("isRequired", sv?.isRequired);
      setValue("isSpeakerDropDown", sv?.isSpeakerDropDown);
      setSPKD(sv?.isSpeakerDropDown);
      setValue("questionType", sv?.questionType);
      setQuestionTypeVal(sv?.questionType);
      setValue("questionCategory", sv?.questionCategory);
      setQuestionCategory(sv?.questionCategory);
      setQuestionType(
        sv?.questionType === "Listbox" ||
          sv?.questionType === "Multiple Choice" ||
          sv?.questionType === "Radio"
      );
      setRateingQuestion(sv?.questionType === "Rating");
      setValue(
        "optionList",
        sv?.optionList?.map((x) => {
          return { option: x?.option };
        })
      );
      GetCompanyRegistrationList(sv);
    } else {
      ToastError(res?.data.message);
    }
  };

  const handleQuestionType = (e, val) => {
    setQuestionType(e);

    setValue("optionList", []);
    setValue("questionType", val);
    setQuestionTypeVal(val);
    setRateingQuestion(false);
    if (val === "Rating") {
      setRateingQuestion(true);
      append({ option: "" });
      append({ option: "" });
    }
    if (e) {
      append({ option: "" });
    }
  };

  const handleSpeaker = (e) => {
    setSPKD(e);
    handleQuestionType(false);
    if (e) {
      setValue("questionType", "");
    }
  };

  const questionTypeOption = [
    { value: "BigText", label: "BigText" },
    { value: "Listbox", label: "Listbox" },
    { value: "Multiple Choice", label: "Multiple Choice" },
    { value: "Radio", label: "Radio" },
    { value: "Rating", label: "Rating" },
    { value: "Text", label: "Text" },
  ];

  const questionCategoryOption = [
    { value: "Conference Question", label: "Conference Question" },
    { value: "Request a Call", label: "Request a Call" },
  ];

  const handleQuestionCategory = (e) => {
    setValue("questionCategory", e);
    setQuestionCategory(e);
  };

  const handleCompany = (e) => {
    setValue("CompanyId", e);
    setCompanyId(e);
  };

  return (
    <>
      {" "}
      <section className="QualifyingQuestionforNonFigEventsCreate">
        <div className="main_wrapper">
          <div className="title mb-3">
            {id ? "Edit" : "Create"} Qualifying Question
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Question Title
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Question Title"
                        {...register("questionTitle", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 300,
                            message: "Max character 300 allowed",
                          },
                        })}
                      />
                      {errors.questionTitle && (
                        <span role="alert" className="error_text">
                          {errors.questionTitle.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {LogDetails?.roleId === 1 ? (
                    <>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Question Category
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={questionCategoryOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Question Category"
                            filterOption={customFilter}
                            value={questionCategoryOption?.filter(
                              (obj) => obj.value === questionCategory
                            )}
                            onChange={(e) => handleQuestionCategory(e?.value)}
                          />
                          <select
                            className="form-select d-none"
                            {...register("questionCategory", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Question Category</option>
                            {questionCategoryOption?.map((x) => (
                              <option value={x?.value} key={x?.value}>
                                {x?.label}
                              </option>
                            ))}
                          </select>
                          {errors.questionCategory && (
                            <span role="alert" className="error_text">
                              {errors.questionCategory.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={`col-md-2`}>
                        <div className="form-group">
                          <label className="form-label">
                            Is Speaker DropDown
                            {/* <span className="red-color"> *</span> */}
                          </label>
                          <label className="personal_detail_label">
                            <input
                              type="checkbox"
                              className="login_check"
                              name="isSpeakerDropDown"
                              {...register("isSpeakerDropDown", {
                                validate: (val) => {
                                  if (
                                    watch("questionCategory") ===
                                      "Request a Call" &&
                                    val === true
                                  ) {
                                    return "IsSpeakerDropDown allowed only in conference";
                                  }
                                },
                                onChange: (e) =>
                                  handleSpeaker(e.target.checked),
                              })}
                            />
                            <span className="checkmark"></span>
                          </label>

                          {errors.isSpeakerDropDown && (
                            <div style={{ marginLeft: "2rem" }}>
                              <span role="alert" className="error_text ">
                                {errors.isSpeakerDropDown.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className={`col-md-${LogDetails?.roleId === 1 ? 2 : 6}`}>
                    <div className="form-group">
                      <label className="form-label">
                        Is Required
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <label className="personal_detail_label">
                        <input
                          type="checkbox"
                          className="login_check"
                          name="isRequired"
                          {...register("isRequired")}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {errors.isRequired && (
                        <span role="alert" className="error_text">
                          {errors.isRequired.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {watch("questionCategory") !== "Conference Question" ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Industry/Company
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={companyList}
                          className="company-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Industry/Company"
                          filterOption={customFilter}
                          value={companyList?.filter(
                            (obj) => companyId === obj.value
                          )}
                          onChange={(e) => handleCompany(e?.value)}
                        />
                        <select
                          className="form-select d-none"
                          {...register("CompanyId", {
                            required: "This field is required",
                            onChange: (e) => handleCompany(e.target.value),
                          })}
                        >
                          <option value="">Select Company</option>
                          {companyList &&
                            companyList?.map((com, i) => (
                              <option value={com.value} key={i}>
                                {com?.label}
                              </option>
                            ))}
                        </select>
                        {errors.CompanyId && (
                          <span role="alert" className="error_text">
                            {errors.CompanyId.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!SPKD ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Question Type
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti={false}
                          name="colors"
                          options={questionTypeOption}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Question Type"
                          filterOption={customFilter}
                          value={questionTypeOption?.filter(
                            (obj) => obj.value === questionTypeVal
                          )}
                          onChange={(e) =>
                            handleQuestionType(
                              e?.value === "Listbox" ||
                                e?.value === "Multiple Choice" ||
                                e?.value === "Radio"
                                ? true
                                : false,
                              e?.value
                            )
                          }
                        />
                        <select
                          className="form-select d-none"
                          {...register("questionType", {
                            required: "This field is required",
                            onChange: (e) =>
                              handleQuestionType(
                                e?.target.value === "Listbox" ||
                                  e?.target.value === "Multiple Choice" ||
                                  e?.target.value === "Radio"
                                  ? true
                                  : false,
                                e?.target.value
                              ),
                          })}
                        >
                          <option value="">Select Question Type </option>
                          <option value="BigText">BigText </option>
                          <option value="Listbox">Listbox</option>
                          <option value="Multiple Choice">
                            {" "}
                            Multiple Choice{" "}
                          </option>
                          <option value="Radio">Radio</option>
                          <option value="Rating">Rating</option>
                          <option value="Text">Text </option>
                          {/* <option value="True/False">True/False</option> */}
                        </select>
                        {errors.questionType && (
                          <span role="alert" className="error_text">
                            {errors.questionType.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {questionType
                    ? fields?.map((_, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-6 ">
                            <div className="form-group">
                              <div className="d-flex justify-content-between">
                                <label className="form-label">
                                  Option {index + 1}
                                  <span className="red-color"> *</span>
                                </label>
                                {index === 0 ? (
                                  <Button
                                    sx={{
                                      background:
                                        "linear-gradient(86.33deg, #18bad4 1.51%, #31b680 52.02%, #80c242 99.48%)",
                                      color: "white",
                                      padding: "0.2rem 0.4rem",
                                      borderRadius: "5px",
                                      height: "fit-content",
                                    }}
                                    onClick={() => append({ option: "" })}
                                  >
                                    {/* <MdAdd /> */}
                                    <MdAdd fontSize={20} />
                                  </Button>
                                ) : (
                                  <Button
                                    sx={{
                                      backgroundColor: "red",
                                      color: "white",
                                      padding: "0.2rem 0.4rem",
                                      borderRadius: "5px",
                                      height: "fit-content",
                                      "&:hover": {
                                        backgroundColor: "red",
                                      },
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <MdDelete fontSize={24} />
                                  </Button>
                                )}
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                {...register(`optionList.${index}.option`, {
                                  required: "this field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  maxLength: {
                                    value: 200,
                                    message: "Max 200 characters",
                                  },
                                })}
                              />
                              {errors.optionList?.[index]?.option && (
                                <span role="alert" className="error_text">
                                  {errors.optionList?.[index]?.option.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    : ""}
                  {rateingQuestion ? (
                    <>
                      <div className="col-md-6"></div>
                      {fields?.map((_, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-5 ">
                            <div className="form-group">
                              <div className="d-flex justify-content-between">
                                {/* <label className="form-label">
                                  Option {index + 1}
                                  <span className="red-color"> *</span>
                                </label> */}
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                {...register(`optionList.${index}.option`, {
                                  required: "this field is required",
                                  pattern: {
                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                    message:
                                      "Starting and Ending Space not allowed",
                                  },
                                  maxLength: {
                                    value: 200,
                                    message: "Max 200 characters",
                                  },
                                })}
                              />
                              {errors.optionList?.[index]?.option && (
                                <span role="alert" className="error_text">
                                  {errors.optionList?.[index]?.option.message}
                                </span>
                              )}
                            </div>
                          </div>
                          {index === 0 ? (
                            <div className="col-md-2">
                              <div className="d-flex gap-5">
                                {[...Array(5).keys()]?.map((x) => (
                                  <div key={x}>
                                    <label
                                      htmlFor={`TrueFalse${x}`}
                                      className="w-100"
                                    >
                                      {x + 1}
                                    </label>
                                    <input
                                      type="radio"
                                      id={`TrueFalse${x}`}
                                      className=""
                                      name="TrueFalse"
                                      disabled
                                      value={x + 1}
                                    />{" "}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Back
                      </Button>
                      <Button
                        className="common_btn ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QualifyingQuestionforNonFigEventsCreate;
