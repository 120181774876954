import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import Axios from "../../../Utils/AxiosConfig";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import DeletePopup from "../../Components/DeletePopup";
import { useSelector } from "react-redux";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsSession = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "templateTitle",
    label: "Template Title",
    widthFix: true,
  },
  {
    id: "subject",
    label: "Subject",
    widthFix: true,
  },
  // {
  //   id: "notificationType",
  //   label: "Notification Type",
  // },
];

const TemplateList = () => {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  useEffect(() => {
    GetTemplateList();
  }, [deleteInfo]);

  const GetTemplateList = () => {
    setOpenCircle(true);
    Axios.get(`/api/Template/GetTemplateList`)
      .then((res) => {
        setOpenCircle(false);
        if (res?.status === 200) {
          if (res?.data.success) {
            let comList = res?.data?.payload?.map((c, i) => {
              return {
                no: i + 1,
                ...c,
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-3"
                      to={`edit/${c?.templateId}`}
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <VscEdit />
                    </Link>
                    <DeletePopup
                      title={"Delete Template"}
                      text={c?.templateTitle}
                      url={`/api/Template/DeleteTemplateById?emailTemplateId=${c?.templateId}`}
                    />
                  </>
                ),
              };
            });
            setDataOfTable1(comList);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setOpenCircle(false);
      });
  };

  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  headCells={headCellsSession}
                  title="Template List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
};

export default TemplateList;
