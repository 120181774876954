import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import ImgPopUp from "../../../../Components/ImgPopUp";
import { Config } from "../../../../../Utils/Config";
import { Link, useLocation } from "react-router-dom";

const NotFound = "/assets/images/Image_not_available.jpg";

const NewLIstTable = lazy(() => import("../../../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "phone",
    label: "Phone",
  },
  {
    id: "email",
    label: "Email",
  },
  // {
  //   id: "planExpiryDate",
  //   label: "Plan Expiry Date",
  // },
];
function UserAssociation() {
  const [openCircle, setOpenCircle] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);
  const Logdetails = useSelector((state) => state.login.LoginDetails);
  const { pathname } = useLocation();
  let name = pathname.split("/")?.length;

  useEffect(() => {
    document.title =
      name > 2
        ? `FIG Events | Group Association Member (Company) Registration`
        : `FIG Events | Group Association Registration`;
    getAssoList();
    // eslint-disable-next-line
  }, []);
  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListByUserId?userId=${Logdetails?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let data = res?.data.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
            logo: x.logo ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${x.logo}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              <ImgPopUp img={NotFound} addClass={"h_fix"} hide_icon={true} />
            ),
            planExpiryDate: x?.planExpiryDate
              ? new Date(x?.planExpiryDate).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            Action: (
              // x?.isAlreadyPay ? (
              //   ""
              // ) :
              <Link
                to={`pay/${x.associationId}`}
                className="btn_edit me-3 d-flex align-items-center"
              >
                Pay Now
              </Link>
            ),
          };
        });
        setDataOfTable(data);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="user_association_page">
          <div className="main_wrapper">
            <div className="new_card">
              <NewLIstTable
                headCells={headCells}
                title="Association List"
                action={true}
                ListData={dataOfTable}
                dropdown={false}
                addBtn={false}
              />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UserAssociation;
