import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Box, CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import { useParams } from "react-router-dom";

const CreateConference = lazy(() => import("./CreateConference"));
const FloorDetailEvent = lazy(() => import("./FloorDetail"));
const FloorPlanEdit = lazy(() => import("../FloorPlanEdit"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ConferenceSetupProcess() {
  const [eventId, setEventId] = useState("");
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEventId(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          // <EventCreate
          //   handleNext={() => handleNext()}
          //   eventId={eventId}
          //   setEventId={setEventId}
          // />
          <CreateConference
            handleNext={() => handleNext()}
            eventId={eventId}
            setEventId={setEventId}
          />
        );
      case 1:
        return (
          <FloorDetailEvent
            eventId={eventId}
            handleNext={() => handleNext()}
            handlePrev={() => handlePrev()}
          />
        );
      case 2:
        return <FloorPlanEdit eventId={eventId} />;
      default:
        return "";
    }
  };

  const steps = ["Create Event", "Floor Detail", "Floor Plan Setup"];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="eventSetupProcss_dashboard py-4">
        <Box sx={{ width: "100%" }}>
          <div className="main_wrapper mb-3">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="stepper_box_label"
            >
              {steps.map((label, index) => (
                <Step key={label} className={`name${index}`}>
                  <StepLabel>
                    <div
                      className={`step_label_container ${
                        activeStep === index ? "active_step" : ""
                      }`}
                    >
                      <div className="step_number">{`0${index + 1}`}</div>
                      <div className="step_label">{label}</div>
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {/* <hr /> */}
          {getStepContent(activeStep)}
        </Box>
      </section>
    </Suspense>
  );
}

export default ConferenceSetupProcess;
