import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import "./index.scss";
import { CircularProgress } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../../../Action/Action";
import { ToastError, ToastSuccess } from "../../../../../../Components/Toast";
function AssociationProductDiscountCreate() {
  const { id, prdId } = useParams();
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();

  useEffect(() => {
    if (id) {
      getDiscountData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const getDiscountData = async () => {
    let seData = {
      url: `/api/ProductPromoCode/GetProductPromoCodeByProductPromoCodeId?productPromoCodeId=${id}`,
    };

    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200 && res?.data.success) {
      let useDe = res?.data.payload;
      setValue("productPromoCodeId", id);
      setValue("productPromoCode", useDe?.productPromoCode1);
      setValue(
        "startDate",
        useDe?.startDate ? useDe?.startDate?.split("T")[0] : ""
      );
      setValue("endDate", useDe?.endDate ? useDe?.endDate?.split("T")[0] : "");
      setValue("promoType", useDe?.promoType);
      setValue("promoValue", useDe?.promoValue);
      setValue("minValue", useDe?.minValue);
      setValue("promoTitle", useDe?.promoTitle);
      setValue("teamsCondition", useDe?.teamsCondition);
    }
  };
  const onSubmit = async (data) => {
    let Menu = {
      ...data,
      CreatedBy: logDetail?.userId,
      promoValue: parseFloat(data?.promoValue),
      minValue: parseFloat(data?.minValue),
      ProductId: prdId,
      // associationProductId: prdId,
    };
    let seData = {
      url: "/api/ProductPromoCode/CreateProductPromoCode",
      body: Menu,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200 && res?.data.success) {
      ToastSuccess(res?.data.message);
      reset();
      nav(-1);
      setDerror({ err: "" });
    } else {
      setDerror({ err: res?.data?.message });
      ToastError(res?.data.message);
    }
  };
  let minDate = new Date()
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

  return (
    <section className="create_discount">
      <div className="main_wrapper">
        <div className="title mb-3">
          {id ? "Edit" : "Create"} Product Discount{" "}
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form_box">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Discount Title
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Discount Title"
                      {...register("promoTitle", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                      })}
                    />
                    {errors.promoTitle && (
                      <span role="alert" className="error_text">
                        {errors.promoTitle.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Discount Code
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Discount Code"
                      {...register("productPromoCode", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                      })}
                    />
                    {errors.productPromoCode && (
                      <span role="alert" className="error_text">
                        {errors.productPromoCode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Discount Type
                      <span className="red-color"> *</span>
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      {...register(`promoType`, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        // onChange: (e) => handleSubmenu(e.target.value),
                      })}
                    >
                      <option value="Percentage">Percentage</option>
                      <option value="Amount">Amount</option>
                    </select>
                    {errors.promoType && (
                      <span role="alert" className="error_text">
                        {errors.promoType.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Discount Value
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Discount Value"
                      {...register(`promoValue`, {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        validate: (value) => {
                          if (watch("promoType") === "Percentage") {
                            if (value > 100) {
                              return "Discount Percentage cannot greater than 100";
                            }
                          }
                        },
                      })}
                    ></input>
                    {errors.promoValue && (
                      <span role="alert" className="error_text">
                        {errors.promoValue.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Min Value
                      <span className="red-color"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Min Value"
                      {...register("minValue", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s]+(\s+[^\s]+)*$/,
                          message: "Starting and Ending Space not allowed",
                        },
                        validate: (value) => {
                          if (watch("promoType") !== "Percentage") {
                            if (watch("promoValue") > value) {
                              return "Discount Value cannot greater than Min Value";
                            }
                          }
                        },
                      })}
                    />
                    {errors.minValue && (
                      <span role="alert" className="error_text">
                        {errors.minValue.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      Start Date
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="Date"
                      className="form-control"
                      min={id ? "" : minDate}
                      {...register("startDate", {
                        required: "This field is required",
                        valueAsDate: true,
                      })}
                    />
                    {errors.startDate && (
                      <span role="alert" className="error_text">
                        {errors.startDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">
                      End Date
                      <span className="red-color"> *</span>
                    </label>

                    <input
                      type="Date"
                      className="form-control"
                      min={id ? "" : minDate}
                      {...register("endDate", {
                        required: "This field is required",
                        valueAsDate: true,
                        validate: (val) => {
                          if (watch("startDate") > val) {
                            return "Your end date cannot be earlier than the start date";
                          }
                        },
                      })}
                    />
                    {errors.endDate && (
                      <span role="alert" className="error_text">
                        {errors.endDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Terms & Condition</label>
                    <textarea
                      rows="5"
                      type="text"
                      className="form-control"
                      placeholder="Enter Terms & Condition"
                      {...register("teamsCondition")}
                    />
                    {errors.teamsCondition && (
                      <span role="alert" className="error_text">
                        {errors.teamsCondition.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-3">
                  <div className="form-group">
                    <Button className="back_button" onClick={() => nav(-1)}>
                      Back
                    </Button>
                    <Button className="common_btn ms-3" type="submit">
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : id ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>

                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AssociationProductDiscountCreate;
