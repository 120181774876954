import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
// import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VscEdit } from "react-icons/vsc";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import ImgPopup from "../../../Components/ImgPopUp";
import { Config } from "../../../../Utils/Config";

const NewLIstTable = lazy(() => import("../../../Components/NewLIstTable"));
const Togglebutton = lazy(() => import("../../../Components/Togglebutton"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsSession = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "sequenceNo",
    label: "SequenceNo",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "type",
    label: "Type",
  },
];

function EventPromotionList() {
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    GetEPList();
    document.title = "FIG Events | Event Promotion";
    // eslint-disable-next-line
  }, []);

  const GetEPList = async () => {
    setDataOfTable1([]);
    setOpenCircle(true);
    let seData = {
      url: `/api/EventPromotion/GetAllEventPromotionList`,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            no: i + 1,
            ...c,
            image: c?.eventImageUrl ? (
              <ImgPopup
                img={`${Config.API_HOST_URL_live}${c.eventImageUrl}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <>
                {c?.isActive && (
                  <Link
                    type="button"
                    className="btn_edit me-3"
                    to={`edit/${c?.eventPromotionId}`}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VscEdit />
                  </Link>
                )}
                <Togglebutton
                  checked={c?.isActive === true ? true : false}
                  title={"Delete Event Promotion"}
                  disabled={false}
                  text={""}
                  url={`/api/EventPromotion/DeleteEventPromotion?eventPromotionId=${c?.eventPromotionId}&deletedBy=${LogDetail?.userId}`}
                  callBack={true}
                  callBackFunction={GetEPList}
                />
              </>
            ),
          };
        });
        setDataOfTable1(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <NewLIstTable
                  addressLink={`create`}
                  headCells={headCellsSession}
                  title="Event Promotion List"
                  action={true}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default EventPromotionList;
