
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
// import storage from 'redux-persist/lib/storage';
import createIDBStorage from 'redux-persist-indexeddb-storage';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import menuReducer from "../reducers/menu"
import loginReducer from "../reducers/login"
import CommonReducer from '../reducers/Common';
import CartReducer from '../reducers/Cart';
import AssociationMemberReducer from '../reducers/AssociationMember';

const idbStorage = createIDBStorage('FIGEventIndexedDB');

const reducers = combineReducers({
    login: loginReducer,
    headerMenu: menuReducer,
    Common: CommonReducer,
    Cart: CartReducer,
    AssociationMember: AssociationMemberReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined; // Reset state to clear everything
    }
    return reducers(state, action);
};


const persistConfig = {
    key: 'root',
    storage: idbStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

const persistor = persistStore(store);
export { persistor, store }
