import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
import { Box, CircularProgress, Modal } from "@mui/material";
import { BlockPostCategory } from "../../reducers/Common";
import { useDispatch } from "react-redux";
import { GetCallApi } from "../../Action/Action";

const all_icon = "/assets/images/all_icon.png";
const CommunityFeedNew = lazy(() => import("./CommunityFeedNew"));
const MemberList = lazy(() => import("./CommunityFeedNew/MemberList"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function NewCommunity() {
  const grpId = {
    value: "1",
    label: "All",
    img: all_icon,
  };

  const searchField = "";
  const [viewMemberList, SetViewMemberList] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const handleClose = () => {
    SetViewMemberList(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    GetAllCategoryList();
  }, []);
  const GetAllCategoryList = async () => {
    let seData = {
      url: `/api/BlockPostCategory/GetAllBlockPostCategoryList`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        let comList = res?.data?.payload
          ?.filter((x) => x?.isActive === true)
          ?.map((c, i) => {
            return {
              id: i + 1,
              ...c,
              value: c?.blockPostCategoryName,
              label: c?.blockPostCategoryName,
            };
          });
        dispatch(BlockPostCategory(comList));
      }
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="new_community_page main_backcolor">
          <div className="main_wrapper">
            <CommunityFeedNew searchField={searchField} />
          </div>
        </section>
      </Suspense>
      {viewMemberList && (
        <Modal
          sx={{ zIndex: 9 }}
          open={viewMemberList}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_member_list"
        >
          <Box sx={style} className={`popup_wh col-xl-4 col-lg-4 col-md-12`}>
            <MemberList
              comGroupId={
                grpId?.value === "1" || grpId?.value === "3"
                  ? "00000000-0000-0000-0000-000000000000"
                  : grpId?.value
              }
            />
          </Box>
        </Modal>
      )}
    </>
  );
}
export default React.memo(NewCommunity);
