import Axios from "../Utils/AxiosConfig";

export const GetCallApi = async (data) => {
  try {
    const response = await Axios.get(data?.url);
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const PostCallApi = async (data) => {
  try {
    const response = await Axios.post(data?.url, data.body, {
      headers: data?.headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const DeleteCallApi = async (data) => {
  try {
    const response = await Axios.delete(data?.url);
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const fetchWithRetry = async (
  apiFunction,
  params,
  retries = 3,
  delay = 5000
) => {
  try {
    const response = await apiFunction(...params);
    return response; // Return the response if successful
  } catch (error) {
    if (retries > 0) {
      // Wait for the specified delay before retrying
      await new Promise((resolve) => setTimeout(resolve, delay));
      return fetchWithRetry(apiFunction, params, retries - 1, delay); // Retry
    }
    throw error; // Throw error if out of retries
  }
};
