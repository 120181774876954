const productData = [
    {
        id: "figk-12",
        logo: "/assets/icons/figk-12.svg",
        title: "FIG K-12",
        description:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System.",
        effectImg: "/assets/images/nutrition.jpg",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
    {
        id: "FIGEvents",
        logo: "/assets/icons/FIGEvents.svg",
        title: "FIG Events",
        description:
            "FIG Events is a premier event planning company specializing in corporate events, weddings, and social gatherings. With a focus on creativity, precision, and excellence, we bring your vision to life, ensuring every detail is flawlessly executed. Experience unforgettable events with us.",
        effectImg: "/assets/images/figeventeffect.jpg",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
    {
        id: "FIGPOS",
        logo: "/assets/icons/pos.svg",
        title: "FIG POS",
        description:
            "FIG POS is a robust School Cafeteria POS System designed to streamline meal counting, labor tracking, and staff scheduling. It simplifies cafeteria operations, ensuring accurate meal counts, efficient labor management, and optimized staff hour schedules for seamless service.",
        effectImg: "/assets/images/posCashier.jpeg",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
    {
        id: "FIGFacts",
        logo: "/assets/icons/FIGFacts.svg",
        title: "FIG Facts",
        description:
            "FIG Facts provides detailed product and ingredient information for school nutrition programs. Our system ensures transparency by offering comprehensive facts about food items, helping schools maintain high nutritional standards and keeping parents informed about their children's meals.",
        effectImg: "/assets/images/nutrition.jpg",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
    {
        id: "FIG Collections",
        logo: "/assets/icons/FIGCollections.svg",
        title: "FIG Collections",
        description:
            "FIG Collection streamlines student money collection for school nutrition programs. We simplify payments by sending email and SMS reminders to parents, ensuring timely and efficient collection. Our service enhances accountability and supports better nutrition for students.",
        effectImg: "/assets/images/FIGCollections.png",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
    {
        id: "FIGCommunications",
        logo: "/assets/icons/FIGEvolution.svg",
        title: "FIG Communications",
        description:
            "FIG Communications enables schools to efficiently send emails and SMS for income surveys, payment queries, and other important notifications. Our platform ensures timely and effective communication, keeping parents informed and engaged with school updates and requirements.",
        effectImg: "/assets/images/FIGEvolution.jpg",
        innerTitle: "Experience the Future with FIGK-12",
        innerDescription:
            "At FIG K12, we're dedicated to empowering school nutrition professionals with a state-of-the-art Learning Management System. Tailored to meet the stringent professional development standards mandated by the USDA, our platform is more than just a learning tool; it's a stepping stone to excellence in school nutrition.",
        InnerLogos: [
            { img: '/assets/icons/ourCustomer.svg' },
            { img: '/assets/icons/vulcan.svg' },
            { img: '/assets/icons/proteam.svg' },
            { img: '/assets/icons/educationalSnacks.svg' },
        ],
    },
]

export default productData