import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { DeleteDataFor } from "../../../reducers/Common";
import { VscTrash } from "react-icons/vsc";
import { ToastError, ToastSuccess } from "../Toast";
import { DeleteCallApi } from "../../../Action/Action";

export default function DeletePopup(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const dispatch = useDispatch();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [derror, setDerror] = useState();

  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDerror({ err: "" });
    setOpen(false);
    setOpenCircle(false);
  };
  const handledelete = async () => {
    setOpenCircle(true);
    let responce = await DeleteCallApi({ url: props.url });
    setOpen(false);
    setOpenCircle(false);
    if (responce?.status === 200 && responce.data.success) {
      setOpenCircle(false);
      if (props?.callBack) {
        props?.callBackFunction();
      } else {
        dispatch(DeleteDataFor(deleteInfo ? false : true));
      }
      handleClose();

      ToastSuccess(responce.data.message);
    } else {
      ToastError(responce.data.message);
    }
  };

  return (
    <>
      {props.addBtnmodifyColor ? (
        <Link
          className="btn_dlt show_delete_color d-flex align-items-center"
          onClick={handleOpen}
        >
          <VscTrash className="icon" />
        </Link>
      ) : (
        <Link
          className="btn_dlt d-flex align-items-center"
          onClick={handleOpen}
        >
          <VscTrash className="icon" />
        </Link>
      )}
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {open && (
        <>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
            sx={{ zIndex: 9 }}
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>{props?.title}</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {props?.text}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handledelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
