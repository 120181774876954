import React, { useEffect, useState } from "react";
import "./index.scss";
import { Box, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { customFilter } from "../Components/CustomFilterForReactSelect";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import Select from "react-select";
import { SwalMessage, ToastError } from "../Components/Toast";
import { useReactToPrint } from "react-to-print";
import NewPaginations from "../Components/NewPaginations/Pagination";
import { Config } from "../../Utils/Config";
import { useLocation } from "react-router-dom";
import {
  IndustryDrop,
  LeaDrop,
  StateAgencyDrop,
} from "../Components/AssociationMember";
// const logo = "/assets/images/ohio_logo_School.png";
const fig_events = "/assets/images/fig_events.png";

function MYQRCodes() {
  // const qrRef = useRef();
  const { state } = useLocation();

  const [qrSave] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [confDetail, setConfDetail] = useState();
  const [usDetails, setUsDetails] = useState();
  // const [userId, setUserId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchField, setSearchField] = useState("");
  const [selectLea, setSelectLea] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [selectIntry, setSelectIntry] = useState();
  const [callApi, setCallApi] = useState(false);

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  useEffect(() => {
    document.title = "FIG Events | My QR Codes";
    getConfList(LogDetail?.userId);
    // setUserId(LogDetail?.userId);
  }, []);
  useEffect(() => {
    if (conList?.length > 0 && state) {
      handleChangeConference(state?.conferenceId, true);
    }
  }, [conList, state]);
  useEffect(() => {
    if (confId && callApi && state) {
      GetUserQRDetail(state?.userName);
      setCallApi(false);
    }
  }, [confId, callApi, state]);

  const getConfList = async (uId) => {
    setConList([]);
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${uId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let co = res?.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.conferenceId,
          label: x?.conferenceName,
        };
      });
      setConList(co);
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIntry(e?.value);
  };

  const handleChangeConference = (e, val) => {
    setConfId(e);
    if (e) {
      let conf = conList?.find((obj) => obj.value === e);
      setConfDetail(conf);
      if (val) {
        setCallApi(true);
      }
    } else {
      setConfDetail();
    }
  };

  const GetUserQRDetail = async (nameSearch) => {
    setSearchField("");
    setCurrentPage(1);
    setUsDetails([]);

    let member = [];
    if (selectLea?.length > 0) {
      member = [...selectLea];
    }
    if (selectAgency?.length > 0) {
      member = [...member, ...selectAgency];
    }
    if (confId) {
      setOpenCircle(true);
      // ?userId=${uId}&conferenceId=${
      //   e ? e : ""
      // }`
      let res = await PostCallApi({
        url: `/api/UserMst/GetUserQRCodeString`,
        body: {
          userId: LogDetail?.userId,
          conferenceId: confId,
          companyId: selectIntry ?? null,
          associationMemberId: member?.length > 0 ? member : null,
        },
      });
      setOpenCircle(false);
      if (res?.status === 200 && res?.data?.success) {
        let usd = res?.data?.payload;
        setUsDetails(usd);
        if (nameSearch) {
          setTimeout(() => {
            setSearchField(nameSearch);
          }, 1000);
        }
      } else {
        ToastError(res?.data?.message);
      }
    } else {
      SwalMessage("Please Select conference");
      setUsDetails();
    }
  };

  const HandleClearFilter = () => {
    setConfId();
    setConfDetail();
    setSelectIntry([]);
    setSelectAgency([]);
    setSelectLea([]);
  };

  const adjustBadgeHeight = () => {
    const badgesElements = componentRef.current.querySelectorAll(".badges");
    badgesElements.forEach((badge, index) => {
      // if (index !== 0) {
      if (index % 6 === 0 && index + 1 < badgesElements.length) {
        const nextBadgeHeight = badgesElements[index + 1].offsetHeight;
        console.log(nextBadgeHeight, badgesElements[index].offsetHeight);
        // badge.style.height = `${nextBadgeHeight}px`;

        badge.style.height = `${nextBadgeHeight + 25}px`;
      }
      // }
    });
  };
  const adjustBadgeHeightAfterPrint = () => {
    const badgesElements = componentRef.current.querySelectorAll(".badges");
    badgesElements.forEach((badge, index) => {
      // if (index !== 0) {
      if (index % 6 === 0 && index + 1 < badgesElements.length) {
        const nextBadgeHeight = badgesElements[index + 1].offsetHeight;
        console.log(nextBadgeHeight, badgesElements[index].offsetHeight);
        badge.style.height = `${nextBadgeHeight - 25}px`;
        // badge.style.height = `${nextBadgeHeight + 20}px`;
      }
      // }
    });
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    removeAfterPrint: true,
    onBeforeGetContent: adjustBadgeHeight,
    onAfterPrint: adjustBadgeHeightAfterPrint,
  });

  const mainCrad = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
    border: "1px solid gainsboro",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.11)",
    height: "100%",
    display: "contents",
    justifyContent: "space-between",
  };

  const searchitem = ["userName", "role"];
  const formattedSearchResults = usDetails?.filter((user) => {
    if (searchField) {
      return searchitem?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section className="my-qr-codes-page">
        <style type="text/css" media="print">
          {` 
          @page{
            size : A4
          }
              .badges:nth-child(odd) {
              margin-left:0px; 
            }
              .badges:nth-child(even) {
                            margin-right:0px; 
            }
          @media print {
            @page {
              margin-top: 25mm; /* Adjust the value as needed */
              }
           
          .badges {
              break-inside: avoid;
                page-break-inside: avoid;
            }
            .badges:nth-child(6n) {
              page-break-after: always;
            }
              .badges:nth-child(odd) {
              margin-left:0px; 
            }
              .badges:nth-child(even) {
                            margin-left:30px; 
            }
          }
          `}
        </style>
        <div className="main_wrapper">
          <div className="title mb-3">My Badges </div>
          <div className="card">
            <div className="card-body">
              {/* <form onSubmit={GetUserQRDetail}> */}
              <div className="row">
                <div className="col-xl-6 col-md-6 col-12">
                  <div className="form-group">
                    <label className="form-label">Conference</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conList?.filter((obj) => obj.value === confId)}
                      onChange={(e) => handleChangeConference(e?.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-12">
                  <LeaDrop
                    selectedLea={selectLea}
                    handleChangeLea={handleChangeLea}
                  />
                </div>
                <div className="col-xl-3 col-md-6 col-12">
                  <StateAgencyDrop
                    handleChangeAgency={handleChangeAgency}
                    selectedAgency={selectAgency}
                  />
                </div>
                <div className="col-xl-3 col-md-6 col-12">
                  <IndustryDrop
                    isMulti={false}
                    selectedIndustry={[selectIntry]}
                    handleChangeIndustry={handleChangeIndustry}
                  />
                </div>
                <div className="col-xl-6 col-md-6 col-12 d-flex justify-content-start">
                  <div className="form-group mt-auto mb-0 mb-md-4">
                    {/* {userArray?.length > 0 ? ( */}
                    <Button
                      className="common_btn"
                      onClick={() => GetUserQRDetail()}
                      disabled={openCircle}
                    >
                      {openCircle ? (
                        <CircularProgress
                          color="inherit"
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      ) : (
                        "Apply Filter"
                      )}
                    </Button>
                    <Button
                      className="back_button ms-3"
                      // type="submit"

                      onClick={() => HandleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                  </div>
                </div>
              </div>
              {/* </form> */}
              {usDetails ? (
                <div
                  className="user_detail_QR"
                  style={{ width: qrSave ? "100%" : "" }}
                >
                  <div className="d-flex mb-3">
                    {usDetails?.length > 0 ? (
                      <button
                        type="button"
                        className="dashboard_btn background"
                        style={{ marginRight: "auto" }}
                        onClick={() => handlePrint()}
                        // onClick={(e) => saveQR(e, LogDetail?.userId)}
                      >
                        Download QR Code
                      </button>
                    ) : (
                      ""
                    )}
                    {usDetails?.length > 1 ? (
                      <div className={` search_sec ms-auto`}>
                        <div className="search_input w-100">
                          <img src={"/assets/icons/search_icon.svg"} alt="" />
                          <input
                            onChange={(e) => {
                              setSearchField(e.target.value);
                              setCurrentPage(1);
                            }}
                            value={searchField}
                            type="text"
                            className="form-control"
                            placeholder="Type your search here"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {usDetails?.length > 1 ? (
                    <div className="page_nation">
                      <div className="rowsPerPageOptions">
                        <span className="p_18_text">Items per page:</span>
                        <select
                          className="pagination_select p_16_text"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <NewPaginations
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={formattedSearchResults?.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {state?.groupUsers?.length > 0 ? (
                    <>
                      <div className="f-14-400">
                        <b>Searching group user list</b>
                      </div>
                      <ol>
                        {state?.groupUsers?.map((x, i) => (
                          <li key={i}>{x?.userName}</li>
                        ))}
                      </ol>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "210mm",
                      // margin: "20px",
                      // gap: "1rem",
                      // padding: "3rem 0",
                    }}
                    id="printablediv"
                    ref={componentRef}
                  >
                    {formattedSearchResults
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((x, i) => (
                        <div
                          className="badges"
                          key={i}
                          style={{
                            // minHeight: "304px",
                            overflow: "hidden",
                            width: "calc(47.48% - 10px)",
                            margin: "15px 15px 5px 15px",
                            minHeight: i % 6 === 0 ? "245px" : "270px",
                            // width: "355px",
                            position: "relative",
                            border: "1px solid #ccc",
                            background:
                              "linear-gradient(142deg, rgb(255 244 244) 50%, rgb(255, 255, 255) 50%)",
                            // "linear-gradient(142deg, rgb(245 245 245) 50%, rgba(255, 255, 255, 1) 50%)",
                          }}
                        >
                          <Box
                            className="qr-card mb-5"
                            sx={mainCrad}
                            // ref={qrRef}
                          >
                            <div
                              className="Card_of_user"
                              style={{
                                position: "relative",
                                display: "flex",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                className="info"
                                style={{
                                  padding: "1rem",
                                  height: "auto",
                                  textAlign: "center",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {confId ? (
                                  <div>
                                    <h6
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "0.5rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      {confDetail?.conferenceName}
                                    </h6>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {x?.userName && (
                                  <div
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {x?.userName ? x?.userName : " "}
                                  </div>
                                )}
                                {x?.companyName && (
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    {x?.companyName ? x?.companyName : ""}
                                  </div>
                                )}
                                {x?.userTitle && (
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    {x?.userTitle ? x?.userTitle : ""}
                                  </div>
                                )}
                                <div
                                  style={{
                                    marginTop: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    minHeight: "100px",
                                    gap: "1rem",
                                  }}
                                >
                                  <img
                                    src={
                                      confDetail?.displayImage
                                        ? Config.API_HOST_URL_live +
                                          confDetail?.displayImage
                                        : fig_events
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = fig_events;
                                    }}
                                    // src={logo}
                                    style={{ width: "45px", height: "45px" }}
                                    alt=""
                                  />{" "}
                                  {confDetail?.conferenceId ===
                                  "eb39c207-d080-48f3-b6d0-74f04becb75d" ? (
                                    <img
                                      src={"/assets/images/ohio_logo.png"}
                                      // src={logo}
                                      style={{ width: "45px", height: "45px" }}
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "absolute",
                                  bottom: "1rem",
                                  right: "1rem",
                                }}
                              >
                                <Box className="qr_code">
                                  <QRCode
                                    size={100}
                                    value={x?.userQRCode ? x?.userQRCode : ""}
                                    viewBox="0 0 80 80"
                                  />
                                </Box>
                              </div>
                            </div>
                          </Box>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MYQRCodes;
