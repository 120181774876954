import React from "react";
import "./index.scss";
import ShowFloorBox from "./ShowFloorBox";

function FloorPlanEdit(props) {
  const { eventId } = props;
  return (
    <section className="floor_plan_page">
      {/* <Sidebar
        content=".floor_plan_page"
        behaviour={"hidden"}
        position={"left"}
      />

      <div className="open_btn">
        <button
          className="my-auto tg_btn"
          onClick={() => {
            Sidebar.toggle(".floor_plan_page");
          }}
        >
          <img src={arrow} alt="" />
        </button>
      </div> */}
      <ShowFloorBox eventId={eventId} />
    </section>
  );
}

export default FloorPlanEdit;
