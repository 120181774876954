import React, { lazy, useEffect, useState } from "react";
import { GetCallApi } from "../../../Action/Action";
import { ToastError } from "../../Components/Toast";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const headCellsCH = [
  { id: "id", label: "#" },
  // { id: "associationMemberName", label: "Name" },
  { id: "userName", label: "Created By" },
  { id: "paymentReference", label: "Payment Reference" },
  { id: "paymentMode", label: "Payment Mode" },
  { id: "note", label: "Note", widthFix: 600 },
  { id: "amount", label: "Amount" },
  { id: "actualAmount", label: "Actual Amount" },
  { id: "additionalAmount", label: "Additional Amount" },
  { id: "discount", label: "Discount" },
  { id: "isPayByCash", label: "Is Pay By Cash" },
  { id: "isRefund", label: "Is Refund" },
  { id: "refundStatus", label: "Refund Status" },
  { id: "createdOn", label: "Created On" },
];
function TransactionHistory(props) {
  const { asmData } = props;
  //   const { associationMembersId } = asmData;
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  useEffect(() => {
    if (asmData) {
      getTHList();
    }
  }, [asmData]);
  const Logdetails = useSelector((state) => state.login.LoginDetails);
  const getTHList = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `api/AssociationPayment/GetAssociationPaymentTransactionHistory?associationPaymentId=${asmData?.id}&userId=${Logdetails?.userId}`,
    });
    if (res?.status === 200 && res?.data?.success) {
      let saveData = res?.data?.payload?.map((x, i) => {
        return {
          ...x,
          id: i + 1,
          paymentMode: x?.isPayByCash
            ? "Cash"
            : x?.paymentMode === "Invoice"
            ? x?.paymentReference
              ? "Online"
              : x?.paymentMode
            : x?.paymentMode,
          isPayByCash: x?.isPayByCash ? "Yes" : "",
          isRefund: x?.isRefund ? "Yes" : "",
          createdOn: x?.createdOn
            ? new Date(x?.createdOn)?.toLocaleString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
                hour12: true,
              })
            : "",
        };
      });
      setDataOfTable(saveData);
      setOpenCircle(false);
    } else {
      setOpenCircle(false);
      ToastError(res?.data?.message);
    }
  };

  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <div className="col-md-12 new_card p-0 mb-4">
          {asmData && (
            <div className="row p-3">
              {Object.entries(asmData)?.map(([key, value]) => {
                if (value && key !== "id") {
                  return (
                    <div key={key} className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          {key}: {value}
                        </label>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <NewLIstTable
            headCells={headCellsCH}
            title={"Transaction History List"}
            // action={true}
            ListData={dataOfTable}
          />
        </div>
      </div>
    </>
  );
}

export default TransactionHistory;
