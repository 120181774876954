// src/features/thunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithRetry, GetCallApi } from "./Action";

export const fetchDataLEA = createAsyncThunk(
  "AssociationMember/fetchDataLEA",
  async (userId, { rejectWithValue }) => {
    try {
      return fetchWithRetry(getASMList, ["LEA", userId]); // 3 retries, 5000ms delay
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDataAgency = createAsyncThunk(
  "AssociationMember/fetchDataAgency",
  async (userId, { rejectWithValue }) => {
    try {
      return fetchWithRetry(getASMList, ["State Agency", userId]); // 3 retries, 5000ms delay
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDataIndustry = createAsyncThunk(
  "AssociationMember/fetchDataIndustry",
  async (userId, { rejectWithValue }) => {
    try {
      return fetchWithRetry(getASMList, ["Industry", userId]); // 3 retries, 5000ms delay
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchDataAssociation = createAsyncThunk(
  "AssociationMember/fetchDataAssociation",
  async (userId) => {
    return await getASList(userId); // Call the API function for Assocaition
  }
);

const getASMList = async (type, userId) => {
  let seData = {
    url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${type}&userId=${userId}`,
  };
  let res = await GetCallApi(seData);
  if (res?.status === 200 && res?.data.success) {
    return res?.data.payload?.map((x) => {
      return {
        ...x,
        value: x?.associationMemberId,
        label: x?.name,
      };
    });
  } else {
    throw new Error("Network response was not ok");
  }
};

const getASList = async (userId) => {
  let seData = {
    url: `/api/Association/GetAssociationListByUserId?userId=${userId}`,
  };
  let res = await GetCallApi(seData);
  if (res?.status === 200 && res?.data.success) {
    return res?.data.payload?.map((x, i) => {
      return {
        ...x,
        id: i + 1,
        value: x?.associationId,
        label: x?.name,
      };
    });
  } else {
    throw new Error("Network response was not ok");
  }
};
