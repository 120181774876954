import { store } from "../store";
import { GetCallApi } from "./Action";

export const GetAllWithoutTokenConferenceListDropDown = async () => {
  const userId = store.getState().login.LoginDetails.userId; // Retrieve token from storage

  let seData = {
    url: `/api/Conference/GetApproveConferenceList?userId=${userId}`,
    // url: `/api/Conference/GetAllWithoutTokenConferenceList?isAlphanumericOrder=true`,
  };
  let res = await GetCallApi(seData);
  if (res?.status === 200 && res?.data.success) {
    let co = res?.data.payload?.map((x) => {
      return {
        ...x,
        value: x?.conferenceId,
        label: x?.conferenceName,
      };
    });
    return co;
  }
};
