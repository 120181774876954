import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerName: { name: "Market Place", link: "/market-place" },
  company: [],
  register: []
};

export const menuSlice = createSlice({
  name: "headerMenu",
  initialState,
  reducers: {
    HeadMenuName(state, action) {
      state.headerName = action.payload;
    },
    LoginHeadMenuList(state, action) {
      state.LoginHeadList = action.payload;
    },
    LoginHeadMenuSe(state, action) {
      state.LoginMenuSe = action.payload;
    },
    CompanyData(state, action) {
      state.company = action.payload;
    },
    RegisterData(state, action) {
      state.register = action.payload;
    },
    resetMenu: () => initialState,
  },
});

export const { HeadMenuName, LoginHeadMenuList, LoginHeadMenuSe, CompanyData, RegisterData, resetMenu } =
  menuSlice.actions;

export default menuSlice.reducer;
