import React, { useEffect, useMemo, useState } from "react";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  // PaymentRequestButtonElement,
  // ExpressCheckoutElement,
  // PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  SwalMessage,
  ToastError,
  ToastSuccess,
} from "../../Modules/Components/Toast";
import useResponsiveFontSize from "../../Modules/SuperAdmin/MemberManagement/Association/UserAssociation/useResponsiveFontSize";
import { CircularProgress, Button, Backdrop } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../Action/Action";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useForm } from "react-hook-form";
import { BsFillPatchCheckFill } from "react-icons/bs";
// import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./index.scss";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function StripePayment() {
  const { id } = useParams();
  const location = useLocation();
  // const LogDetails = useSelector((state) => state.login.LoginDetails);

  const [openCircle, setOpenCircle] = useState(false);
  const [pathName, setPathName] = useState();
  const [paymentDetail, setPaymentDetail] = useState();
  const [paymentDone, setPaymentDone] = useState();
  const [clientPublicKey, setClientPublicKey] = useState();

  useEffect(() => {
    getPaymentData();
  }, []);
  const getPaymentData = async () => {
    let asoMembership = `/api/AssociationPayment/ViewOrResendAssociationInvoiceDetailsWithoutToken?associationInvoiceId=${id}`;
    let asoProduct = `/api/AssociationProductPayment/ViewAssociationProductInvoiceDetails?associationProductPaymentId=${id}`;
    let confIndividual = `/api/ExhibitorAttendeeInvoice/ViewExhibitorAttendeeInvoiceDetailsWithoutToken?exhibitorAttendeeInvoiceId=${id}`;
    let confGroup = `/api/MemberManagement/ViewOrResendMemberManagementInvoiceDetailsWithoutToken?memberManagementInvoiceId=${id}`;
    let PName =
      location?.pathname?.split("/")[2] === "association-membership"
        ? "AssociationMember"
        : location?.pathname?.split("/")[2] === "association-product"
        ? "AssociationProduct"
        : location?.pathname?.split("/")[2] === "conference-individual"
        ? "ConferenceIndividual"
        : location?.pathname?.split("/")[2] === "conference-group"
        ? "ConferenceGroup"
        : "";
    setPathName(PName);
    let path =
      location?.pathname?.split("/")[2] === "association-membership"
        ? asoMembership
        : location?.pathname?.split("/")[2] === "association-product"
        ? asoProduct
        : location?.pathname?.split("/")[2] === "conference-individual"
        ? confIndividual
        : location?.pathname?.split("/")[2] === "conference-group"
        ? confGroup
        : "";
    let url = path;
    // setClientPublicKey(
    //   "pk_test_51Jcb3kEf9tRIx2k2ERTwAkqw325lvTvxlxHmJqy4Nl1iuWCAApvbya7XgY7awLar37fFqkoAZWrI964TKxYjcvZP00EBvSnMNV"
    // );
    setOpenCircle(true);
    if (url) {
      let res1 = await GetCallApi({
        url: url,
      });
      if (res1?.status === 200 && res1?.data.success) {
        let d = res1?.data.payload;
        if (d?.isPayByOnline === true || d?.invoiceStatus === "Complete") {
          setPaymentDone(true);
          setOpenCircle(false);
        } else {
          setPaymentDetail({
            ...d,
            amount:
              PName === "AssociationMember" ||
              PName === "ConferenceIndividual" ||
              PName === "ConferenceGroup"
                ? d?.totalAmount
                : PName === "AssociationProduct"
                ? d?.paymentAmount
                : "",
          });

          let seData = {
            url:
              PName === "AssociationMember" || PName === "AssociationProduct"
                ? `/api/AssociationPayment/GetPaymentKeysByAssociationId?associationId=${d?.associationId}`
                : `/api/Payment/GetPaymentKeysByConferenceId?conferenceId=${d?.conferenceId}`,
            body: {},
          };
          const res = await PostCallApi(seData);

          if (res?.status === 200 && res?.data.success) {
            const clientPub = res?.data?.payload?.publicKey;
            setClientPublicKey(clientPub);
            setOpenCircle(false);
          } else {
            ToastError(res?.data.message);
            setOpenCircle(false);
          }
        }
      } else {
        ToastError(res1?.data.message);
        setOpenCircle(false);
      }
    } else {
      ToastError();
    }
  };
  const stripePromise = loadStripe(clientPublicKey);
  const CheckoutForm = ({ setPaymentDone, paymentDetail, pathName }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [dis, setDis] = useState(false);
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 200);
    }, []);
    let send = paymentDetail;

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;
          let paymentData = {
            ...send,
            associationProductPaymentId:
              pathName === "AssociationProduct" ? id : null,
            PaymentMethodId: sourceId,
            attendeePaymentId:
              pathName === "ConferenceIndividual"
                ? send?.userType === "User"
                  ? send?.paymentId
                  : null
                : null,
            boothBookingPaymentId:
              pathName === "ConferenceIndividual"
                ? send?.userType === "Exhibitor"
                  ? send?.paymentId
                  : null
                : null,
            memberManagementPaymentId:
              pathName === "ConferenceGroup" ? send?.paymentId : null,
          };

          delete paymentData.userId;

          let url =
            pathName === "AssociationMember"
              ? `/api/AssociationPayment/AddPayByOnlineInAssociationInvoice`
              : pathName === "AssociationProduct"
              ? `/api/AssociationProductPayment/AddPayByOnlineInAssociationProductInvoice`
              : pathName === "ConferenceIndividual"
              ? `/api/ExhibitorAttendeeInvoice/AddPayByOnlineInExhibitorOrAttendeeInvoice`
              : pathName === "ConferenceGroup"
              ? `/api/MemberManagement/AddPayByOnlineInMemberManagementInvoice`
              : "";
          let sveCC = {
            url: url,
            body: paymentData,
          };
          setDis(true);
          let res = await PostCallApi(sveCC);
          if (res?.status === 200) {
            if (res?.data.isPaid) {
              setPaymentDone(true);
              setDis(false);
              ToastSuccess(res?.data.message);
            } else {
              setDis(false);
              SwalMessage(res?.data.message);
            }
          } else {
            setDis(false);
            ToastError(res?.data.message);
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };
    // const handleClick = async () => {
    //   const stripe = await stripePromise;

    //   const response = await fetch(
    //     "https://api.stripe.com/v1/checkout/sessions",
    //     {
    //       method: "POST",
    //       headers: {
    //         Authorization: `Bearer sk_test_51OFh6oKWk1TMy99D6uUp78OwlV2sr5zoFiCCGbpfpYYndX9qbqyqrVVyTPkHdAtLAp5W5kH2MlejkwZJD9tTVtjK00TFZXi29h`, // Note: Exposing secret key in frontend is not safe!
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //       body: new URLSearchParams({
    //         payment_method_types: "card",
    //         line_items: JSON.stringify([
    //           {
    //             amount: 2000, // amount in cents
    //             currency: "usd",
    //             quantity: 1,
    //           },
    //         ]),
    //         mode: "payment",
    //         success_url: window.location.origin + "/success",
    //         cancel_url: window.location.origin + "/cancel",
    //       }),
    //     }
    //   );

    //   const session = await response?.json();

    //   if (session.id) {
    //     const result = await stripe.redirectToCheckout({
    //       sessionId: session.id,
    //     });

    //     if (result.error) {
    //       alert(result.error.message);
    //     }
    //   } else {
    //     alert("Failed to create session");
    //   }
    // };
    // const [paymentRequest, setPaymentRequest] = useState(null);

    // useEffect(() => {
    //   if (!stripe) {
    //     return;
    //   }

    //   const pr = stripe.paymentRequest({
    //     country: "US",
    //     currency: "usd",
    //     total: {
    //       label: "Total",
    //       amount: 2000, // Amount in cents
    //     },
    //     requestPayerName: true,
    //     requestPayerEmail: true,

    //     wallets: ["googlePay", "applePay", "browserCard", "link"],
    //   });
    //   // Create the Payment Request Button
    //   pr.canMakePayment().then((result) => {
    //     if (result) {
    //       setPaymentRequest(pr);
    //     }
    //   });

    //   // Handle payment confirmation
    //   pr.on("token", async (ev) => {
    //     // Process the payment with the token
    //     // For example, send it to your server for processing
    //     console.log("Token received:", ev.token.id);
    //     ev.complete("success");
    //   });

    //   // Cleanup the event listener
    //   return () => {
    //     pr.off("token");
    //   };
    // }, [stripe]);

    // const [paymentRequest, setPaymentRequest] = useState(null);

    // useEffect(() => {
    //   if (!stripe) return;

    //   const paymentRequestData = {
    //     country: "US", // Your country
    //     currency: "usd", // Your currency
    //     total: {
    //       label: "Your Company Name",
    //       amount: 1000, // Amount in cents
    //     },
    //     requestPayerName: true,
    //     requestPayerEmail: true,
    //   };

    //   const pr = stripe.paymentRequest(paymentRequestData);

    //   // Check if the payment request is supported
    //   pr.canMakePayment().then((result) => {
    //     if (result) {
    //       setPaymentRequest(pr);
    //     } else {
    //       console.log("Payment request not supported");
    //     }
    //   });

    //   // Handle the payment request
    //   pr.on("token", async (ev) => {
    //     // Send the token to your server for processing
    //     const { token } = ev;

    //     // Example fetch request to your backend
    //     const response = await fetch("/your-backend-endpoint", {
    //       method: "POST",
    //       body: JSON.stringify({ token }),
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });

    //     if (response?.ok) {
    //       ev.complete("success");
    //     } else {
    //       ev.complete("fail");
    //     }
    //   });

    //   // Cleanup on unmount
    //   return () => {
    //     pr.off("token");
    //   };
    // }, [stripe]);

    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <PaymentElement /> */}
        <Button className="common_btn" type="submit" disabled={!stripe || dis}>
          {dis ? (
            <CircularProgress
              color="inherit"
              sx={{
                color: "#fff",
                marginLeft: "0.5rem",
                height: "23px !important",
                width: "23px !important",
              }}
            />
          ) : (
            "Complete Payment"
          )}
        </Button>
        {/* {paymentRequest && (
          <button
            className="payment-button"
            onClick={() => paymentRequest.show()}
          >
            <img
              src="/assets/images/apple_pay.webp"
              alt="Apple Pay"
              className="logo"
            />
            Pay with Apple Pay /
            <img
              src="/assets/images/google_pay.png"
              alt="Google Pay"
              className="logo"
            />
            Google Pay
          </button>
        )} */}
        {/* <Button
          className="common_btn ms-3"
          role="link"
          onClick={handleClick}
        >
          Checkout
        </Button> */}
        {/* {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
        <ExpressCheckoutElement /> */}
      </form>
    );
  };

  // const options = {
  //   mode: "payment",
  //   amount: 1099,
  //   currency: "usd",
  //   // Customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="Register_Conference_Form">
        <div className="main_wrapper">
          {paymentDone ? (
            <>
              <div className="success_message_box col-md-6 m-auto">
                <BsFillPatchCheckFill className="icon" />
                <div>
                  <h1 className="m-0">Payment Successful</h1>
                  <p>We will share payment receipt on registered email.</p>
                </div>
              </div>
              <div className="text-center mt-4">
                <Link
                  to={-1}
                  // to={LogDetails?.userId ? -1 : "/"}
                  style={{ textDecoration: "none" }}
                  className="back_button"
                  onClick={() => sessionStorage.removeItem("asd")}
                >
                  Back
                </Link>
              </div>
            </>
          ) : (
            <div className="card">
              <div className="card-body">
                <div>
                  <div className="title attend-title mb-3">Payment Details</div>
                  <div className="col-md-6">
                    <div className="conference_details border-bottom-0 mb-4">
                      <div className="subtotal">
                        <p>
                          Total{" "}
                          <span>
                            {paymentDetail?.amount
                              ? paymentDetail?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : "$0.00"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <Elements stripe={stripePromise}>
                    {/* <Elements stripe={stripePromise} options={options}> */}
                    <CheckoutForm
                      setPaymentDone={setPaymentDone}
                      paymentDetail={paymentDetail}
                      pathName={pathName}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default StripePayment;
