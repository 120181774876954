import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./index.scss";
export default function CropFix(props) {
  const {
    src,
    filename,
    openCropModal,
    setCropModal,
    setCroppedImageUrl,
    fixSize,
    coverCropImage,
  } = props;
  const [crop, setCrop] = useState({
    maxHeight: fixSize ? fixSize?.fixheight : 500,
    maxWidth: fixSize ? fixSize?.fixwidth : 700,
  });

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    setCroppedImageUrl(dataURLtoFile(base64Image, filename));
    setCropModal(false);
  };
  const [image, setImage] = useState(null);

  const onload = (e) => {
    // const { width, height } = e.currentTarget;
    setImage(e.currentTarget);
  };
  console.log(fixSize, crop, coverCropImage);
  return (
    <div>
      <Dialog
        open={openCropModal}
        onClose={() => setCropModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Fix_box_width"
      >
        <DialogTitle id="alert-dialog-title">{"Crop Image"}</DialogTitle>
        <DialogContent>
          <ReactCrop
            // src={src}
            // onImageLoaded={setImage}
            // aspect={4 / 4}
            maxHeight={fixSize ? fixSize?.fixheight : 500}
            maxWidth={fixSize ? fixSize?.fixwidth : 700}
            crop={crop}
            onChange={setCrop}
            className={
              fixSize && coverCropImage
                ? "fixSize coverCropImage"
                : fixSize
                ? "fixSize"
                : coverCropImage
                ? "coverCropImage"
                : ""
            }
          >
            <img src={src} onLoad={onload} alt="" />
          </ReactCrop>
          {/* <ReactCrop
              src={props.src}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            /> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCropModal(false);
              setCroppedImageUrl(null);
            }}
            color="primary"
          >
            cancel
          </Button>
          <Button onClick={cropImageNow} color="primary" autoFocus>
            done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
