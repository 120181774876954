import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

function PostBlockCategoryCreate() {
  const [openCircle, setOpenCircle] = useState(false);
  const { id } = useParams();
  const nav = useNavigate();
  const LogDetails = useSelector((state) => state?.login?.LoginDetails);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/BlockPostCategory/CreateBlockPostCategory`,
      body: { ...data, createdBy: LogDetails?.userId },
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data?.success) {
      nav(-1);
      ToastSuccess(res?.data.message);
    } else {
      ToastError(res?.data?.message);
    }
  };

  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);

  const getDataById = async () => {
    setOpenCircle(true);
    let res = await GetCallApi({
      url: `/api/BlockPostCategory/GetBlockPostCategoryById?blockPostCategoryId=${id}`,
    });
    setOpenCircle(false);
    if (res?.status === 200 && res?.data?.success) {
      let sv = res?.data?.payload;
      setValue("blockPostCategoryId", id);
      setValue("blockPostCategoryName", sv?.blockPostCategoryName);
    } else {
      ToastError(res?.data?.message);
    }
  };

  return (
    <>
      {" "}
      <section className="create_discount">
        <div className="main_wrapper">
          <div className="title mb-3">
            {id ? "Edit" : "Create"} Post Block Category
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Block Post Category Name
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Block Post Category Name"
                        {...register("blockPostCategoryName", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 250,
                            message: "Max character 250 allowed",
                          },
                        })}
                      />
                      {errors.blockPostCategoryName && (
                        <span role="alert" className="error_text">
                          {errors.blockPostCategoryName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Back
                      </Button>
                      <Button
                        className="common_btn ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PostBlockCategoryCreate;
