import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogdata from "./../blogdata";
import "./../index.scss";

import parse from "html-react-parser";
import shortid from "shortid";

const QualityImage = "/assets/images/product-img.jpg";
const BlogData = blogdata;
const renderLoader = () => <p>Loading...</p>;

function CreatingNutritiousAndFunSchoolLunches() {
  let { id } = useParams();
  const FoundBlog = BlogData.find((element) => element.id === id);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    if (FoundBlog) {
      setPageData(FoundBlog?.BlogPageData);
    }
  }, [BlogData, id]); // Add dependencies here to avoid unnecessary re-renders

  if (pageData.length > 0) {
    console.log(pageData);
  }

  if (!FoundBlog) {
    return <p>Blog post not found</p>;
  }

  function Points(props) {
    return (
      <div
        className={`points_wrapper ${props.image ? "imagebox" : ""} ${
          props.class
        }`}
      >
        <div className="point_content">
          <div className="points_title">
            <h2>{props.title ? parse(props.title) : ""}</h2>
          </div>
          <div className="points_description">
            {props.Description?.map((data) =>
              data.desc ? (
                <p key={shortid.generate()}>{parse(data.desc)}</p>
              ) : null
            )}
          </div>
        </div>
        {props.image && (
          <div className="points_image">
            <Suspense fallback={renderLoader()}>
              <img src={props.image} alt={props.alt} title={props.cap} />
            </Suspense>
          </div>
        )}
      </div>
    );
  }

  function Steps(props) {
    return (
      <div
        className={`points_wrapper ${props.image ? "imagebox" : ""} ${
          props.class
        }`}
      >
        <div className="point_content">
          <div className="points_title">
            {props.title ? <h2>{parse(props.title)}</h2> : ""}
            {props.tit ? <h3>{parse(props.tit)}</h3> : ""}
          </div>
          <div className="steps_description">
            {props.description ? (
              <p className="step_desc">{parse(props.description)}</p>
            ) : (
              ""
            )}
            {props.desc1 ? (
              <p className="step_desc">{parse(props.desc1)}</p>
            ) : (
              ""
            )}
            {props.desc2 ? (
              <p className="step_desc">{parse(props.desc2)}</p>
            ) : (
              ""
            )}
            {props.desc3 ? (
              <p className="step_desc">{parse(props.desc3)}</p>
            ) : (
              ""
            )}
            {props.points?.map((data, i) => (
              <div key={i}>
                <p className="line-hight-2" key={shortid.generate}>
                  {parse(data.point)}
                  <br />
                  {data.pointsdes && (
                    <span className="mt-2" key={shortid.generate}>
                      {parse(`${data.pointsdes}`)}
                    </span>
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
        {props.image && (
          <div className="points_image">
            <img src={props.image} alt={props.alt} title={props.cap} />
          </div>
        )}
      </div>
    );
  }

  function Faq(props) {
    return (
      <div className="faq_question">
        <div className="faq_questions">
          <h3>{props.question ? parse(props.question) : ""}</h3>
        </div>
        <div className="faq_answer">
          <p>{props.answer ? parse(props.answer) : ""}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <section className="viewblog">
        <div className="container">
          <div className="common_heading text-center mt-0">
            <h1>{parse(FoundBlog.title || "")}</h1>
          </div>
          {FoundBlog.description && (
            <div className="common_content text-center">
              <p>{FoundBlog.description}</p>
            </div>
          )}
          <div className="blogImage">
            <Suspense fallback={renderLoader()}>
              <img
                src={QualityImage}
                alt={FoundBlog.alt}
                title={FoundBlog.cap}
              />
            </Suspense>
          </div>
          {pageData?.map((descData, i) => {
            descData.description && (
              <div className="common_content text-left mt-5" key={i}>
                {descData.description?.map((desc, i) => (
                  <p className="mt-2 mb-3" key={i}>
                    {desc.desc}
                  </p>
                ))}
              </div>
            )
          })}
          {pageData?.map((data) =>
            data.topic?.map((innerData, i) => (
              <Points
                key={i}
                title={innerData.title}
                Description={innerData.description}
                image={innerData.image}
                alt={innerData.alt}
                cap={innerData.cap}
                class={innerData.class}
              />
            ))
          )}
          {pageData?.map((data) =>
            data.topicPoints?.map((innerData, i) => (
              <Steps
                key={i}
                title={innerData.title}
                description={innerData.desc}
                points={innerData.points}
                image={innerData.image}
                alt={innerData.alt}
                cap={innerData.cap}
                class={innerData.class}
                subtitle={innerData.subtitle}
              />
            ))
          )}
          {pageData?.map((data) =>
            data.topic1?.map((innerData, i) => (
              <Points
                key={i}
                title={innerData.title}
                Description={innerData.description}
                image={innerData.image}
                alt={innerData.alt}
                cap={innerData.cap}
                class={innerData.class}
              />
            ))
          )}
          {pageData?.map((data) =>
            data.topicPoints1?.map((innerData, i) => (
              <Steps
                key={i}
                title={innerData.title}
                tit={innerData.tit}
                description={innerData.desc}
                desc1={innerData.desc1}
                desc2={innerData.desc2}
                desc3={innerData.desc3}
                points={innerData.points}
                pointsDes={innerData.pointsdes}
                image={innerData.image}
                alt={innerData.alt}
                cap={innerData.cap}
                class={innerData.class}
              />
            ))
          )}
          <div className="FAQ">
            {pageData?.map((data) =>
              data.faq?.map((innerData, i) => (
                <div key={i}>
                  <div className="faq_title">
                    <h2>{parse(innerData.title || "")}</h2>
                  </div>
                  {innerData.questions?.map((questionData, i) => (
                    <Faq
                      key={i}
                      question={questionData.question}
                      answer={questionData.answer}
                    />
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default React.memo(CreatingNutritiousAndFunSchoolLunches);
