import { Button, CircularProgress } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import useResponsiveFontSize from "../../SuperAdmin/MemberManagement/Association/UserAssociation/useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const StripeCardPayment = ({ CallBackApi }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [derror, setDerror] = useState();
  React.useEffect(() => {
    setTimeout(() => {
      loadCaptchaEnginge(6, "black", "white");
    }, 500);
  }, []);
  const [dis, setDis] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSubmitStripe = async (data) => {
    setDerror({ h_er: "" });
    if (validateCaptcha(data?.captcha, false) === true) {
      loadCaptchaEnginge(6, "black", "white");
      if (!stripe || !elements) {
        return;
      }
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data?.cardName,
          address: { postal_code: data?.zip ? data?.zip : null },
        },
        // card: elements.getElement(CardElement),
      });
      if (error) {
        // handle payment error
      } else {
        const sourceId = paymentMethod.id;
        setDis(true);
        await CallBackApi({ PaymentMethodId: sourceId });
        setDis(false);
      }
    } else {
      setDerror({ h_er: "Captcha does not match." });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitStripe)}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">Name On Card</label>{" "}
            <input
              type="text"
              className="form-control"
              placeholder="Enter name on card"
              {...register("cardName", {
                required: "This field is required",
              })}
            />
            {errors.cardName && (
              <span role="alert" className="error_text">
                {errors.cardName.message}
              </span>
            )}
          </div>
        </div>
        <div
          className="col-md-6 "
          // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
        >
          <div className="form-group">
            <label className="form-label">Card Number</label>
            <CardNumberElement className="form-control" options={options} />
          </div>
          {/* <CardElement options={CARD_OPTIONS} /> */}
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <label className="form-label"> Expiration date</label>
            <CardExpiryElement className="form-control" options={options} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <label className="form-label">CVC</label>
            <CardCvcElement className="form-control" options={options} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">Zip Code</label>{" "}
            <input
              type="text"
              className="form-control"
              placeholder="Enter Zip Code"
              {...register("zip", {
                // required: "This field is required",
                maxLength: {
                  value: 6,
                  message: "Max 6 characters",
                },
              })}
            />
            {errors.zip && (
              <span role="alert" className="error_text">
                {errors.zip.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-6 my-auto">
          <div className="captcha_box ">
            <LoadCanvasTemplate />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">Captcha</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Captcha"
              {...register("captcha", {
                required: {
                  value: true,
                  message: "This field is required",
                },
                maxLength: {
                  value: 6,
                  message: "Max 6 characters",
                },
              })}
            />
            {errors?.captcha && (
              <span role="alert" className="d-block error_text">
                {errors.captcha.message}
              </span>
            )}
            {derror?.h_er && (
              <span role="alert" className="d-block error_text">
                {derror.h_er}
              </span>
            )}
          </div>
        </div>
      </div>
      <Button className="common_btn" type="submit" disabled={!stripe || dis}>
        {dis ? (
          <CircularProgress
            color="inherit"
            sx={{
              color: "#fff",
              marginLeft: "0.5rem",
              height: "23px !important",
              width: "23px !important",
            }}
          />
        ) : (
          "Complete Payment"
        )}
      </Button>
    </form>
  );
};

export default StripeCardPayment;
