import React, { Suspense, useEffect, useState } from "react";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../Action/Action";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { ToastError, ToastSuccess } from "../../../Components/Toast";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../../Action/ConferenceListApi";
import { VscTrash } from "react-icons/vsc";
import { useSelector } from "react-redux";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function ClassworkDocUpload() {
  const [conferenceList, setConferenceList] = useState([]);
  const [derror, setDerror] = useState();
  const [confId, setConfId] = useState();
  const [sessionList, setSessionList] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [matD, setMatD] = React.useState();
  const { speakerId } = useParams();
  const nav = useNavigate();
  const logDetail = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [displayDoc, setDisplayDoc] = useState([]);
  useEffect(() => {
    document.title = "FIG Events | Classwork Document Uplaod";
    setValue("SpeakerId", speakerId);
    setValue("createdBy", logDetail?.userId);
    getConfList();
  }, []);
  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConferenceList(res);
    }
  };
  const getSessionList = async (conId) => {
    let res = await GetCallApi({
      url: `/api/Classes/GetSessionListBySpeakerId?conferenceId=${conId}&speakerId=${speakerId}`,
    });
    if (res?.status === 200 && res?.data?.success) {
      let sList = res?.data?.payload?.map((x) => {
        return { ...x, value: x?.sessionId, label: x?.sessionName };
      });
      setSessionList(sList);
    }
  };
  const getSessionDocList = async (sId) => {
    setDisplayDoc([]);
    if (sId) {
      let res = await GetCallApi({
        url: `/api/Classes/GetClassWorkDocumentListBySpeakerId?sessionId=${sId}&speakerId=${speakerId}`,
      });
      if (res?.status === 200 && res?.data?.success) {
        let sList = res?.data?.payload;
        setDisplayDoc(sList);
      }
    }
  };
  const handleConferenceSelect = (e) => {
    setConfId(e);
    setValue("ConferenceId", e);
    getSessionList(e);
  };
  const handleSessionSelect = (e) => {
    setSessionId(e);
    setValue("sessionId", e);
    getSessionDocList(e);
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);

    let doc = data;
    let formData = new FormData();
    if (data?.ClassWorkDocuments?.length > 0) {
      data?.ClassWorkDocuments?.map((d) => {
        return formData.append("ClassWorkDocuments", d);
      });
    }
    ["SpeakerClassworkDocumentation", "ClassWorkDocuments"].forEach(
      (e) => delete doc[e]
    );
    for (var key in doc) {
      formData.append(key, doc[key]);
    }

    let res = await PostCallApi({
      url: `/api/Speaker/UploadClassWorkDocuments`,
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res?.status === 200 && res?.data?.success) {
      getSessionDocList(sessionId);
      setValue(`ClassWorkDocuments`, "");
      setValue(`SpeakerClassworkDocumentation`, "");
      ToastSuccess(res?.data?.message);
      setOpenCircle(false);
    } else {
      ToastError(res?.data?.message);
      setOpenCircle(false);
    }
  };

  const handleDoCMultiFile1 = async (e) => {
    if (e.target.files.length) {
      if (e.target.files.length > 5) {
        setValue(`ClassWorkDocuments`, "");
        setValue(`SpeakerClassworkDocumentation`, "");
        setDerror({ doc: `You can upload maximum 5 documents` });
      } else if (e.target.files?.length > 0) {
        setDerror({ doc: `` });
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }

        setValue(`ClassWorkDocuments`, base64_namemulti);
        setValue(`SpeakerClassworkDocumentation`, multifile);
      }
    }
  };

  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMatD();
  };

  const deleteDocument = async () => {
    if (matD?.classesDocumnetId) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let res = await DeleteCallApi({
        url: `/api/Classes/DeleteClassesDocumentById?classesDocumnetId=${matD?.classesDocumnetId}`,
      });
      if (res?.status === 200 && res?.data.success) {
        setOpenCircle(false);
        handleClose();
        ToastSuccess(res?.data?.message);
        getSessionDocList(sessionId);
      } else {
        setOpenCircle(false);
        ToastError(res?.data?.message);
        setDerror({ err: res?.data?.message });
      }
    }
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard classword_doc_upload py-4">
          <div className="main_wrapper">
            <div className="title mb-3">Classwork Document Upload </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Conference
                          <span className="red-color"> *</span>
                        </label>

                        <Select
                          isMulti={false}
                          name="colors"
                          options={conferenceList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Conference"
                          filterOption={customFilter}
                          value={conferenceList?.filter(
                            (obj) => obj.value === confId
                          )}
                          onChange={(e) => handleConferenceSelect(e?.value)}
                        />
                        <select
                          className="form-select d-none"
                          {...register("ConferenceId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Conference</option>
                          {conferenceList &&
                            conferenceList?.map((m, i) => (
                              <option value={m.value} key={i}>
                                {m.label}
                              </option>
                            ))}
                        </select>
                        {errors.ConferenceId && (
                          <span role="alert" className="error_text">
                            {errors.ConferenceId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Session/Class
                          <span className="red-color"> *</span>
                        </label>

                        <Select
                          isMulti={false}
                          name="colors"
                          options={sessionList}
                          isClearable={true}
                          className="dropdown-single-select"
                          classNamePrefix="select"
                          placeholder="Select Session/Class"
                          filterOption={customFilter}
                          value={sessionList?.filter(
                            (obj) => obj.value === sessionId
                          )}
                          onChange={(e) => handleSessionSelect(e?.value)}
                        />
                        <select
                          className="form-select d-none"
                          {...register("sessionId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Session</option>
                          {sessionList &&
                            sessionList?.map((m, i) => (
                              <option value={m.value} key={i}>
                                {m.label}
                              </option>
                            ))}
                        </select>
                        {errors.sessionId && (
                          <span role="alert" className="error_text">
                            {errors.sessionId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Classwork Documentation
                          <span className="red-color"> *</span>
                        </label>
                        <div className="d-flex upload_btn_sec">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            placeholder="Upload classwork documentation"
                            {...register(`SpeakerClassworkDocumentation`, {
                              required: "This field is required",
                            })}
                          />
                          <IconButton
                            component="label"
                            className="upload_btn m-0"
                          >
                            Choose File
                            <input
                              id="upload"
                              type="file"
                              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              multiple
                              max={5}
                              className="form-control"
                              hidden
                              onChange={(e) => handleDoCMultiFile1(e)}
                            />
                          </IconButton>
                        </div>

                        {errors.SpeakerClassworkDocumentation && (
                          <span role="alert" className="error_text">
                            {errors.SpeakerClassworkDocumentation.message}
                          </span>
                        )}
                        {derror?.doc && (
                          <span role="alert" className="error_text">
                            {derror?.doc}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end mt-3">
                      <div className="form-group">
                        <Button className="back_button" onClick={() => nav(-1)}>
                          Cancel
                        </Button>
                        <Button
                          className="common_btn ms-3"
                          type="submit"
                          disabled={openCircle}
                        >
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Save"
                          )}
                        </Button>
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    {displayDoc?.length > 0 ? <div>Documents: </div> : ""}
                    <div className="row">
                      {displayDoc?.map((mat, no) => (
                        <div className="col-md-3" key={no}>
                          <div className="mat_box_main mb-3">
                            <div className="num me-3">{no + 1}</div>
                            <div className="text_data">
                              {mat?.fileName ? mat?.fileName : "Material"}
                            </div>
                            <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleOpen(mat)}
                            >
                              <VscTrash className="icon" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {open && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={openCircle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>Delete Class Document</h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want to Delete
                      <span> {matD?.fileName}?</span>
                    </p>
                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                    <button
                      type="button"
                      className="popup_btn delete"
                      onClick={deleteDocument}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default ClassworkDocUpload;
