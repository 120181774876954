import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableSortLabel,
  TableCell,
  CircularProgress,
  Box,
  Button,
  Backdrop,
} from "@mui/material";
import { ToastError, ToastSuccess } from "../../Components/Toast";
// import { fetchDataIndustry } from "../../../Action/AssociationMember";
// import { selectIndustry } from "../../../reducers/AssociationMember";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const StyledTableCell = styled(TableCell)(() => ({
  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: "#F1F6FC",
  // },
  ".Mui-active": {
    color: "#fff !important",
    ".MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(odd)": {
  backgroundColor: "#ffffff",
  // },
  // "&:nth-of-type(even)": {
  // backgroundColor: "#F1F6FC",
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const headCells = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "isAdmin",
    label: "IsAdmin",
  },
  {
    id: "isCompanyEdit",
    label: "Company Create/Edit",
  },
  {
    id: "isProduct",
    label: "Product Create/Edit ",
  },
  {
    id: "isTeamMember",
    label: "Team Member Create/Edit ",
  },
];
function CompanyUserAccess() {
  const [open, setOpen] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [parentCompanyId, setParentCompanyId] = useState();
  const [userList, setUserList] = useState([]);
  const logDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Industry/Company User Access";
    GetCompanyList();
  }, []);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  function descendingComparator(a, b, orderBy) {
    if (orderBy === "no" || orderBy === "id") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const GetCompanyList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=Industry&userId=${logDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res?.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.associationMembersId,
          label: c?.name,
        };
      });
      setCompanyList(comList);
      if (comList?.length > 0) {
        setTimeout(() => {
          handleParentCompany(comList[0]?.value);
        }, 500);
      }
    }
  };

  const handleParentCompany = (e) => {
    setParentCompanyId(e);
    getUserList(e);
  };

  const getUserList = async (comId) => {
    setUserList([]);
    if (comId) {
      setOpen(true);
      let seData = {
        url: `/api/AssociationMembers/GetCompanyUserAllocationListByCompanyId?companyId=${comId}`,
      };
      let res = await GetCallApi(seData);
      setOpen(false);

      if (res?.status === 200 && res?.data?.success) {
        let usList = res?.data?.payload?.map((u, i) => {
          return {
            id: i + 1,
            ...u,
            isAdminAccess: u?.isAdmin,
            isAdmin: u?.isAdmin ? "Yes" : "",
            // <span className="control_btn d-flex align-items-center">
            //   <label className="personal_detail_label">
            //     <input
            //       type="checkbox"
            //       className="login_check"
            //       name="Remember Me"
            //       checked={u?.isAdmin}
            //       onChange={(e) =>
            //         UpdateUserList(e?.target?.checked, u?.userId, "admin")
            //       }
            //     />
            //     <span className="checkmark"></span>
            //   </label>
            // </span>
            isCompanyEdit: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={u?.isCompanyAccess}
                    onChange={(e) =>
                      UpdateUserList(e?.target?.checked, u?.userId, "company")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
            isProduct: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={u?.isProductAccess}
                    onChange={(e) =>
                      UpdateUserList(e?.target?.checked, u?.userId, "product")
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
            isTeamMember: (
              <span className="control_btn d-flex align-items-center">
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={u?.isTeamMemberAccess}
                    onChange={(e) =>
                      UpdateUserList(
                        e?.target?.checked,
                        u?.userId,
                        "teamMember"
                      )
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </span>
            ),
          };
        });
        setUserList(usList);
      }
    }
  };

  const UpdateUserList = (check, uId, type) => {
    setUserList((prevState) => {
      return prevState.map((user) => {
        if (user?.userId === uId) {
          // if (type === "admin") {
          //   return {
          //     ...user,
          //     isAdminAccess: check,
          //     isAdmin: (
          //       <span className="control_btn d-flex align-items-center">
          //         <label className="personal_detail_label">
          //           <input
          //             type="checkbox"
          //             className="login_check"
          //             name="Remember Me"
          //             checked={check}
          //             onChange={(e) =>
          //               UpdateUserList(
          //                 e?.target?.checked,
          //                 user?.userId,
          //                 "admin"
          //               )
          //             }
          //           />
          //           <span className="checkmark"></span>
          //         </label>
          //       </span>
          //     ),
          //   };
          // }
          if (type === "company") {
            return {
              ...user,
              isCompanyAccess: check,
              isCompanyEdit: (
                <span className="control_btn d-flex align-items-center">
                  <label className="personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      checked={check}
                      onChange={(e) =>
                        UpdateUserList(
                          e?.target?.checked,
                          user?.userId,
                          "company"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </span>
              ),
            };
          }
          if (type === "product") {
            return {
              ...user,
              isProductAccess: check,
              isProduct: (
                <span className="control_btn d-flex align-items-center">
                  <label className="personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      checked={check}
                      onChange={(e) =>
                        UpdateUserList(
                          e?.target?.checked,
                          user?.userId,
                          "product"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </span>
              ),
            };
          }
          if (type === "teamMember") {
            return {
              ...user,
              isTeamMemberAccess: check,
              isTeamMember: (
                <span className="control_btn d-flex align-items-center">
                  <label className="personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      checked={check}
                      onChange={(e) =>
                        UpdateUserList(
                          e?.target?.checked,
                          user?.userId,
                          "teamMember"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </span>
              ),
            };
          }
        }
        return user;
      });
    });
  };
  const sendUser = async () => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/CompanyRegistration/AddUpdateCompanyAndUserWiseAccess`,
      body: {
        companyId: parentCompanyId,
        createdBy: logDetail?.userId,
        userAccessList: userList?.map((x) => {
          return {
            userId: x?.userId,
            isProductAccess: x?.isProductAccess,
            isCompanyAccess: x?.isCompanyAccess,
            isTeamMemberAccess: x?.isTeamMemberAccess,
            isAdminAccess: x?.isAdminAccess,
          };
        }),
      },
    });

    setOpenCircle(false);
    if (res?.status === 200 && res?.data?.success) {
      getUserList(parentCompanyId);
      ToastSuccess(res?.data?.message);
    } else {
      ToastError(res?.data?.message);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="comapny_user_access py-4">
          <div className="main_wrapper">
            <div className="new_card">
              <div className="title mb-3">Industry/Company User Access</div>
              <div className="row ">
                <div className="col-md-6">
                  <div className={`form-group ${!parentCompanyId && "pb-5"}`}>
                    <label className="form-label">
                      Industry/Company
                      <span className="red-color"> *</span>
                    </label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={companyList ?? []}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Industry/Company"
                      filterOption={customFilter}
                      value={
                        companyList?.filter(
                          (obj) => obj.value === parentCompanyId
                        ) ?? []
                      }
                      onChange={(e) => handleParentCompany(e?.value)}
                    />
                  </div>
                </div>
                {userList?.length > 0 ? (
                  <>
                    <div className="col-md-6 d-flex justify-content-start mt-auto">
                      <div className="form-group">
                        <Button
                          className="common_btn"
                          onClick={sendUser}
                          disabled={openCircle ? true : false}
                        >
                          {openCircle ? (
                            <CircularProgress
                              color="inherit"
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="col-12 new_all_list">
                      <Paper className="table-box">
                        <TableContainer>
                          <Table>
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                              {stableSort(
                                userList,
                                getComparator(order, orderBy)
                              )?.map((row, index) => {
                                return (
                                  <StyledTableRow
                                    className={`table_body`}
                                    key={index}
                                  >
                                    {headCells?.map((column) => {
                                      const value = row[column?.id];
                                      return (
                                        <StyledTableCell
                                          className={`column_data`}
                                          key={column?.id}
                                        >
                                          {value ? value : ""}
                                        </StyledTableCell>
                                      );
                                    })}
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CompanyUserAccess;
