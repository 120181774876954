import React from "react";
import "./index.scss";
import Sidebar from "./sidebar";
import ShowFloorBox from "./ShowFloorBox";
import { FaArrowRightLong } from "react-icons/fa6";

function FloorPlanApproval() {
  const [sboothId, setSboothId] = React.useState();

  return (
    <section className="floor_plan_page">
      <Sidebar
        content=".floor_plan_view_page"
        behaviour={"hidden"}
        position={"left"}
        setSboothId={setSboothId}
      />

      <div className="open_btn">
        <button
          className="my-auto tg_btn"
          onClick={() => {
            Sidebar.toggle(".floor_plan_page");
          }}
        >
          <FaArrowRightLong fontSize={20} />
        </button>
      </div>
      <ShowFloorBox sboothId={sboothId} setSboothId={setSboothId} />
    </section>
  );
}

export default FloorPlanApproval;
