import React, { Suspense, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import blogdata from "./blogdata";
import Blogbox from "../../../Modules/Components/Blogbox";

const latestBlog = blogdata;
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function Blogs() {
  const [openCircle] = useState(false);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="commingSoon">
          <div className="container">
            <div className="commingSoon_title">
              <h2>Blog Post for School Nutrition’s</h2>
              <p>
                Sure! Here are three blog posts tailored for your event planner
                website, focusing on school nutrition.
              </p>
            </div>
            <div className="row">
              {latestBlog?.map((blog) => (
                <Blogbox
                  key={blog.id}
                  title={blog.title}
                  description={blog.sortdesc}
                  date={blog.date}
                  image={blog.img}
                  alt={blog.alt}
                  cap={blog.cap}
                  to={blog.id}
                />
              ))}
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
export default Blogs;
