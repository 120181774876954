import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { PostCallApi } from "../../../../Action/Action";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import {
  // CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useResponsiveFontSize from "../../../SuperAdmin/MemberManagement/Association/UserAssociation/useResponsiveFontSize";
import { ToastError } from "../../../Components/Toast";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};
function SchedulePayment(props) {
  const { id } = useParams();
  const { allData } = props;
  const [clientPublicKey, setClientPublicKey] = useState();
  // const [paymentId, setPaymentId] = useState();
  const [subtotal, setSubTotal] = useState("");
  const [payment, setPayment] = useState();

  const [allInVData, setAllInVData] = useState();
  const { setValue } = useForm();
  let nav = useNavigate();
  useEffect(() => {
    if (allData) {
      setValue("amount", allData?.amount);
      setSubTotal(allData?.amount);
      setAllInVData(allData);
    }
    // eslint-disable-next-line
  }, [allData]);
  const handlePay = () => {
    setPayment(true);
    PCSec();
  };

  const PCSec = async () => {
    let seData = {
      url: `/api/Payment/GetPaymentKeysByConferenceId?conferenceId=${id}`,
      body: {},
    };
    const res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res?.data.success) {
        const clientPub = res?.data?.payload?.publicKey;
        setClientPublicKey(clientPub);
      } else {
        ToastError(res?.data.message);
      }
    } else {
      ToastError(res?.data.message);
    }
  };
  const stripePromise = loadStripe(clientPublicKey);
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [dis, setDis] = useState(false);
    const options = useOptions();
    const [derror, setDerror] = useState();
    useEffect(() => {
      setTimeout(() => {
        loadCaptchaEnginge(6, "black", "white");
      }, 200);
    }, []);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const handleSubmitStripe = async (data) => {
      setDerror({ h_er: "" });

      if (validateCaptcha(data?.captcha, false) === true) {
        loadCaptchaEnginge(6, "black", "white");
        if (!stripe || !elements) {
          return;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data?.cardName,
            address: { postal_code: data?.zip ? data?.zip : null },
          },
          // card: elements.getElement(CardElement),
        });

        if (error) {
          // handle payment error
        } else {
          const sourceId = paymentMethod.id;

          setDis(true);
          let seData = {
            url: `/api/AttendeeSchedule/PayPaidEventForAttendeeSchedule`,
            body: { ...allInVData, PaymentMethodId: sourceId },
          };

          let res = await PostCallApi(seData);
          if (res?.status === 200) {
            if (res?.data?.isPaid) {
              nav(-1);
              //
            } else {
              ToastError(res?.data.message);
            }
          } else {
            ToastError(res?.data.message);
          }
        }
      } else {
        setDerror({ h_er: "Captcha does not match." });
      }
    };

    return (
      <form onSubmit={handleSubmit(handleSubmitStripe)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Name On Card</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter name on card"
                {...register("cardName", {
                  required: "This field is required",
                })}
              />
              {errors.cardName && (
                <span role="alert" className="error_text">
                  {errors.cardName.message}
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 "
            // style={{ padding: "0.5rem", border: "1px solid gainsboro" }}
          >
            <div className="form-group">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="form-control" options={options} />
            </div>
            {/* <CardElement options={CARD_OPTIONS} /> */}
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label"> Expiration date</label>
              <CardExpiryElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="form-group">
              <label className="form-label">CVC</label>
              <CardCvcElement className="form-control" options={options} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code</label>{" "}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  // required: "This field is required",
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors.zip && (
                <span role="alert" className="error_text">
                  {errors.zip.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-auto">
            <div className="captcha_box ">
              <LoadCanvasTemplate />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Captcha</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Captcha"
                {...register("captcha", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  maxLength: {
                    value: 6,
                    message: "Max 6 characters",
                  },
                })}
              />
              {errors?.captcha && (
                <span role="alert" className="d-block error_text">
                  {errors.captcha.message}
                </span>
              )}
              {derror?.h_er && (
                <span role="alert" className="d-block error_text">
                  {derror.h_er}
                </span>
              )}
            </div>
          </div>
        </div>
        <Button className="common_btn" type="submit" disabled={!stripe || dis}>
          Complete Payment
        </Button>
      </form>
    );
  };

  return (
    <>
      <div className="main_wrapper">
        <div className="card">
          <div className="card-body">
            <div className="title attend-title mb-3">Payment Details</div>
            <div className="col-md-6">
              <div className="conference_details border-bottom-0 mb-4">
                <ul>
                  <li>
                    Fees
                    <span>{allData?.amount}</span>
                  </li>{" "}
                </ul>
                <div className="subtotal">
                  <p>
                    Total <span>{subtotal ? subtotal : "0.00"}</span>
                  </p>
                </div>

                <div className=" text-end ">
                  <div className="mt-3 ">
                    {!payment && (
                      <Button className="common_btn" onClick={handlePay}>
                        Pay
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {payment ? (
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SchedulePayment;
