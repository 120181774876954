import { useDispatch, useSelector } from "react-redux";
import {
  selectAssociation,
  selectIndustry,
  selectLEA,
  selectStateAgency,
} from "../../../reducers/AssociationMember";
import Select, { components } from "react-select";
import { customFilter } from "../CustomFilterForReactSelect";
import {
  fetchDataAgency,
  fetchDataAssociation,
  fetchDataIndustry,
  fetchDataLEA,
} from "../../../Action/AssociationMember";
import { useEffect } from "react";

export const IndustryDrop = (props) => {
  const {
    isMulti,
    selectedIndustry,
    handleChangeIndustry,
    hideLabel,
    GrpClass,
    cmp,
    defaultSelected,
  } = props;
  const industryList = useSelector(selectIndustry);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (industryList.status === "idle" && !industryList.loading) {
      dispatch(fetchDataIndustry(LogDetails?.userId));
    } else {
      if (defaultSelected) {
        if (industryList?.data?.length > 0)
          handleChangeIndustry(industryList?.data[0]);
      }
    }
  }, [industryList.status, dispatch]);
  const CustomMultiValueRemove = (props) => {
    const { data, cmp } = props;
    // const { data, innerProps } = props;
    // Conditionally render the remove button based on the item value
    if (data.value === cmp) {
      return null; // Do not render the remove button for the fixed item
    }

    return <components.MultiValueRemove {...props} />;
  };
  const component = {
    MultiValueRemove: (props) => (
      <CustomMultiValueRemove {...props} cmp={cmp} />
    ),
  };
  return (
    <>
      <div className={`form-group ${GrpClass && GrpClass}`}>
        {!hideLabel && <label className="form-label">Industry/Company</label>}
        <Select
          isMulti={isMulti === false ? false : true}
          name="industry"
          options={industryList?.data ?? []}
          className={`company-multi-select `}
          classNamePrefix="select"
          placeholder="Select Industry/Company"
          filterOption={customFilter}
          value={
            industryList?.data?.filter((obj) =>
              selectedIndustry?.includes(obj.value)
            ) ?? []
          }
          onChange={handleChangeIndustry}
          components={cmp ? component : {}}
        />
      </div>
    </>
  );
};

export const LeaDrop = (props) => {
  const { isMulti, selectedLea, handleChangeLea, hideLabel } = props;
  const leaList = useSelector(selectLEA);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (leaList.status === "idle" && !leaList.loading) {
      dispatch(fetchDataLEA(LogDetails?.userId));
    }
  }, [leaList.status, dispatch]);

  return (
    <>
      <div className="form-group">
        {!hideLabel && <label className="form-label">LEA </label>}
        <Select
          isMulti={isMulti === false ? false : true}
          name="lea"
          options={leaList?.data ?? []}
          className="company-multi-select"
          classNamePrefix="select"
          placeholder="Select LEA"
          filterOption={customFilter}
          value={
            leaList?.data?.filter((obj) => selectedLea?.includes(obj.value)) ??
            []
          }
          onChange={handleChangeLea}
        />
      </div>
    </>
  );
};

export const StateAgencyDrop = (props) => {
  const { isMulti, selectedAgency, handleChangeAgency, hideLabel } = props;
  const agencyList = useSelector(selectStateAgency);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (agencyList.status === "idle" && !agencyList.loading) {
      dispatch(fetchDataAgency(LogDetails?.userId));
    }
  }, [agencyList.status, dispatch]);

  return (
    <>
      <div className="form-group">
        {!hideLabel && <label className="form-label">State Agencies</label>}
        <Select
          isMulti={isMulti === false ? false : true}
          name="Agency"
          options={agencyList?.data ?? []}
          className="company-multi-select"
          classNamePrefix="select"
          placeholder="Select State Agencies"
          filterOption={customFilter}
          value={
            agencyList?.data?.filter((obj) =>
              selectedAgency?.includes(obj.value)
            ) ?? []
          }
          onChange={handleChangeAgency}
        />
      </div>
    </>
  );
};

export const AssociationDrop = (props) => {
  const { isMulti, selectedAssociation, handleChangeAssociation, hideLabel } =
    props;
  const associationList = useSelector(selectAssociation);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (associationList.status === "idle" && !associationList.loading) {
      dispatch(fetchDataAssociation(LogDetails?.userId));
    }
  }, [associationList.status, dispatch]);

  return (
    <>
      <div className="form-group">
        {!hideLabel && <label className="form-label">Association</label>}
        <Select
          isMulti={isMulti === false ? false : true}
          name="Association"
          options={associationList?.data ?? []}
          className="company-multi-select"
          classNamePrefix="select"
          placeholder="Select Assocciation"
          filterOption={customFilter}
          value={
            associationList?.data?.filter((obj) =>
              selectedAssociation?.includes(obj.value)
            ) ?? []
          }
          onChange={handleChangeAssociation}
        />
      </div>
    </>
  );
};
