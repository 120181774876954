import React, { Suspense, lazy } from "react";
import "./index.scss";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { Config } from "../../Utils/Config";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { GetCallApi } from "../../Action/Action";
const EmailTogglebutton = lazy(() => import("./EmailTogglebutton"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function EmailPreference() {
  const [mentionComment, setMentionComment] = useState(false);
  const [recieveMessage, setRecieveMessage] = useState(false);
  const [connectionRequestS, setConnectionRequestS] = useState(false);
  const [connectionRequestA, setConnectionRequestA] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [blockPost, setBlockPost] = useState(false);
  const [blockPostDelete, setBlockPostDelete] = useState(false);
  const [crPost, setCrPost] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogUserd = useSelector((state) => state.login?.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    document.title = "FIG Events | Email Preference";
    getEmailTDetail();
    // eslint-disable-next-line
  }, []);
  const getEmailTDetail = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/EmailPreference/GetEmailPreferenceByuserId?userId=${LogUserd?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let epd = res?.data.payload;
        setMentionComment(epd?.whenSomeoneMentionsThemInCommentOrPost);
        // setCompleteCourse(epd?.whenTheyCompleteIscourse);
        setRecieveMessage(epd?.whenTheyIsRecevieMessage);
        setConnectionRequestS(epd?.whenSomeoneSendsThemIsConnectionRequest);
        setConnectionRequestA(epd?.whenSomeoneAcceptsTheirIsConnectionRequest);
        setChangePassword(epd?.whenTheyChangeTheirIsPassword);
        setBlockPost(epd?.whenSomeOneBlockPost);
        setBlockPostDelete(epd?.whenTheyBlockPostDelete);
        // setNewCourse(epd?.whenSomeOneSendAssignCourseToCreated);
        // setAssignCourse(epd?.whenSomeOneSendAssignCourseToUser);
        // setCsasIns(epd?.whenTheyAssignedCourseInstructore);
        // setCeIns(epd?.whenTheyCertificateEarnedInstructore);
        // setCeUser(epd?.whenTheyCertificateEarnedUser);
        // setCexIns(epd?.whenTheyCertificateExpiredInstructore);
        // setCexUser(epd?.whenTheyCertificateExpiredUser);
        // setDuedIns(epd?.whenTheyCourseDueDateExpiredInstructore);
        // setDuedUser(epd?.whenTheyCourseDueDateExpiredUser);
        // setCertificateCreate(epd?.whenTheyCreateCertificate);
        // setCrCourse(epd?.whenTheyCreateCourse);
        setCrPost(epd?.whenTheyCreateIsPost);
        // setCexpIns(epd?.whenTheyExpiringCertificateEnteredInstructore);
        // setCexpUser(epd?.whenTheyExpiringCertificateEnteredUser);
        // setStartCourse(epd?.whenTheyStartCourse);
        // setUpdCourse(epd?.whenTheyUpdateCourse);
        // setCexrIns(epd?.whentheyReminderExpiringCertificateInstructore);
        // setCexrUser(epd?.whentheyReminderExpiringCertificateTeamMember);
        // setCsasUser(epd?.whenTheyCourseAssignedUser);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const onSubmit = () => {
    let Em = {
      userId: LogUserd?.userId,
      whenSomeoneMentionsThemInCommentOrPost: mentionComment,
      whenTheyIsRecevieMessage: recieveMessage,
      whenSomeoneSendsThemIsConnectionRequest: connectionRequestS,
      whenSomeoneAcceptsTheirIsConnectionRequest: connectionRequestA,
      whenTheyChangeTheirIsPassword: changePassword,
      whenSomeOneBlockPost: blockPost,
      whenTheyBlockPostDelete: blockPostDelete,
      whenTheyCreateIsPost: crPost,
      createdBy: LogUserd?.userId,
    };
    setOpenCircle(true);
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/EmailPreference/CreateUpdateEmailPreference`,
        Em,
        { headers }
      )
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data.success) {
            setOpenCircle(false);

            Swal.fire({
              position: "top-end",
              width: 400,
              text: titleCase(res?.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            getEmailTDetail();
          } else {
            setOpenCircle(false);
          }
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="email_preference main_backcolor">
          <div className="main_wrapper">
            <div className="title mb-3">Email Preference</div>
            <div className="row">
              <div className="col-md-12">
                <div className="email_preference_wrapper">
                  <div className="email_preference_section">
                    <div className="title">Activity Feed</div>
                    <div className="column_count">
                      <div className="preference_content">
                        <div className="text">
                          <h4>Receive Notification when create post</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={crPost ? "1" : "0"}
                            onToggleBtn={setCrPost}
                          />
                        </div>
                      </div>
                      <div className="preference_content">
                        <div className="text">
                          <h4>A member mentions you in comment or post</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={mentionComment ? "1" : "0"}
                            onToggleBtn={setMentionComment}
                          />
                        </div>
                      </div>
                      <div className="preference_content">
                        <div className="text">
                          <h4>Some one Block Post</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={blockPost ? "1" : "0"}
                            onToggleBtn={setBlockPost}
                          />
                        </div>
                      </div>
                      <div className="preference_content">
                        <div className="text">
                          <h4>Block Post Delete</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={blockPostDelete ? "1" : "0"}
                            onToggleBtn={setBlockPostDelete}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="email_preference_section">
                    <div className="title">Messages</div>
                    <div className="column_count">
                      <div className="preference_content">
                        <div className="text">
                          <h4>A member sends you a new message</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={recieveMessage ? "1" : "0"}
                            onToggleBtn={setRecieveMessage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="email_preference_section">
                    <div className="title">Connections</div>
                    <div className="column_count">
                      <div className="preference_content">
                        <div className="text">
                          <h4>A member invites you to connect</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={connectionRequestS ? "1" : "0"}
                            onToggleBtn={setConnectionRequestS}
                          />
                        </div>
                      </div>
                      <div className="preference_content">
                        <div className="text">
                          <h4>A member accepts your connection request</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={connectionRequestA ? "1" : "0"}
                            onToggleBtn={setConnectionRequestA}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="email_preference_section">
                    <div className="title">Password</div>
                    <div className="column_count">
                      <div className="preference_content">
                        <div className="text">
                          <h4>When Password Change</h4>
                        </div>
                        <div className="switcher">
                          <EmailTogglebutton
                            disabled={false}
                            offstyle="btn-danger"
                            onstyle="btn-success"
                            checked={changePassword ? "1" : "0"}
                            onToggleBtn={setChangePassword}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="email_preference_section p-3 d-flex justify-content-end">
                    <Button className="common_btn" onClick={onSubmit}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default React.memo(EmailPreference);
