import React, { useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import "./index.scss";
// import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PostCallApi } from "../../../Action/Action";
import { DeleteDataFor } from "../../../reducers/Common";
// import { Link } from "react-router-dom";
// import { IoIosCloseCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Config } from "../../../Utils/Config";
import { ToastError, ToastSuccess } from "../Toast";
import { customFilter } from "../CustomFilterForReactSelect";
import Select from "react-select";
import { IndustryDrop } from "../AssociationMember";

const Not_Found = "/assets/images/Image_not_available.jpg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",

  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export default function DetailShowPopup(props) {
  const {
    openbox,
    closebox,
    item,
    approvBooth,
    canEdit,
    gotoBtn,
    allBooth,
    getBox,
    evD,
    // LoadPop,
  } = props;

  const logDetail = useSelector((state) => state.login.LoginDetails);

  const { id } = useParams();
  const { state } = useLocation();
  const confId = id || state?.conferenceId;
  const [conId, setConFId] = React.useState();
  const [LoadPop, setLoadPop] = React.useState(false);
  const [companySelectForView, setCompanySelectForView] = React.useState(false);

  useEffect(() => {
    if (confId) {
      setConFId(confId);
    }
    // eslint-disable-next-line
  }, [confId]);
  useEffect(() => {
    if (openbox) {
      handleOpen();
    } else {
      handleClose();
    }
    // eslint-disable-next-line
  }, [openbox]);
  const [open, setOpen] = React.useState(false);
  const [move, setMove] = React.useState(false);
  const [moveComVal, setMoveComVal] = React.useState();
  // const [comfatch, setComfatch] = React.useState(false);
  const [moveBoothNo, setMoveBoothNo] = React.useState();
  const [moveBoothNoArr, setMoveBoothNoArr] = React.useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [derror, setDerror] = React.useState();
  // const [companyList, setCompanyList] = React.useState();

  const handleOpen = () => {
    setOpen(true);
    setCompanySelectForView(item?.companyD[0]);
  };
  const handleViewCompany = (e) => {
    let cv = item?.companyD?.find((item) => item.value === e) ?? "";

    setCompanySelectForView(cv);
  };

  const handleOpen1 = (e) => {
    setMoveComVal(e);
    setMove(true);
    setOpen(false);
    if (e !== "move") {
      GetCompanyRegistrationList();
    }
  };

  const handleClose = () => {
    setOpen(false);
    closebox(false);
  };

  const handleClose1 = () => {
    setDerror();
    closebox(false);
    setMove(false);
    setMoveBoothNo();
    setMoveBoothNoArr([]);
  };
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  let dispatch = useDispatch();
  let newBooth = allBooth
    ?.filter((x) => x?.type !== "wall")
    ?.filter((x) => x?.type !== "door")
    ?.filter((x) => x?.status !== "Approve")
    ?.filter((x) => x?.status !== "InProgress");
  // ?.filter((x) => x?.status !== "InProgress")
  // ?.filter((x) => x?.height === item?.height)
  // ?.filter((x) => x?.width === item?.width);
  const moveBooth = async () => {
    if (
      moveBoothNo?.length === 0 ||
      moveBoothNo?.length === undefined ||
      moveBoothNo?.length === ""
    ) {
      setDerror("This field is required");
    } else {
      setDerror("");
      setOpenCircle(true);
      let seData = {
        replaceBoothId: moveBoothNo,
        currentBoothId: item?.value,
        exhibitorId: item?.exhibitorsId,
        conferenceId: conId,
        createdBy: logDetail?.userId,
        boothBookingPaymentId: item?.boothBookingPaymentId,
        boothId: item?.value,
        companyId: moveBoothNo,
        currentCompanyIds: item?.companyD?.map((x) => x?.value),
        removeCurrentCompanyIds: item?.companyD
          ?.filter((x) => !moveBoothNoArr?.includes(x?.value))
          ?.map((x) => x?.value),
        replacedCompanyList: moveBoothNoArr?.map((x) => {
          if (x === moveBoothNo) {
            return { companyId: x, isFloorPlanDisplayCompany: true };
          }
          return { companyId: x, isFloorPlanDisplayCompany: false };
        }),
      };

      let apiUrl = {
        url:
          moveComVal === "move"
            ? `/api/ExhibitorBoothAllocation/MoveExhibitorBooth`
            : `/api/ExhibitorBoothAllocation/UpdateBoothCompanyAllocationsAfterRegistration`,
        body: seData,
      };

      const resp = await PostCallApi(apiUrl);
      setOpenCircle(false);
      if (resp?.status === 200 && resp?.data?.success) {
        setMoveBoothNo();
        let msg = resp?.data?.message;
        dispatch(DeleteDataFor(deleteInfo ? false : true));
        ToastSuccess(msg);
        handleClose1();
        getBox();
      } else {
        ToastError(resp?.data?.message);
      }
    }
  };
  const CancelBooth = async () => {
    setDerror("");
    setOpenCircle(true);
    let seData = {
      boothNumber: item?.label,
      boothId: item?.value,
      exhibitorId: item?.exhibitorsId,
      conferenceId: conId,
      createdBy: logDetail?.userId,
    };

    let apiUrl = {
      url: `/api/ExhibitorBoothAllocation/CancelExhibitorBooth`,
      body: seData,
    };

    const resp = await PostCallApi(apiUrl);
    setOpenCircle(false);
    if (resp?.status === 200 && resp?.data?.success) {
      setMoveBoothNo();
      let msg = resp?.data?.message;
      dispatch(DeleteDataFor(deleteInfo ? false : true));
      ToastSuccess(msg);
      handleClose1();
      getBox();
    } else {
      ToastError(resp?.data?.messagee);
    }
  };
  const GetCompanyRegistrationList = async () => {
    setMoveBoothNo(item?.companyD[0]?.value);
    setMoveBoothNoArr(item?.companyD?.map((x) => x?.value) || []);
  };
  const approvBoothCompany = async (uid, status) => {
    setLoadPop(true);
    await approvBooth(uid, status);
    setLoadPop(false);
  };
  const handleAddComp = (e) => {
    setMoveBoothNo(e?.value);
    let smar = moveBoothNoArr?.filter((x) => x !== e?.value);
    setMoveBoothNoArr([...smar, e?.value]);
  };

  const handlemoveboothAr = (e) => {
    let ar = Array.isArray(e)
      ? e?.length > 0
        ? e?.map((x) => x?.value)
        : moveBoothNo
        ? [moveBoothNo]
        : []
      : [];
    if (moveBoothNo) {
      if (ar?.find((x) => x !== moveBoothNo)) {
        ar.push(moveBoothNo);
      }
    }
    let nAR =
      ar?.length > 0
        ? ar.filter((item, index) => ar.indexOf(item) === index)
        : [];
    setMoveBoothNoArr(nAR);
  };

  return (
    <>
      {open && (
        <>
          <Modal
            sx={{ zIndex: 9 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_show_data"
          >
            <Box sx={style} className="show_data_box">
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={LoadPop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="popup_show_detail_box">
                <h6 className="d-flex justify-content-between">
                  {props?.title}{" "}
                  <RxCross2
                    style={{ cursor: "pointer" }}
                    fontSize={28}
                    color="#616161"
                    onClick={handleClose}
                  />
                </h6>
                <div className={`popup_show_detail`}>
                  <div className="exhibitor_deatil">
                    <div className="img_box">
                      <img
                        src={
                          companySelectForView?.companyLogo
                            ? Config.API_HOST_URL_live +
                              companySelectForView?.companyLogo
                            : item?.exhibitorProfileImage
                            ? Config.API_HOST_URL_live +
                              item?.exhibitorProfileImage
                            : Not_Found
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = Not_Found;
                        }}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="name_company ">
                      <div className=" d-flex justify-content-between">
                        {item?.companyD?.length > 0 ? (
                          <div className="d-flex align-items-center text_grey w-75">
                            <img
                              src="/assets/icons/company_logo.svg"
                              className="me-2"
                              alt=""
                            />
                            {item?.companyD?.length > 1 ? (
                              <Select
                                isMulti={false}
                                name="colors"
                                options={item?.companyD}
                                // isClearable={true}
                                className="dropdown-single-select w-100"
                                classNamePrefix="select"
                                placeholder="Select Company"
                                // menuIsOpen={true}
                                filterOption={customFilter}
                                value={item?.companyD?.filter(
                                  (obj) =>
                                    obj.value === companySelectForView?.value
                                )}
                                onChange={(e) => handleViewCompany(e?.value)}
                              />
                            ) : (
                              <div className="user_name ms-1">
                                {companySelectForView?.companyName}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {
                          props?.hideButton ? (
                            <Link
                              to={`/conference-floor/app/${conId}/exhibitorId=${companySelectForView?.companyId}/message`}
                              className="chat_img"
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src="/assets/icons/exhibitor_Chat.svg"
                                alt=""
                              />
                            </Link>
                          ) : (
                            // companySelectForView?.isSubscription ? (
                            <Link
                              className="chat_img"
                              to={`/exhibitor-profile`}
                              // to={`/exhibitor-profile/${item?.exhibitorsId}/${id}/message`}
                              state={{
                                exhibitorId: companySelectForView?.companyId,
                                conferenceId: conId,
                                req: "message",
                              }}
                            >
                              <img
                                src="/assets/icons/exhibitor_Chat.svg"
                                alt=""
                              />
                            </Link>
                          )
                          // ) : item?.companyD?.length > 0 ? (

                          //   <span disabled className=" grey p-0">
                          //     <img
                          //       src="/assets/icons/ExhibitorChat_grey.svg"
                          //       alt=""
                          //     />
                          //   </span>
                          // ) : (
                          //   ""
                          // )
                        }
                      </div>
                      <div className="f-16-600 mt-2 text_grey">
                        {companySelectForView?.contactName
                          ? companySelectForView?.contactName
                          : item?.exhibitorName}
                      </div>
                      <div className="f-14-400 text_grey">
                        {companySelectForView?.addressLine1 ? "Address: " : ""}
                        {companySelectForView?.addressLine1}{" "}
                        {companySelectForView?.addressLine2
                          ? ", " + companySelectForView?.addressLine2 + ", "
                          : ""}
                        {companySelectForView?.city
                          ? companySelectForView?.city + ", "
                          : ""}
                        {companySelectForView?.state}
                        {companySelectForView?.zip
                          ? " - " + companySelectForView?.zip
                          : ""}
                      </div>

                      <div className="f-14-400 text_grey">
                        {companySelectForView?.companyTitle?.slice(0, 235)}
                        {companySelectForView?.companyTitle?.length > 235
                          ? "..."
                          : ""}
                      </div>
                      {/* <div className="f-14-400 text_grey">
                        {item?.exhibitorTitle}
                      </div>

                      <div className="text_grey f-14-400 ">
                        {item?.exhibitorDescription?.slice(0, 235)}
                        {item?.exhibitorDescription?.length > 235 ? "..." : ""}
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="show_sideby">
                    <p className="left_text">Company Name :</p>
                    <p className="right_text">
                      {item?.companyName
                        ? item?.companyName
                        : item?.exhibitorName}
                    </p>
                  </div>
                  <div className="show_sideby">
                    <p className="left_text">Email :</p>
                    <p className="right_text">
                      {item?.companyEmail ? item?.companyEmail : item?.emailId}
                    </p>
                  </div>
                  <div className="show_sideby">
                    <p className="left_text">Phone Number :</p>
                    <p className="right_text">
                      {item?.companyPhone
                        ? item?.companyPhone
                        : item?.phoneNumber}
                    </p>
                  </div> */}
                  {/* <div className="d-flex">
                    <p className="left_text">Type :</p>
                    <p className="right_text">{item?.type}</p>
                  </div>
                  {item?.type === "company" ? (
                    <div className="d-flex">
                      <p className="left_text">Company Name :</p>
                      <p className="right_text">{item?.companyName}</p>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <p className="left_text">Title :</p>
                      <p className="right_text">{item?.title}</p>
                    </div>
                  )} */}
                  {gotoBtn ? (
                    <div className="col-md-12 button_box text-end  ">
                      {/* // <div className="col-md-12 button_box   "> */}
                      {/* <div className="d-flex"> */}
                      {/* <span className="ms-auto"></span> */}
                      {canEdit ? (
                        <>
                          {item?.status === "InProgress" ? (
                            <>
                              {evD?.conferenceEnddate ? (
                                evD?.conferenceEnddate?.split("T")[0] >=
                                new Date()?.toISOString()?.split("T")[0] ? (
                                  <Button
                                    onClick={() => handleOpen1("updateCompany")}
                                    className="new_orange-btn  me-3"
                                    style={{ textDecoration: "none" }}
                                  >
                                    Update Booth Company
                                  </Button>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {/* <div className="col-md-12 button_box text-end mt-3"> */}
                              <Button
                                className="common_btn"
                                onClick={() =>
                                  approvBoothCompany(
                                    item?.exhibitorsId,
                                    "Approve"
                                  )
                                }
                              >
                                Approve
                              </Button>
                              <Button
                                className="orange-btn ms-3"
                                onClick={() =>
                                  approvBoothCompany(
                                    item?.exhibitorsId,
                                    "Reject"
                                  )
                                }
                              >
                                Reject
                              </Button>
                              {/* </div> */}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {props?.hideButton ? (
                        // item?.userType === "Exhibitor" ||
                        // item?.userType === "Admin" ? (
                        <div className="common_btn">
                          {/* {companySelectForView?.isSubscription ? ( */}
                          <Link
                            to={`/conference-floor/app/${conId}/exhibitorId=${companySelectForView?.companyId}/req`}
                            className="back_button me-3"
                            style={{ textDecoration: "none" }}
                          >
                            Req a Call
                          </Link>
                          {/* ) : (
                            <Button
                              disabled
                              className="back_button me-3"
                              style={{ textDecoration: "none" }}
                            >
                              Req a Call
                            </Button>
                          )} */}
                          <Link
                            to={`/conference-floor/app/${conId}/exhibitorId=${companySelectForView?.companyId}`}
                            className="common_btn"
                            // reloadDocument
                            style={{ textDecoration: "none" }}
                          >
                            View Profile
                          </Link>
                        </div>
                      ) : // ) : (
                      //   ""
                      // )
                      canEdit && item?.status === "Approve" ? (
                        <>
                          {/* <div> */}
                          {evD?.conferenceEnddate ? (
                            evD?.conferenceEnddate?.split("T")[0] >=
                            new Date()?.toISOString()?.split("T")[0] ? (
                              <>
                                <Link
                                  onClick={() => handleOpen1("updateCompany")}
                                  className="new_orange-btn  me-2"
                                  style={{ textDecoration: "none" }}
                                >
                                  Update Booth Company
                                </Link>
                                <Link
                                  onClick={() => handleOpen1("move")}
                                  className="new_orange-btn  me-2"
                                  style={{ textDecoration: "none" }}
                                >
                                  Move Booth
                                </Link>{" "}
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {/* {item?.exhibitorsId !== logDetail?.userId ? (
                            item?.userType === "Exhibitor" ||
                            item?.userType === "Admin" ? ( */}
                          {/* {companySelectForView?.isSubscription ? ( */}
                          <Link
                            to={`/exhibitor-profile`}
                            // to={`/exhibitor-profile/${item?.exhibitorsId}/${id}/req`}
                            state={{
                              exhibitorId: companySelectForView?.companyId,
                              conferenceId: conId,
                              req: "req",
                            }}
                            className="new_back_button me-2"
                            style={{ textDecoration: "none" }}
                          >
                            Req a Call
                          </Link>
                          {/* ) : (
                            <span
                              className="new_back_button disabled_b me-3"
                              style={{ textDecoration: "none" }}
                            >
                              Req a Call
                            </span>
                          )} */}
                          {/* ) : (
                              ""
                            )
                          ) : (
                            ""
                          )} */}
                          {/* {item?.userType === "Exhibitor" ||
                          item?.userType === "Admin" ? ( */}
                          <Link
                            to={`/exhibitor-profile`}
                            state={{
                              exhibitorId: companySelectForView?.companyId,
                              conferenceId: conId,
                              req: "",
                            }}
                            className="New_theme_button"
                            style={{ textDecoration: "none" }}
                          >
                            View Profile
                          </Link>
                          {/* ) : (
                            ""
                          )} */}
                          {/* </div> */}
                          {evD?.conferenceEnddate ? (
                            evD?.conferenceEnddate?.split("T")[0] >=
                            new Date()?.toISOString()?.split("T")[0] ? (
                              <Button
                                sx={{ marginTop: "-2px" }}
                                className="new_orange-btn  ms-2"
                                disabled={openCircle}
                                onClick={() => CancelBooth(item)}
                              >
                                {openCircle ? (
                                  <CircularProgress
                                    sx={{
                                      color: "#fff",
                                      marginLeft: "0.5rem",
                                      height: "23px !important",
                                      width: "23px !important",
                                    }}
                                  />
                                ) : (
                                  "Cancel Booth"
                                )}
                              </Button>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ) : item?.status === "Approve" ? (
                        <>
                          {item?.exhibitorsId === logDetail?.userId ? (
                            evD?.conferenceEnddate ? (
                              evD?.conferenceEnddate?.split("T")[0] >=
                              new Date()?.toISOString()?.split("T")[0] ? (
                                <Link
                                  onClick={() => handleOpen1("updateCompany")}
                                  className="new_orange-btn  me-2"
                                  style={{ textDecoration: "none" }}
                                >
                                  Update Booth Company
                                </Link>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {/* {item?.userType === "Exhibitor" ||
                          item?.userType === "Admin" ? (
                            <> */}
                          {/* {item?.exhibitorsId !== logDetail?.userId ? ( */}
                          {/* {companySelectForView?.isSubscription ? ( */}
                          <Link
                            to={`/exhibitor-profile`}
                            className="new_back_button me-3"
                            style={{ textDecoration: "none" }}
                            state={{
                              exhibitorId: companySelectForView?.companyId,
                              conferenceId: conId,
                              req: "req",
                            }}
                          >
                            Req a Call
                          </Link>
                          {/* ) : (
                            <span
                              className="new_back_button disabled_b me-3"
                              style={{ textDecoration: "none" }}
                            >
                              Req a Call
                            </span>
                          )} */}
                          {/* ) : (
                                ""
                              )} */}
                          <Link
                            to={`/exhibitor-profile`}
                            state={{
                              exhibitorId: companySelectForView?.companyId,
                              conferenceId: conId,
                              // req: "",
                            }}
                            className="New_theme_button"
                            style={{ textDecoration: "none" }}
                          >
                            View Profile
                          </Link>
                          {/* </>
                          ) : (
                            ""
                          )} */}
                        </>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
      {move && (
        <>
          <Modal
            sx={{ zIndex: 9 }}
            open={move}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_show_data"
          >
            <Box sx={style} className="show_data_box">
              <div className="popup_show_detail_box">
                <h6 className="d-flex justify-content-between">
                  {moveComVal === "move"
                    ? "Move Company Booth"
                    : "Update Booth Company"}
                  <RxCross2
                    style={{ cursor: "pointer" }}
                    fontSize={28}
                    color="#616161"
                    onClick={handleClose1}
                  />
                </h6>
                <div className="popup_show_detail">
                  <div className={`ex_details`}>
                    {item?.companyD?.length > 0
                      ? item?.companyD?.map((x, i) => (
                          <div className="d-flex" key={x?.value}>
                            <p className="left_text">{i + 1}. Company Name :</p>
                            <p className="right_text">
                              {x?.companyName ? x?.companyName : ""}
                              {x?.isFloorPlanDisplayCompany === true
                                ? " - Main"
                                : ""}
                            </p>
                          </div>
                        ))
                      : ""}
                    <div className="d-flex">
                      <p className="left_text">Exhibitor Name :</p>
                      <p className="right_text">
                        {item?.exhibitorName ? item?.exhibitorName : ""}
                      </p>
                    </div>{" "}
                    {/* <div className="d-flex">
                      <p className="left_text">Email :</p>
                      <p className="right_text">
                        {item?.companyEmail
                          ? item?.companyEmail
                          : item?.emailId}
                      </p>
                    </div> */}
                    {/* <div className="d-flex">
                      <p className="left_text">Phone Number :</p>
                      <p className="right_text">
                        {item?.companyPhone
                          ? item?.companyPhone
                          : item?.phoneNumber}
                      </p>
                    </div> */}
                    <div className="d-flex">
                      <p className="left_text">Current Booth No : </p>
                      <p className="right_text">
                        {item?.label ? item?.label : item?.label}
                      </p>
                    </div>
                    {moveComVal === "move" ? (
                      <div className="d-flex">
                        <p className="left_text">Move Both No :</p>
                        {newBooth?.length > 0 ? (
                          <>
                            <select
                              className="form-select w-50"
                              onChange={(e) => setMoveBoothNo(e?.target?.value)}
                            >
                              <option value="">Select Booth</option>
                              {props?.evD?.priceType === "SinglePrice"
                                ? newBooth?.map((x) => {
                                    return (
                                      <option value={x?.value} key={x?.value}>
                                        {x?.label}
                                      </option>
                                    );
                                  })
                                : newBooth
                                    ?.filter((y) => y?.price === item?.price)
                                    ?.map((x) => {
                                      return (
                                        <option value={x?.value} key={x?.value}>
                                          {x?.label}
                                        </option>
                                      );
                                    })}
                            </select>
                          </>
                        ) : (
                          <p className="right_text">
                            No Booth Available for Same size
                          </p>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="d-flex mb-3">
                          <p className="left_text">Display in Booth :</p>
                          {/* {comfatch ? (
                            <p className="right_text">
                              <CircularProgress sx={{ color: "#86c65a" }} />
                            </p>
                          ) : companyList?.length > 0 ? (
                            <> */}
                          <IndustryDrop
                            isMulti={false}
                            hideLabel={true}
                            selectedIndustry={[moveBoothNo]}
                            GrpClass="w-50"
                            handleChangeIndustry={(e) => handleAddComp(e)}
                          />
                          {/* <Select
                                isMulti={false}
                                name="colors"
                                options={companyList}
                                className="dropdown-single-select w-50"
                                classNamePrefix="select"
                                placeholder="Select Company"
                                filterOption={customFilter}
                                value={companyList?.filter(
                                  (obj) => obj.value === moveBoothNo
                                )}
                                onChange={(e) => handleAddComp(e)}
                              /> */}
                          {/* </>
                          ) : (
                            <p className="right_text">No Company Available</p>
                          )} */}
                        </div>
                        <div className="d-flex">
                          <p className="left_text">Select Company in booth :</p>
                          <IndustryDrop
                            hideLabel={true}
                            selectedIndustry={moveBoothNoArr}
                            GrpClass="w-50"
                            handleChangeIndustry={(e) => handlemoveboothAr(e)}
                            cmp={moveBoothNo}
                          />
                          {/* {comfatch ? (
                            <p className="right_text">
                              <CircularProgress sx={{ color: "#86c65a" }} />
                            </p>
                          ) : companyList?.length > 0 ? (
                            <>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={companyList}
                                isClearable={true}
                                className="dropdown-single-select w-50"
                                classNamePrefix="select"
                                placeholder="Select Company"
                                filterOption={customFilter}
                                value={companyList?.filter((obj) =>
                                  moveBoothNoArr?.includes(obj.value)
                                )}
                                onChange={(e) => handlemoveboothAr(e)}
                                components={{
                                  MultiValueRemove: (props) => (
                                    <CustomMultiValueRemove
                                      {...props}
                                      cmp={moveBoothNo}
                                    />
                                  ),
                                }}
                              /> */}
                          {/* <select
                              className="form-select w-50"
                              onChange={(e) => setMoveBoothNo(e?.target?.value)}
                            >
                              <option value="">Select Company</option>
                              {companyList?.map((x) => {
                                return (
                                  <option value={x?.value} key={x?.value}>
                                    {x?.label}
                                  </option>
                                );
                              })}
                            </select> */}
                          {/*  </>
                        ) : (
                            <p className="right_text">No Company Available</p>
                          )} */}
                        </div>
                      </>
                    )}
                    {derror && (
                      <div className="d-flex">
                        <p className="left_text mb-0"> </p>
                        <p className="right_text mb-0">
                          <span role="alert" className="error_text">
                            {derror}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12  button_box text-end ">
                    {openCircle ? (
                      <Button className="common_btn">
                        Saving{" "}
                        <CircularProgress
                          sx={{
                            color: "#fff",
                            marginLeft: "0.5rem",
                            height: "23px !important",
                            width: "23px !important",
                          }}
                        />
                      </Button>
                    ) : (
                      <Button className="common_btn" onClick={moveBooth}>
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
