import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AttendeeRegistrationConference } from "../../../../reducers/Common";
import { ToastError, ToastSuccess } from "../../../Components/Toast";

function GroupInvoiceEdit(props) {
  const { companyData } = props;
  const { id } = useParams();
  const [editorValue, setEditorValue] = useState("");
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  let nav = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getInvoiceDetail(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const getInvoiceDetail = async (id) => {
    if (id) {
      setOpen(true);
      let sData = {
        url: `/api/MemberManagement/GetMemberManagementInvoiceDataByMemberManagementInvoiceId?memberManagementInvoiceId=${id}`,
        headers: headers,
      };
      const res = await GetCallApi(sData);
      setOpen(false);
      if (res?.status === 200) {
        if (res?.data.success) {
          const comList = res?.data?.payload;
          setValue(
            "memberManagementInvoiceId",
            comList?.memberManagementInvoiceId
          );
          setValue("email", comList?.email);
          setValue("name", comList?.name);
          setValue("address1", comList?.addressLine1);
          setValue("purchaseOrderNumber", comList?.purchaseOrderNumber);
          setValue("city", comList?.city);
          setValue("State", comList?.state);
          setValue("invoiceStatus", comList?.invoiceStatus);
          setValue("zipCode", comList?.zipCode);
          setEditorValue(comList?.invoiceInstructions);
        }
      }
    }
  };

  const handleEditotValue = (value) => {
    if (value?.length > 1000) {
      setDerror({ ed: "Max 1000 character" });
    } else {
      setDerror({ ed: "" });
      setEditorValue(value);
    }
  };

  const onSubmit = async (data) => {
    setOpen(true);
    let seData = {
      ...data,
      addressLine1: data?.address1,
      invoiceInstructions: editorValue,
      createdBy: LogDetails?.userId,
    };

    let apiSend = {
      url: `/api/MemberManagement/AddMemberManagementInvoice`,
      body: seData,
      headers: headers,
    };

    const resp = await PostCallApi(apiSend);
    setOpen(false);
    if (resp?.status === 200 && resp.data.success) {
      let seData1 = {
        url: `/api/MemberManagement/CreateMemberManagement`,
        body: {
          ...companyData,
          memberManagementInvoiceId:
            resp?.data?.payload?.memberManagementInvoiceId,
        },
        headers: headers,
      };
      let resv = await PostCallApi(seData1);
      if (resv?.status === 200) {
        if (resv?.data?.success) {
          dispatch(AttendeeRegistrationConference());
          nav(`/group-conference-invoice-approval/view/${id}`, {
            replace: true,
          });
        }
      }
      ToastSuccess(resp?.data.message);
    } else {
      ToastError(resp.data.message);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="">
        {/* <section className="main_invoice_create"> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="card">
            <div className="card-body"> */}
          <div className="title attend-title mb-3">Invoice Edit</div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">
                Email Invoice To
                <span className="red-color"> *</span>
              </label>

              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  {...register("email", {
                    required: "This field is require",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please Enter Valid Email",
                    },
                  })}
                />
                {errors?.email && (
                  <span className="error_text">{errors.email.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3">
              <div className="form-group mb-3">
                <div>
                  <label className="form-label">Address</label>
                </div>
                <label className="form-label">
                  Name<span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  {...register("name", {
                    required: "This field is require",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 500,
                      message: "You can enter max 500 charactor",
                    },
                  })}
                />
                {errors?.name && (
                  <span className="error_text">{errors.name.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-3 mt-auto">
              <div className="form-group  mb-3">
                <label className="form-label">
                  Address Line 1<span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address Line 1"
                  {...register("address1", {
                    required: "This field is require",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                  })}
                />
                {errors?.address1 && (
                  <span className="error_text">{errors.address1.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-auto">
              <div className="form-group mb-3">
                <label className="form-label">PO Number </label>
                <input
                  type="text"
                  placeholder="Enter PO Number"
                  className="form-control"
                  {...register("purchaseOrderNumber", {
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                  })}
                />
                {errors?.purchaseOrderNumber && (
                  <span className="error_text">
                    {errors.purchaseOrderNumber.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group mb-3">
                <label className="form-label">
                  City<span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                  {...register("city", {
                    required: "This field is require",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 100,
                      message: "You can enter max 100 charactor",
                    },
                  })}
                />
                {errors?.city && (
                  <span className="error_text">{errors.city.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group mb-3">
                <label className="form-label">
                  State<span className="red-color"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter State"
                  {...register("State", {
                    required: "This field is require",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 100,
                      message: "You can enter max 100 charactor",
                    },
                  })}
                />
                {errors?.State && (
                  <span className="error_text">{errors.State.message}</span>
                )}
              </div>
            </div>{" "}
            <div className="col-md-3">
              <div className="form-group mb-3">
                <label className="form-label">
                  Zip Code
                  <span className="red-color"> *</span>
                </label>
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  placeholder="Enter Zip Code"
                  {...register("zipCode", {
                    required: "This field is require",
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Starting and Ending Space not allowed",
                    },
                    maxLength: {
                      value: 10,
                      message: "You can enter max 10 charactor",
                    },
                  })}
                />
                {errors?.zipCode && (
                  <span className="error_text">{errors.zipCode.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-12 ">
              <label className="form-label">Invoice Introduction</label>
              <ReactQuill
                value={editorValue}
                onChange={(value) => handleEditotValue(value)}
                // style={{ height: "200px", overflow: "auto" }}
                className="ql_show"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],

                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }], // superscript/subscript
                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ direction: "rtl" }], // text direction

                    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],

                    ["link", "image", "video"],
                    ["clean"],
                  ],
                }}
                theme="snow"
              />
              {derror?.ed && (
                <span role="alert" className="d-block error_text">
                  {derror.ed}
                </span>
              )}
            </div>
            <div className="col-md-12 ">
              <Button
                className="back_button mt-3 me-3"
                onClick={() => nav(-1, { replace: true })}
              >
                Back
              </Button>
              <Button className="common_btn mt-3" type="submit">
                Update
              </Button>
            </div>
          </div>
          {/* </div>
          </div> */}
        </form>
      </section>
    </>
  );
}

export default GroupInvoiceEdit;
