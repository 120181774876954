import React, { useEffect, useState } from "react";
import "./index.scss";
import event_logo from "../../../../../assets/images/fig_events.png";
import DragableBox from "../DragableBox";
import { Slider } from "@mui/material";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { Config } from "../../../../../Utils/Config";

const calendar = "/assets/icons/calendar-icon.svg";
const location = "/assets/icons/location-icon.svg";

function ShowFloorBoxBook(props) {
  const { sboothId, setSboothId } = props;
  const [zVal, setZVal] = useState(0.636);
  const handleMinus = () => {
    if (zVal > 0.143) {
      setZVal(zVal - 0.0715);
    }
  };
  const handlePlus = () => {
    if (zVal < 3.0888) {
      setZVal(zVal + 0.0715);
    }
  };
  const onScroll = (e) => {
    const delta = e.deltaY * -0.01;
    let newScale = zVal + (delta === 1 ? 0.0715 : -0.0715);
    if (newScale < 3.0888 && newScale > 0.143) {
      setZVal(newScale);
    } else {
      setZVal(zVal);
    }
  };
  function valuetext(value) {
    return `${value}°C`;
  }
  const onSlider = (e, newValue) => {
    setZVal(newValue);
  };
  const [edShow, setEdShow] = useState();
  const [showZoom, setShowZoom] = useState(false);

  const [deltaPositionSave, setDeltaPositionSave] = useState({
    x: 0,
    y: 0,
  });
  const handleDrag = (e, ui) => {
    let { x, y } = deltaPositionSave;
    let select = {
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    };
    setDeltaPositionSave(select);
  };
  const { id } = useParams();
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (id) {
      let eID = id;

      getEveDetail(eID);
    }
    // eslint-disable-next-line
  }, [id]);
  const getEveDetail = (id) => {
    Axios.get(
      `/api/ConferenceFloorPlan/GetConferenceFloorPlanDataByConferenceId?conferenceId=${id}&userId=${LogDetails?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status) {
          if (res?.data.success) {
            setEdShow(res?.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const WorkZoomIn = () => {
    setShowZoom(!showZoom);
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  return (
    <>
      <div className="zoom_buttons show">
        <button onClick={WorkZoomIn}>Zoom</button>
      </div>
      {showZoom && (
        <div className="zoom_buttons">
          <button onClick={handleMinus}>
            <img src={"/assets/icons/zoom-out.svg"} alt="" />
          </button>
          <Slider
            aria-label="zoom_in_out"
            getAriaValueText={valuetext}
            value={zVal}
            onChange={onSlider}
            step={0.0715}
            marks
            valueLabelDisplay="auto"
            min={0}
            max={3.2032}
          />
          <button onClick={handlePlus}>
            <img src={"/assets/icons/zoom-in.svg"} alt="" />
          </button>
        </div>
      )}
      <Draggable
        // bounds="parent"
        handle=".handle"
        position={{
          x: deltaPositionSave?.x,
          y: deltaPositionSave?.y,
        }}
        onDrag={handleDrag}
      >
        <div className="">
          <div
            className="  show_floor_main_approve"
            style={{
              transform: `Scale(${zVal})`,
              width: edShow?.conference?.width
                ? edShow?.conference?.width
                : "1920px",
              height: edShow?.conference?.height
                ? edShow?.conference?.height
                : "1080px",
            }}
            onWheelCapture={(e) => (showZoom ? onScroll(e) : false)}
          >
            <div className="mai_wrapper">
              {" "}
              <div className=" handle event_deatil_box c_p">
                <div className="d-flex gap-3 align-items-center">
                  <div className="logo">
                    <img
                      src={
                        edShow?.floorplan?.floorPlanLogo
                          ? `${Config.API_HOST_URL_live}${edShow?.floorplan?.floorPlanLogo}`
                          : event_logo
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="event_name_d f-20-400">
                    <div className="e_name ">
                      <span>{edShow?.conference?.conferenceName}</span>
                      {/* New York School Nutrition Association 71st Annual Conference */}
                    </div>
                    <div className="ev_date">
                      <span className=" d-flex gap-2 my-2">
                        <img src={location} height={25} alt="location" />

                        {edShow?.conference?.conferenceAddress}
                        {/* Verona, New York */}
                      </span>
                      <div className=" d-flex gap-2">
                        <img src={calendar} height={25} alt="calendar" />
                        Booking StartDate:{" "}
                        {edShow?.conference?.bookingStartDate
                          ? convert(
                              edShow?.conference?.bookingStartDate?.split(
                                "T"
                              )[0]
                            )
                          : ""}
                        {/* October 21-22-2022 */}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pe-4">
                    <h4>
                      <img src={"/assets/icons/hand_drag_head.svg"} alt="" />
                      You Can Drag From Header
                    </h4>
                  </div>
                  <div className="text_of_event book_box  f-14-400">
                    {/* <1div className="val_show"> */}
                    <div className="me-3">
                      <span className="bl booked"></span> Approved
                    </div>
                    <div className="me-3">
                      <span className="bl available"></span>Available
                    </div>
                    <div>
                      <span className="bl selected"></span>Selected
                    </div>
                    {/* <div>
                  <span className="bl mybook">7</span>My Booking
                </div> */}
                    {/* </div> */}
                  </div>
                  {/* <button className="handle common_btn">
                    Drag From here
                  </button> */}
                </div>
              </div>
              <div
                className="back_img_box"
                style={{
                  backgroundImage: edShow?.floorplan?.pdfLayout
                    ? `url(${Config.API_HOST_URL_live}${edShow?.floorplan?.pdfLayout})`
                    : require("../../../../../assets/images/fixdesign_back.png"),
                  height: edShow?.conference?.height
                    ? edShow?.conference?.height - 200
                    : "770",
                }}
              >
                <div className="box__padding">
                  <DragableBox
                    evD={edShow?.conference}
                    sboothId={sboothId}
                    setSboothId={setSboothId}
                    canEdit={
                      LogDetails?.roleId === 1
                        ? true
                        : edShow?.isAccessibleForMoveBooth
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {/* <div className="text_of_event f-14-400 ">
                <div className="des">{edShow?.des}</div>
              </div> */}
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
}

export default ShowFloorBoxBook;
