import React, { useEffect, useState } from "react";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
// import eye from "../../assets/icons/eye.svg";
// import eyeOff from "../../assets/icons/eye-off.svg";
import { ToastContainer } from "react-toastify";
import { GetCallApi, PostCallApi } from "../../Action/Action.jsx";
import {
  ToastError,
  ToastSuccess,
} from "../../Modules/Components/Toast/index.jsx";
// import { useSelector } from "react-redux";

function WithoutLoginUpdatePassword() {
  const { id } = useParams();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  let nav = useNavigate();

  // const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  // const logindetail = useSelector((state) => state.login.LoginDetails);

  // const headers = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //     Accept: "*/*",
  // };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Update Password";
    if (id) {
      UpdateUser(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const UpdateUser = async (id) => {
    let seData = {
      url: `/api/UserMst/UserEmailConfirmStatus?userId=${id}`,
    };
    await GetCallApi(seData);
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Questions/ChangePassordForNonFigUser?userId=${id}&password=${data?.newPassword}`,
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        reset();
        ToastSuccess(res?.data.message);
        setTimeout(function () {
          nav("/login");
        }, 2100);
        setDerror({ Log: "" });
      } else {
        setOpenCircle(false);

        setDerror({ Log: res?.data?.message });
        ToastError(res?.data.message);
      }
    } else {
      setOpenCircle(false);
      ToastError(res?.data.message);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="home_page_new_password">
        <div className="main_box">
          <div className="row m-0">
            <div className="col-md-12 p-0">
              <div className="inner_box">
                <h1>UPDATE PASSWORD</h1>
                <h5 className="text-center">Your email has been confirm.</h5>
                <form
                  className="d-flex flex-column"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="New Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown1 ? "text" : "password"}
                        {...register("newPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                          validate: (val) => {
                            if (watch("oldPassword") === val) {
                              return "Your old password and new password cannot be same";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown1(!passwordShown1)}
                      >
                        <img
                          src={
                            passwordShown1 === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.newPassword && (
                      <span role="alert" className="error_text">
                        {errors.newPassword.message}
                      </span>
                    )}
                  </div>{" "}
                  <div className="form-group">
                    <div className="password">
                      <TextField
                        label="Confirm Password"
                        variant="outlined"
                        name="current-password"
                        className="w-100"
                        type={passwordShown ? "text" : "password"}
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          pattern: {
                            value: /^\S+$/,
                            message: "Space not allowed",
                          },
                          validate: (val) => {
                            if (watch("newPassword") !== val) {
                              return "Password and Confirm Password does not match";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        <img
                          src={
                            passwordShown === true
                              ? "/assets/icons/eye.svg"
                              : "/assets/icons/eye-off.svg"
                          }
                          alt="eye"
                        />
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <span role="alert" className="error_text">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                    {derror?.Log && (
                      <span role="alert" className="d-block error_text">
                        {derror.Log}
                      </span>
                    )}
                  </div>
                  <input
                    type="submit"
                    name="submit"
                    className="submit_btn w-10"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WithoutLoginUpdatePassword;
