import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Smallpop from "../../Modules/Components/Smallpop";
import { Link, useNavigate } from "react-router-dom";
import "./index.scss"; // Make sure you define fade transition styles here

function AssignHeadMenu() {
  const [options, setOptions] = useState();
  const [showMenu, setShowMenu] = useState([]);
  const [mobileShowMenu, setMobileShowMenu] = useState(false);
  const [mobileShowMenuList, setMobileShowMenuList] = useState([]);
  const [mobileShowMenuId, setMobileShowMenuId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const MenuList = useSelector((state) => state.headerMenu?.LoginHeadList);
  const MenuName = useSelector((state) => state.setting?.MenuName);

  const handleClose = () => setAnchorEl(null);

  const showSub = (event, item) => {
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
      setAnchorEl(null);
      setOptions(null);
    } else if (item?.subMenu !== undefined) {
      setAnchorEl(event.currentTarget);
      setOptions(item.subMenu);
    } else {
      setAnchorEl(null);
      setOptions(null);
    }
  };

  const mobileAShowSub = (event, item) => {
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
      setMobileShowMenuList([]);
    } else if (item?.subMenu !== undefined) {
      if (mobileShowMenuId === item?.menuId) {
        // If the clicked submenu is already open, close it
        setMobileShowMenu(false);
        setMobileShowMenuId(null);
      } else {
        // If a different submenu is clicked, close the current and open the new one
        setMobileShowMenu(true);
        setMobileShowMenuId(item?.menuId);
        setMobileShowMenuList(item?.subMenu);
      }
    }
  };
  

  useEffect(() => {
    if (MenuList?.length > 0) {
      const sortedMenu = [...MenuList].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      setShowMenu(sortedMenu);
    }
  }, [MenuList]);

  const handleName = (name) => navigate(name, { replace: true });

  return (
    <>
      {showMenu?.map((item, i) => (
        <React.Fragment key={i}>
          <li
            className={`hover_link ${
              item.subMenu?.find((data) => data?.menuName === MenuName) ? `linear ${MenuName}` : ""
            }`}
            onClick={(e) =>
              window.innerWidth > 768 ? showSub(e, item) : mobileAShowSub(e, item)
            }
          >
            <Link className="hover dropdown">
              {item.menuName}
              {item?.subMenu?.length > 0 && <ArrowDropDownRoundedIcon />}
            </Link>

            <CSSTransition
              in={mobileShowMenu && mobileShowMenuId === item?.menuId}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="mobileSubmenu">
                {mobileShowMenuList?.map((submenu, i) => (
                  <div
                    className="submenu"
                    key={i}
                    onClick={() => handleName(submenu?.url)}
                  >
                    <div className="icon">
                      {submenu.menuIcon ? (
                        <i className={`fas fa-${submenu.menuIcon}`}></i>
                      ) : (
                        <i className="fas fa-bars"></i>
                      )}
                    </div>
                    <div className="bac">
                      {submenu?.menuName}
                      <span>{submenu?.menuDescription}</span>
                    </div>
                  </div>
                ))}
              </div>
            </CSSTransition>
          </li>
        </React.Fragment>
      ))}

      <Smallpop
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        optionsMenu={options}
        megamenu={options?.length >= 4 ? "megamenu" : ""}
        className="Menu_icon_settings"
      />
    </>
  );
}

export default React.memo(AssignHeadMenu);
