import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
  Modal,
  Box,
  Backdrop,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { Link } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { SwalMessage, ToastError, ToastSuccess } from "../../Components/Toast";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import TableHeader from "../../../TableHeader.json";
import { VscEdit } from "react-icons/vsc";
import {
  IndustryDrop,
  LeaDrop,
  StateAgencyDrop,
} from "../../Components/AssociationMember";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));
// const PaymentTable = lazy(() =>
//   import("../../Payment/PaymentHistory/PaymentTable")
// );

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "associationMemberName",
    label: "Company Name",
  },
  // {
  //   id: "payUserName",
  //   label: "Pay by User Name",
  // },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phoneNumber",
    label: "Phone",
  },
  {
    id: "associationMemberType",
    label: "Membership Type",
  },
  // {
  //   id: "paymentAmount",
  //   label: "Payment Amount",
  // },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
  },
  {
    id: "planStatus",
    label: "Plan Status",
  },
  {
    id: "date",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
];

function AssociationMembershipReport() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [asList, setASList] = useState([]);
  const [asId, setASId] = useState();
  const [planStatus, setPlanStatus] = useState();
  const [selectLea, setSelectLea] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [selectIntry, setSelectIntry] = useState([]);
  const [userType, setUserType] = useState("User");
  const [apUserType, setApUserType] = useState("User");
  const [openPop, setOpenPop] = useState(false);
  const [apData, setApData] = useState();
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Association Membership Report";
    getAssoList();
    // eslint-disable-next-line
  }, []);

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListForFliter?userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res?.data.success) {
        let data = res?.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setASList(data);
      }
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIntry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const GetPaymentList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let memberList = [];
    if (selectLea?.length > 0) {
      memberList = selectLea;
    }
    if (selectAgency?.length > 0) {
      memberList = [...memberList, ...selectAgency];
    }
    if (selectIntry?.length > 0) {
      memberList = [...memberList, ...selectIntry];
    }
    let res = await PostCallApi({
      url: `/api/AssociationPayment/GetAssociationPaymentHistory`,
      body: {
        ...data,
        loginUserId: LogDetails?.userId,
        associationMemberId: memberList?.length > 0 ? memberList : [],
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res?.status === 200 && res?.data.success) {
      setOpenCircle(false);
      // let comList = res?.data?.payload?.map((c, i) => {
      //   let astype = c?.associationType ? " - " + c?.associationType : "";

      //   return {
      //     ...c,
      //     id: i + 1,
      //     date: c?.date
      //       ? new Date(c?.date).toLocaleDateString("en-us", {
      //           year: "2-digit",
      //           month: "2-digit",
      //           day: "2-digit",
      //         })
      //       : "",
      //     endDate: c?.endDate
      //       ? new Date(c?.endDate).toLocaleDateString("en-us", {
      //           year: "2-digit",
      //           month: "2-digit",
      //           day: "2-digit",
      //         })
      //       : "",
      //     associationMemberName: c?.associationMemberName
      //       ? c?.associationMemberName + astype
      //       : "",
      //     groupUserList:
      //       c?.userType === "User"
      //         ? c?.groupAssociationUserList?.length > 0
      //           ? c?.groupAssociationUserList?.map((x, index) => {
      //               return {
      //                 ...x,
      //                 registerType: "User",
      //                 id: index + 1,
      //               };
      //             })
      //           : []
      //         : c?.groupAssociationCompanyList?.length > 0
      //         ? c?.groupAssociationCompanyList?.map((x, index) => {
      //             return {
      //               ...x,

      //               userName: x?.companyName,
      //               userEmail: x?.companyEmail,
      //               userPhone: x?.companyPhone,
      //               registerType: "Company",
      //               id: index + 1,
      //             };
      //           })
      //         : [],
      //     Action:
      //       c?.registerType === "Stripe" ? (
      //         <Tooltip title="View Receipt">
      //           <Link
      //             className="btn_edit"
      //             to={`/association-stripe-recipe/${c?.paymentId}`}
      //           >
      //             <IoReceiptOutline />
      //           </Link>
      //         </Tooltip>
      //       ) : (
      //         <Tooltip title="View Receipt">
      //           <Link
      //             className="btn_edit"
      //             to={`/association-invoice-list/view/${c?.invoiceId}`}
      //           >
      //             <IoReceiptOutline />
      //           </Link>
      //         </Tooltip>
      //       ),
      //   };
      // });
      setApUserType(userType);
      let comList = res?.data?.payload
        ?.filter((x) => x?.userType === userType)
        ?.map((c) => {
          let astype = c?.associationType ? " - " + c?.associationType : "";

          return {
            // ...c,
            // id: i + 1,

            groupUserList:
              c?.userType === "User"
                ? c?.groupAssociationUserList?.length > 0
                  ? c?.groupAssociationUserList?.map((x, index) => {
                      return {
                        ...x,
                        associationName: c?.associationName,
                        invoiceNumber: c?.invoiceNumber,
                        // registerType: "User",
                        payUserName: c?.userName,
                        allDetails: c,
                        associationMemberName: c?.associationMemberName
                          ? c?.associationMemberName + astype
                          : "",
                        email: x?.userEmail,
                        phoneNumber: x?.userPhone,
                        paymentAmount: x?.totalPrice,
                        id: index + 1,
                        planStatus:
                          x?.isPlanExpire === true
                            ? "Plan Expired"
                            : "Current Plan",
                        date: x?.userStartDate
                          ? new Date(x?.userStartDate).toLocaleDateString(
                              "en-us",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "",
                        endDate: x?.userEndDate
                          ? new Date(x?.userEndDate).toLocaleDateString(
                              "en-us",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "",
                        // Action: (
                        //   <>
                        //     {LogDetails?.roleId === 1 ? (
                        //       <Link
                        //         onClick={() =>
                        //           handleOpenPop({
                        //             ...x,
                        //             registerType: c?.userType,
                        //             userId: x?.userId,
                        //             associationPaymentId: c?.paymentId,
                        //           })
                        //         }
                        //         className="btn_edit me-3 d-none "
                        //       >
                        //         <VscEdit />
                        //       </Link>
                        //     ) : (
                        //       ""
                        //     )}
                        //     {c?.registerType === "Stripe" ? (
                        //       <Tooltip title="View Receipt">
                        //         <Link
                        //           className="btn_edit"
                        //           to={`/association-stripe-recipe/${c?.paymentId}`}
                        //         >
                        //           <IoReceiptOutline />
                        //         </Link>
                        //       </Tooltip>
                        //     ) : (
                        //       <Tooltip title="View Receipt">
                        //         <Link
                        //           className="btn_edit"
                        //           to={`/association-invoice-list/view/${c?.invoiceId}`}
                        //         >
                        //           <IoReceiptOutline />
                        //         </Link>
                        //       </Tooltip>
                        //     )}
                        //   </>
                        // ),
                      };
                    })
                  : []
                : c?.groupAssociationCompanyList?.length > 0
                ? c?.groupAssociationCompanyList?.map((x, index) => {
                    return {
                      ...x,
                      associationName: c?.associationName,
                      invoiceNumber: c?.invoiceNumber,
                      associationMemberName: x?.companyName,
                      // userName: x?.companyName,
                      payUserName: c?.userName,
                      allDetails: c,
                      id: index + 1,
                      email: x?.companyEmail,
                      phoneNumber: x?.companyPhone,
                      // registerType: "Company",
                      paymentAmount: x?.totalPrice,
                      planStatus:
                        x?.isPlanExpire === true
                          ? "Plan Expired"
                          : "Current Plan",
                      date: x?.companyStartDate
                        ? new Date(x?.companyStartDate).toLocaleDateString(
                            "en-us",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        : "",
                      endDate: x?.companyEndDate
                        ? new Date(x?.companyEndDate).toLocaleDateString(
                            "en-us",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        : "",
                      // Action: (
                      //   <>
                      //     {LogDetails?.roleId === 1 ? (
                      //       <Link
                      //         onClick={() =>
                      //           handleOpenPop({
                      //             ...x,
                      //             registerType: c?.userType,
                      //             associationMembersId: x?.associationMembersId,
                      //             associationPaymentId: c?.paymentId,
                      //           })
                      //         }
                      //         className="btn_edit me-3 d-none"
                      //       >
                      //         <VscEdit />
                      //       </Link>
                      //     ) : (
                      //       ""
                      //     )}
                      //     {c?.registerType === "Stripe" ? (
                      //       <Tooltip title="View Receipt">
                      //         <Link
                      //           className="btn_edit"
                      //           to={`/association-stripe-recipe/${c?.paymentId}`}
                      //         >
                      //           <IoReceiptOutline />
                      //         </Link>
                      //       </Tooltip>
                      //     ) : (
                      //       <Tooltip title="View Receipt">
                      //         <Link
                      //           className="btn_edit"
                      //           to={`/association-invoice-list/view/${c?.invoiceId}`}
                      //         >
                      //           <IoReceiptOutline />
                      //         </Link>
                      //       </Tooltip>
                      //     )}
                      //   </>
                      // ),
                    };
                  })
                : [],
          };
        })
        ?.flatMap((x) => x?.groupUserList)
        ?.map((x, i) => {
          return { ...x, id: i + 1 };
        });
      let showArr = comList?.map((x) => {
        return {
          ...x,
          Action: (
            <>
              {LogDetails?.roleId === 1 ? (
                <Link
                  onClick={() =>
                    handleOpenPop({
                      ...x,
                      registerType: x?.allDetails?.userType,
                      userId: x?.userId,
                      associationMembersId: x?.associationMembersId,
                      associationPaymentId: x?.allDetails?.paymentId,
                      comList: comList,
                    })
                  }
                  className="btn_edit me-3  "
                >
                  <VscEdit />
                </Link>
              ) : (
                ""
              )}
              {x?.allDetails?.registerType === "Stripe" ? (
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit"
                    to={`/association-stripe-recipe/${x?.allDetails?.paymentId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="View Receipt">
                  <Link
                    className="btn_edit"
                    to={`/association-invoice-list/view/${x?.allDetails?.invoiceId}`}
                  >
                    <IoReceiptOutline />
                  </Link>
                </Tooltip>
              )}
            </>
          ),
        };
      });
      setDataOfTable(showArr);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleAS = (e) => {
    setASId(e);
    setValue("associationId", e);
  };

  const PlanStatusOption = [
    { value: "Plan Expired", label: "Plan Expired" },
    { value: "Current Plan", label: "Current Plan" },
  ];

  const handlePaymentStatus = (e) => {
    setPlanStatus(e);
    setValue("planStatus", e);
  };

  const handleChangeValue = (e) => {
    setUserType(e);
  };

  const handleClearFilter = () => {
    setASId();
    setPlanStatus();
    setSelectLea([]);
    setSelectIntry([]);
    setSelectAgency([]);
    setDataOfTable([]);
    reset();
  };

  const CallBackApi = () => {
    let send = {
      associationId: getValues("associationId"),
      planStatus: getValues("planStatus"),
      orderNumber: getValues("orderNumber") ? getValues("orderNumber") : "",
      paymentDate: getValues("paymentDate") ? getValues("paymentDate") : null,
      endDate: getValues("endDate") ? getValues("endDate") : null,
    };
    GetPaymentList(send);
  };

  const handleOpenPop = (eId) => {
    setApData(eId);
    setOpenPop(true);

    setValue(
      `mstartDate`,
      eId?.userStartDate
        ? eId?.userStartDate?.split("T")[0]
        : eId?.companyStartDate
        ? eId?.companyStartDate?.split("T")[0]
        : ""
    );
    setValue(
      `mendDate`,
      eId?.userEndDate
        ? eId?.userEndDate?.split("T")[0]
        : eId?.companyEndDate
        ? eId?.companyEndDate?.split("T")[0]
        : ""
    );
  };
  const handleClose = () => {
    setApData();
    setOpenPop(false);
    reset();
  };
  function isValidRange(newStart, newEnd, existingRanges) {
    for (const { date, endDate } of existingRanges) {
      // Check for overlap
      let sd = new Date(date);
      let ed = new Date(endDate);
      let cns = new Date(newStart).toLocaleDateString("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      cns = new Date(cns);
      let cne = new Date(newEnd).toLocaleDateString("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      cne = new Date(cne);
      if (
        (cns >= sd && cns <= ed) || // New start is within an existing range
        (cne >= sd && cne <= ed) || // New end is within an existing range
        (cns < ed && cne > sd) // New range overlaps with existing range
      ) {
        return false; // Overlap found
      }
    }
    return true; // No overlap
  }

  const HandleRequest = async (data) => {
    let is_success = true;
    let sameList;
    if (apData?.registerType === "User") {
      sameList = apData?.comList
        ?.filter((x) => x?.id !== apData?.id)
        ?.filter(
          (x) =>
            x?.userId === apData?.userId &&
            apData?.allDetails?.associationId === x?.allDetails?.associationId
        );
    } else {
      sameList = apData?.comList
        ?.filter((x) => x?.id !== apData?.id)
        ?.filter(
          (x) =>
            x?.associationMembersId === apData?.associationMembersId &&
            apData?.allDetails?.associationId === x?.allDetails?.associationId
        );
    }
    if (sameList?.length > 0) {
      if (isValidRange(data?.mstartDate, data?.mendDate, sameList)) {
        // console.log("working");
      } else {
        // handleClose();
        SwalMessage(
          `${
            apData?.registerType === "User"
              ? apData?.userName
              : apData?.associationMemberName
          } membership already exists with same date range`
        );
        // console.log("The new date range overlaps with existing ranges.");
        is_success = false;
      }
    }
    if (is_success) {
      setOpenCircle(true);

      let reqData = {
        ...apData,
        startDate: data?.mstartDate,
        endDate: data?.mendDate,
        updatedBy: LogDetails?.userId,
      };

      let seData = {
        url: `/api/AssociationPayment/UpdateStartAndEndDateOfAssociationMembership`,
        body: reqData,
      };

      let resp = await PostCallApi(seData);
      setOpenCircle(false);
      if (resp?.status === 200 && resp.data.success) {
        setOpenCircle(false);
        handleClose();
        let msg = resp.data.message;
        CallBackApi();
        ToastSuccess(msg);
      } else {
        setOpenCircle(false);
        ToastError(resp.data.message);
      }
    }
  };

  var dtToday = new Date().toISOString().split("T")[0];

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={handleSubmit(GetPaymentList)}>
                      <div className="row" style={{ marginLeft: "20px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Association</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={asList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Association"
                              filterOption={customFilter}
                              value={asList?.filter(
                                (obj) => obj.value === asId
                              )}
                              onChange={(e) => handleAS(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Plan Status</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={PlanStatusOption}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select Plan Status"
                              filterOption={customFilter}
                              value={PlanStatusOption?.filter(
                                (obj) => obj.value === planStatus
                              )}
                              onChange={(e) => handlePaymentStatus(e?.value)}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("paymentDate", {
                                valueAsDate: true,
                              })}
                            />
                            {errors.paymentDate && (
                              <span role="alert" className="error_text">
                                {errors.paymentDate.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("endDate", {
                                valueAsDate: true,
                                validate: (val) => {
                                  if (
                                    watch("paymentDate")?.toString() !==
                                      "Invalid Date" &&
                                    val?.toString() === "Invalid Date"
                                  ) {
                                    return "This field is required";
                                  }
                                  if (watch("paymentDate") > val) {
                                    return "Your end date cannot be earlier than the start date";
                                  }
                                },
                              })}
                            />
                            {errors.endDate && (
                              <span role="alert" className="error_text">
                                {errors.endDate.message}
                              </span>
                            )}
                          </div>
                        </div>{" "}
                        <div className="col-md-3">
                          <LeaDrop
                            selectedLea={selectLea}
                            handleChangeLea={handleChangeLea}
                          />
                        </div>
                        <div className="col-md-3">
                          <StateAgencyDrop
                            handleChangeAgency={handleChangeAgency}
                            selectedAgency={selectAgency}
                          />
                        </div>
                        <div className="col-md-3">
                          <IndustryDrop
                            selectedIndustry={selectIntry}
                            handleChangeIndustry={handleChangeIndustry}
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">Register Type</label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={[
                                { label: "User", value: "User" },
                                {
                                  label:
                                    "Association Member (LEA/Industry/State Agency)",
                                  value: "AssociationMember",
                                },
                              ]}
                              // isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select User Type"
                              filterOption={customFilter}
                              value={[
                                { label: "User", value: "User" },
                                {
                                  label:
                                    "Association Member (LEA/Industry/State Agency)",
                                  value: "AssociationMember",
                                },
                              ]?.filter((obj) => obj.value === userType)}
                              onChange={(e) => handleChangeValue(e?.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-start mt-auto">
                          <div className="form-group">
                            <Button
                              className="common_btn ms-3"
                              type="submit"
                              disabled={openCircle ? true : false}
                            >
                              {openCircle ? (
                                <CircularProgress
                                  color="inherit"
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              className={`back_button ms-3 ${
                                window.innerWidth > 400 ? "" : "mt-3"
                              }`}
                              onClick={() => handleClearFilter()}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-md-12">
                <div className="accordian_card">
                  <NewLIstTable
                    title="Association Membership Report"
                    action={true}
                    headCells={
                      apUserType === "User"
                        ? headCellsEvent
                        : headCellsEvent?.filter((x) => x?.id !== "userName")
                    }
                    ListData={dataOfTable}
                    dropdown={false}
                    addBtn={false}
                    showUserListInTable={true}
                    fileName={"Association Membership Report"}
                    filterPage={filterPage}
                    exportDataList={true}
                    innerProduct={true}
                    subHeadCells={
                      TableHeader?.AssociationMembershipPaymentHistorySubHeader
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {openPop && (
          <>
            <Modal
              sx={{ zIndex: 9 }}
              open={openPop}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  open={openCircle}
                  sx={{ color: "#fff", zIndex: () => 9999 }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>Membership </h6>
                  <form onSubmit={handleSubmit(HandleRequest)}>
                    <div className="popup_delete_detail">
                      <p className="delete_text">
                        Are you sure you want to change date of membership of
                        <span>
                          {" "}
                          {apData?.userName
                            ? apData?.userName
                            : apData?.companyName}
                          ?
                        </span>
                      </p>

                      <div className="form-group">
                        <label className="form-label">
                          Start Date
                          <span className="red-color"> *</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          max={dtToday}
                          {...register("mstartDate", {
                            required: "This field is require",
                            valueAsDate: true,
                          })}
                        />
                        {errors?.mstartDate && (
                          <span className="error_text">
                            {errors.mstartDate.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          End Date
                          <span className="red-color"> *</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("mendDate", {
                            required: "This field is require",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("mstartDate") > val) {
                                return "Your end date cannot be earlier than the start date";
                              }
                            },
                          })}
                        />
                        {errors?.mendDate && (
                          <span className="error_text">
                            {errors.mendDate.message}
                          </span>
                        )}
                      </div>

                      <button
                        type="submit"
                        className="popup_btn delete"
                        // onClick={HandleRequest}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default AssociationMembershipReport;
