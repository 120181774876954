import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";

const PayByCash = (props) => {
  const { handlePayByCash, btnLoad } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form
      onSubmit={handleSubmit(async (data) =>
        handlePayByCash({ ...data, isPayByCash: true })
      )}
    >
      <div className="row">
        <div className="col-md-8">
          <label className="form-label">Add Note</label>

          <div className="form-group mb-3">
            <textarea
              type="text"
              rows={5}
              className="form-control"
              placeholder="Enter Note"
              {...register("note", {
                required: "This field is require",
                pattern: {
                  value: /^[^\s]+(\s+[^\s]+)*$/,
                  message: "Starting and Ending Space not allowed",
                  maxLength: {
                    value: 1000,
                    message: "Max 1000 characters",
                  },
                },
              })}
            />
            {errors?.note && (
              <span className="error_text">{errors.note.message}</span>
            )}
          </div>
        </div>
        <div className="col-md-12 ">
          <Button className="common_btn mt-3" disabled={btnLoad} type="submit">
            {btnLoad ? (
              <CircularProgress
                color="inherit"
                sx={{
                  color: "#fff",
                  marginLeft: "0.5rem",
                  height: "23px !important",
                  width: "23px !important",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PayByCash;
