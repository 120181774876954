import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

// import events from "./events";
import { GetCallApi } from "../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import { customFilter } from "../../Modules/Components/CustomFilterForReactSelect";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const showReadMore = (t, des) => {
  Swal.fire({
    position: "center",
    width: window.innerWidth > 768 ? "50%" : "90%",
    title: t,
    text: des,
    confirmButtonColor: "#fff",
    // confirmButtonTextColor: "#000000",
    color: "#ffffff",
    background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
  });
};

function ConferenceCalendar() {
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const [dataOfTable, setDataOfTable] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [conferenceId, setConferenceId] = useState("");

  useEffect(() => {
    GetSessionEventList();
    // eslint-disable-next-line
  }, []);

  const GetSessionEventList = async () => {
    setOpenCircle(true);
    setDataOfTable([]);
    setDataOfTable1([]);
    let res = await GetCallApi({
      url: `/api/AttendeeSchedule/GetAttendeeConferenceScheduleList?userId=${LogDetails?.userId}`,
    });
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res?.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((x, i) => {
          return {
            ...x,
            value: x?.conferenceId,
            label: x?.conferenceName,
            id: i + 1,
          };
        });
        setDataOfTable(comList);
        setConferenceId(comList[0]?.conferenceId);
        if (res?.data?.payload?.length > 0) {
          GetMySchedule(res?.data?.payload[0]?.conferenceId);
        }
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const GetMySchedule = async (id) => {
    setDataOfTable1();
    setConferenceId(id);
    if (id) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/AttendeeSchedule/GetAttendeeScheduleDetailsById?userId=${LogDetails?.userId}&conferenceId=${id}`,
      });
      setOpenCircle(false);

      if (res?.status === 200) {
        if (res?.data.success) {
          let sessionEventList = [];
          let schedule = res?.data?.payload
            ?.filter(
              (x) => x?.classList?.length > 0 || x?.eventList?.length > 0
            )
            ?.map((x) => {
              let classList =
                x?.classList?.length > 0
                  ? x?.classList?.map((y) => {
                      return {
                        start:
                          x.date?.split("T")[0] + "T" + y?.startTime + ":00",
                        end: x.date?.split("T")[0] + "T" + y?.endTime + ":00",
                        title: y?.classTitle,
                        type: "Session",
                      };
                    })
                  : null;
              let eventList =
                x?.eventList?.length > 0
                  ? x?.eventList?.map((y) => {
                      return {
                        start:
                          x.date?.split("T")[0] +
                          "T" +
                          y?.eventStartTime +
                          ":00",
                        end:
                          x.date?.split("T")[0] + "T" + y?.eventEndTime + ":00",
                        title: y?.eventName,
                        type: "Event",
                      };
                    })
                  : null;
              let mainlist =
                classList !== null && eventList !== null
                  ? [...classList, ...eventList]
                  : classList !== null
                  ? classList
                  : eventList !== null
                  ? eventList
                  : [];
              mainlist.sort(function (a, b) {
                let btime = b?.startTime ? b?.startTime : b?.eventStartTime;
                let atime = a?.startTime ? a?.startTime : a?.eventStartTime;

                return (
                  new Date(x.date?.split("T")[0] + " " + atime) -
                  new Date(x.date?.split("T")[0] + " " + btime)
                );
              });
              if (mainlist?.length > 0) {
                return sessionEventList.push(...mainlist);
              }
              return "";
            });
          setDataOfTable1(sessionEventList);
          //remove warning
          sessionEventList = schedule;
        }
      }
    }
  };

  function getClickData(info) {
    showReadMore(info?.event?.extendedProps?.type, info?.el?.innerText);
  }

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="calendar_dashboard py-4">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="d-flex justify-content-between AttendeeSchedule_table">
                    <h3 className="title m-0">My Schedule Calendar</h3>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={dataOfTable}
                      isClearable={true}
                      className="dropdown-single-select w-25"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={dataOfTable?.filter(
                        (obj) => obj.value === conferenceId
                      )}
                      onChange={(e) => GetMySchedule(e?.value)}
                    />
                    <select
                      className="form-select w-25 ms-auto d-none"
                      value={conferenceId}
                      onChange={(e) => GetMySchedule(e.target.value)}
                    >
                      <option value="">Select Conefernce</option>
                      {dataOfTable?.map((x, i) => (
                        <option value={x?.value} key={i}>
                          {x?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* {conferenceList?.map((x) => x?.name)} */}
                  <div className="col-md-12 calander_box">
                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        // left: "myCustomButton prev,today,next",
                        left: "prev,today,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      buttonText={{
                        today: "current",
                        month: "month",
                        week: "week",
                        day: "day",
                        list: "list",
                      }}
                      // header={{
                      //   left: "prev,next",
                      //   center: "title",
                      //   right: "dayGridMonth,timeGridWeek,timeGridDay",
                      // }}
                      // plugins={[dayGridPlugin]}
                      themeSystem="Simplex"
                      events={dataOfTable1}
                      editable={false}
                      selectMirror={true}
                      dayMaxEvents={true}
                      weekends={true}
                      eventClick={(e) => getClickData(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceCalendar;
