import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import ReactToPrint from "react-to-print";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
const logo = "/assets/images/figEventWhite.png";
const SEO_Sign = "/assets/images/sign.png";

function Certificate_New() {
  let componentRef = useRef();
  const [open, setOpen] = useState(false);

  const { userid, sessionid, name } = useParams();
  const [certiData, setCertiData] = useState();
  const [derror, setDerror] = useState({});
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  useEffect(() => {
    if (userid && sessionid) {
      getCertiData(userid, sessionid);
    }
    // eslint-disable-next-line
  }, [userid, sessionid]);
  const getCertiData = async (userId, sessionid) => {
    if (userId && sessionid) {
      setOpen(true);
      let response = await GetCallApi({
        url: `/api/SessionAttendance/${
          LogUserId
            ? `GetUserDetailsForCertificate`
            : `GetUserDetailsForCertificateWithoutToken`
        }?userId=${userId}&sessionId=${sessionid}&TypeOfUser=${name}`,
      });

      setOpen(false);
      if (response?.data.success) {
        setOpen(false);
        setCertiData(response?.data?.payload);
        setDerror({ pageErr: "" });
      } else {
        setOpen(false);
        setDerror({ pageErr: "You do not have access to this page" });
      }
    } else {
      setDerror({ pageErr: "Missing key's" });
      setCertiData();
    }
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="certificate_page main_backcolor">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="col-md-8 m-auto">
              {derror?.pageErr === "" ? (
                <>
                  <div className="button_div text-center mb-3">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="btn_theme"
                          data-testid="Print-iconButton"
                          aria-label="Print"
                          title="Print"
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                  <body
                    ref={(el) => (componentRef = el)}
                    className="clean-body table-responsive"
                    style={{
                      margin: 0,
                      padding: 0,
                      WebkitTextSizeAdjust: "100%",
                      backgroundColor: "#EAF0F2",
                    }}
                  >
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="nl-container"
                      role="presentation"
                      style={{
                        tableLayout: "fixed",
                        verticalAlign: "top",
                        minWidth: 320,
                        borderSpacing: 0,
                        borderCollapse: "collapse",
                        msTableLspace: 0,
                        msTableRspace: 0,
                        width: "100%",
                      }}
                      // valign="top"
                      width="100%"
                    >
                      <tbody>
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            style={{
                              wordBreak: "break-word",
                              verticalAlign: "top",
                              alignContent: "center",
                            }}
                            valign="top"
                          >
                            <table
                              cellPadding="0"
                              cellSpacing="0"
                              className="email_box_wrapper"
                              style={{
                                backgroundColor: "transparent",
                                padding: "0px 0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                margin: "0rem auto",
                              }}
                            >
                              <tbody
                                className="email_box"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tr
                                  className="block-grid"
                                  style={{
                                    overflowWrap: "break-word",
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                    margin: "0 auto",
                                    backgroundColor: "transparent",
                                    width: "100%",
                                    display: "block",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      display: "table",
                                      width: "100%",
                                      backgroundColor: "transparent",
                                      margin: "auto",
                                    }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      className="num12"
                                      style={{
                                        minWidth: 320,
                                        maxWidth: 900,
                                        display: "table-cell",
                                        verticalAlign: "top",
                                        padding: "0px",
                                      }}
                                    >
                                      <tr
                                        style={{
                                          borderTop: 0,
                                          borderLeft: 0,
                                          borderBottom: 0,
                                          borderRight: 0,
                                          display: "block",
                                        }}
                                      >
                                        <td
                                          style={{
                                            width: "100%",
                                            display: "block",
                                          }}
                                        >
                                          <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            className="order_data"
                                            style={{
                                              width: "100%",
                                              display: "block",
                                            }}
                                          >
                                            <tr
                                              style={{
                                                width: "100%",
                                                display: "block",
                                              }}
                                            >
                                              <td
                                                className="odtt"
                                                style={{
                                                  // width: 900,
                                                  backgroundColor: "#fff",
                                                  padding: "1rem 2rem",
                                                  textAlign: "center",
                                                  backgroundImage:
                                                    "linear-gradient(to left bottom, #3ad2fc, #9ffae7)",
                                                  boxShadow:
                                                    "0px 5px 10px rgba(145, 145, 145, 0.25)",
                                                  borderRadius: "10px",
                                                  display: "block",
                                                }}
                                              >
                                                <h1
                                                  style={{
                                                    fontWeight: 600,
                                                    fontStyle: "normal",
                                                    color: "rgb(42, 58, 145)",
                                                    textDecoration: "none",
                                                    textShadow:
                                                      "2px 2px #3671b5",
                                                    letterSpacing: "3px",
                                                    fontSize: "45px",
                                                    margin: "22px 0 23px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  Certificate of Completion
                                                </h1>
                                                <h6
                                                  style={{
                                                    fontWeight: "600",
                                                    margin: "10px 20px",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  Proudly Presented To
                                                </h6>
                                                <p
                                                  style={{
                                                    color: "#2a3a91",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {certiData?.name}
                                                </p>
                                                <h6
                                                  style={{
                                                    margin: "10px 20px",
                                                    fontWeight: "600",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  For Completing the Course
                                                </h6>
                                                <p
                                                  style={{
                                                    // margin: "10px 20px",
                                                    color: "#2a3a91",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {certiData?.sessionName}
                                                </p>
                                                {/* Additional sections */}
                                                <h6
                                                  style={{
                                                    margin: "10px 20px",
                                                    fontWeight: "600",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  Key Area
                                                </h6>
                                                {certiData?.keyarea?.map(
                                                  (mcat, i) => (
                                                    <p
                                                      style={{
                                                        // margin: "20px",
                                                        color: "#2a3a91",
                                                        fontWeight: "bold",
                                                      }}
                                                      key={i}
                                                    >
                                                      {mcat?.courseCategoryCode}{" "}
                                                      -{" "}
                                                      {mcat?.courseCategoryName}
                                                    </p>
                                                  )
                                                )}
                                                <h6
                                                  style={{
                                                    margin: "10px 20px",
                                                    fontWeight: "600",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  Learning Objective
                                                </h6>

                                                {certiData?.learningObjectives?.map(
                                                  (mcat, i) => (
                                                    <p
                                                      style={{
                                                        // margin: "20px",
                                                        color: "#2a3a91",
                                                        fontWeight: "bold",
                                                      }}
                                                      key={i}
                                                    >
                                                      {mcat?.courseCategoryCode}{" "}
                                                      -{" "}
                                                      {mcat?.courseCategoryName}
                                                    </p>
                                                  )
                                                )}
                                                <h6
                                                  style={{
                                                    margin: "10px 20px",
                                                    fontWeight: "600",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  Training Topic
                                                </h6>

                                                {certiData?.trainingTopics?.map(
                                                  (mcat, i) => (
                                                    <p
                                                      style={{
                                                        // margin: "20px",
                                                        color: "#2a3a91",
                                                        fontWeight: "bold",
                                                      }}
                                                      key={i}
                                                    >
                                                      {mcat?.courseCategoryCode}{" "}
                                                      -{" "}
                                                      {mcat?.courseCategoryName}
                                                    </p>
                                                  )
                                                )}

                                                <h6
                                                  style={{
                                                    margin: "10px 20px",
                                                    fontWeight: "600",
                                                    color: "#fbf6f3",
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  CEU Value
                                                </h6>
                                                <p
                                                  style={{
                                                    // marginTop: "20px",
                                                    color: "#2a3a91",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {certiData?.ceuValue}
                                                </p>
                                                <table
                                                  cellPadding="0"
                                                  cellSpacing="0"
                                                  style={{ width: "100%" }}
                                                >
                                                  <tr>
                                                    <td
                                                      style={{
                                                        width: "33.33%",
                                                      }}
                                                    >
                                                      <img
                                                        src={SEO_Sign}
                                                        style={{
                                                          height: "50px",
                                                        }}
                                                        alt=""
                                                      />
                                                      <h6
                                                        style={{
                                                          fontWeight: "600",
                                                          marginTop: "5px",
                                                          marginBottom: "0px",
                                                          color: "#fff",
                                                          borderTop:
                                                            "2px solid #fff",
                                                          fontSize: "25px",
                                                          paddingTop: "7px",
                                                        }}
                                                      >
                                                        Becki Swan
                                                      </h6>
                                                      <p
                                                        style={{
                                                          fontWeight: "500",
                                                          marginBottom: "0px",
                                                          color: "#fff",
                                                        }}
                                                      >
                                                        CEO FIG
                                                      </p>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "33.33%",
                                                        verticalAlign:
                                                          "baseline",
                                                      }}
                                                    >
                                                      <h6
                                                        style={{
                                                          fontWeight: "600",
                                                          margin: "10px 20px",
                                                          color: "#fbf6f3",
                                                          textTransform:
                                                            "uppercase",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        Date of Completion
                                                      </h6>
                                                      <p
                                                        style={{
                                                          color: "#2a3a91",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {certiData?.sessionStartDate ===
                                                          "" ||
                                                        certiData?.sessionStartDate ===
                                                          null
                                                          ? ""
                                                          : convert(
                                                              certiData?.sessionStartDate?.split(
                                                                "T"
                                                              )[0]
                                                            )}
                                                      </p>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "33.33%",
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          width: "150px",
                                                        }}
                                                        src={logo}
                                                        alt=""
                                                      />
                                                    </td>
                                                  </tr>
                                                </table>
                                                <table
                                                  // cellpadding="0"
                                                  // cellSpacing="0"
                                                  style={{ width: "100%" }}
                                                >
                                                  <tr>
                                                    <td
                                                      style={{
                                                        width: "33.33%",
                                                      }}
                                                    >
                                                      <h6
                                                        style={{
                                                          fontWeight: "600",
                                                          marginBottom: "5px",
                                                          color: "#3371c3",
                                                          paddingTop: "10px",
                                                          textTransform:
                                                            "uppercase",
                                                        }}
                                                      >
                                                        To find out how your
                                                        district can join the
                                                        fig family click here!
                                                      </h6>
                                                      <Link
                                                        target="_blank"
                                                        to="https://figk-12.com"
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                        }}
                                                      >
                                                        <button
                                                          style={{
                                                            background:
                                                              "#87c65a",
                                                            padding:
                                                              "10px 30px",
                                                            marginBottom:
                                                              "10px",
                                                            fontSize: "15px",
                                                            fontWeight: 500,
                                                            borderRadius:
                                                              "100px",
                                                            marginTop: "10px",
                                                            boxShadow:
                                                              "0px 3px",
                                                          }}
                                                          type="button"
                                                        >
                                                          {" "}
                                                          Learn More
                                                        </button>
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </body>
                </>
              ) : (
                <div className="err_msg">{derror?.pageErr}</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default React.memo(Certificate_New);
